import {
  DATA_GOOGLE_AD_METRICS,
  DATA_GOOGLE_AD_METRICS_LOADING,
  DATA_GOOGLE_AD_METRICS_LOADING_ERROR,
} from '../constants';

export default {
  defaultState: {
    adMetrics: [],
    adMetricsLoading: false,
    adMetricsLoadingError: false,
  },
  reducers: {
    [DATA_GOOGLE_AD_METRICS]: (state, { payload }) => ({
      ...state,
      adMetrics: payload,
    }),
    [DATA_GOOGLE_AD_METRICS_LOADING]: (state, { payload }) => ({
      ...state, adMetricsLoading: payload,
    }),
    [DATA_GOOGLE_AD_METRICS_LOADING_ERROR]: (state, { payload }) => ({
      ...state, adMetricsLoadingError: payload,
    }),
  },
};
