import _ from 'lodash';
import { call } from 'redux-saga/effects';

import api from '../../services/api';
import logger from '../../services/logger';

export default function* createStripeCustomer(businessId, name, email) {
  try {
    const customer = yield call(api.createStripeCustomer, { businessId, name, email });
    return _.get(customer, 'id');
  } catch (error) {
    logger.error({
      error,
      context: { saga: 'createStripeCustomer' },
      params: {
        businessId, name, email,
      },
    });
    return null;
  }
}
