import {
  DATA_STORES,
  DATA_STORES_LOADING,
  DATA_STORES_PAYMENT_METHODS,
  DATA_STORES_FB_INFO_LOADING,
  SET_STORE_STATUS,
} from '../constants';

export default {
  defaultState: {
    stores: [],
    storesLoading: false,
    storesFacebookInfoLoading: false,
    storesPaymentMethods: [],
  },
  reducers: {
    [DATA_STORES]: (state, { payload }) => ({ ...state, stores: payload }),
    [DATA_STORES_LOADING]: (state, { payload }) => ({ ...state, storesLoading: payload }),
    [DATA_STORES_FB_INFO_LOADING]: (state,
      { payload }) => ({ ...state, storesFacebookInfoLoading: payload }),
    [DATA_STORES_PAYMENT_METHODS]: (state,
      { payload }) => ({ ...state, storesPaymentMethods: payload }),
    [SET_STORE_STATUS]: (state, { payload: { storeId, disabled } }) => ({
      ...state,
      stores: state.stores.map((store) => {
        if (store.id === storeId) {
          return {
            ...store,
            disabled,
          };
        }
        return store;
      }),
    }),
  },
};
