import _ from 'lodash';
import {
  call,
  put,
  select,
} from 'redux-saga/effects';

import {
  resetApp,
  showBackdrop,
  setLoadingPermissions,
  getSmartFeed,
} from '../../actions';
import { selectUserAllUsers } from '../../selectors';
import getStoresFacebookPermissions from '../getStoresFacebookPermissions/getStoresFacebookPermissions';

import localStorage from '../../services/localStorage';
import logger from '../../services/logger';
import handleReturningUser from '../handleReturningUser/handleReturningUser';
import history, { getCurrentPath } from '../../services/history';
import { getRejectedRoute, getHomeRoute } from '../../routes';
import auth from '../../services/auth';

const buildDeeplinkUrl = (currentPath, biz) => {
  const path = currentPath.split('/').slice(3);
  let page;
  let tab;
  if (path) {
    [page, tab] = path;
    if (tab) {
      tab = `?${tab}`;
    }
    if (page && page.indexOf('invoice') > -1) {
      tab = tab.replace('?', '/');
    }
  }
  return `/businesses/${biz}${page ? `/${page}` : ''}${tab || ''}`;
};

export default function* changeBusiness({ payload }) {
  const auth0Id = auth.getUserId();
  const userId = _.get(payload, 'userId');
  const businessId = _.get(payload, 'businessId');
  let currentPath = getCurrentPath() || getHomeRoute();

  if (businessId && currentPath && currentPath.indexOf('businesses') > -1) {
    currentPath = buildDeeplinkUrl(currentPath, businessId);
  }

  try {
    // ensure local storage is set BEFORE getting the new users
    localStorage.setItem(`${auth0Id}:last_viewed_user_id`, userId);

    yield put(resetApp());
    yield put(showBackdrop(true));
    yield put(setLoadingPermissions(true));
    const users = yield select(selectUserAllUsers);
    const user = _.find(users, ['id', userId]);

    yield call(handleReturningUser, user, null, user?.businessId);

    // ensure permissions by store are properly set
    yield call(getStoresFacebookPermissions);

    // we need the smartfeed to reload when switching between business
    yield put(getSmartFeed());
    yield put(setLoadingPermissions(false));
    yield put(showBackdrop(false));
    history.push(currentPath);
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'changeBusiness' },
      params: { userId },
    });
    yield put(showBackdrop(false));
    history.push(getRejectedRoute());
  }
}
