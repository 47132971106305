import { DATA_FACEBOOK_PERMISSIONS_BY_STORES, DATA_FACEBOOK_TOKEN_EXPIRED } from '../constants';

const defaultState = {
  stores: {},
  tokenExpired: null,
};

export default {
  defaultState,
  reducers: {
    [DATA_FACEBOOK_PERMISSIONS_BY_STORES]: (state, { payload: stores }) => ({ ...state, stores }),
    [DATA_FACEBOOK_TOKEN_EXPIRED]: (state, { payload: tokenExpired }) => ({
      ...state,
      tokenExpired,
    }),
  },
};
