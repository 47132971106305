import _ from 'lodash';
import { call, put } from 'redux-saga/effects';

import api from '../../services/api';
import {
  setUserActive,
  updateUserInfo,
  showToaster,
} from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

const omittedPaths = ['connectedBusinesses', 'created', 'identities', 'nickname', 'updated'];

export default function* updateUser(user, notCurrentUser) {
  try {
    let updatedUser = yield call(api.updateUser, user.id, _.omit(user, omittedPaths));
    updatedUser = _.merge({}, user, updatedUser);
    yield put(updateUserInfo(updatedUser));
    if (!notCurrentUser) {
      yield put(setUserActive(updatedUser));
    }
    return updatedUser;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'updateUser' },
      params: { user, notCurrentUser },
    });
    yield put(showToaster(getToasterOptions('generic_request_error', 'error')));
    return undefined;
  }
}
