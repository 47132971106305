import { put, call } from 'redux-saga/effects';
import i18n from '../../services/i18n';
import api from '../../services/api';
import { showToaster, setLoadingAsync } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions, applyNameToCampaignAndGroupFields } from '../helpers';

export default function* createUpdateGoogleSearchAdsBulk({
  payload: {
    onDone, googleData, editAction,
  },
}) {
  try {
    applyNameToCampaignAndGroupFields(googleData);
    yield put(setLoadingAsync(true));
    let toasterResult = i18n.t('create_google_bulk', 'Google Search Ads Bulk created successfully!');
    if (!editAction) { // create action
      yield put(showToaster(getToasterOptions(
        'Creating group ad...', 'info', null, 7000, true, null, 'bottom', 'right',
      )));
      yield call(
        api.createGoogleSearchAdBulk,
        googleData,
      );
    } else { // edit action
      toasterResult = i18n.t('update_google_bulk', 'Google Search Ads Bulk updated successfully!');
      if (googleData?.base?.status === 'smartFeed') {
        yield put(showToaster(getToasterOptions(
          'Publishing the Group Ad...', 'info', null, 7000, true, null, 'bottom', 'right',
        )));
      } else if (googleData?.base?.status === 'archived') {
        yield put(showToaster(getToasterOptions(
          'Deleting group ad...', 'info', null, 7000, true, null, 'bottom', 'right',
        )));
      } else {
        yield put(showToaster(getToasterOptions(
          'Updating group ad...', 'info', null, 7000, true, null, 'bottom', 'right',
        )));
      }
      const groupId = googleData?.base?.id;
      // eslint-disable-next-line no-param-reassign
      delete googleData?.base?.id;
      yield call(
        api.updateGoogleSearchAdBulk,
        groupId,
        googleData,
      );
    }
    yield put(showToaster(getToasterOptions(toasterResult, 'success', null, 5000)));
    yield put(setLoadingAsync(false));
    if (onDone) {
      onDone();
    }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'createUpdateGoogleSearchAdsBulk' },
      params: {
        googleData,
      },
    });
    yield put(showToaster(getToasterOptions(i18n.t('create_google_bulk_error', 'An error occurred saving google search ads bulk'), 'error')));
    yield put(setLoadingAsync(false));
  }
}
