import React from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import globals from '../../../../globals';
import StripeIntro from '../StripeIntro/StripeIntro';
import StripeForm from '../StripeForm/StripeForm';
import StripeOutro from '../StripeOutro/StripeOutro';

const stripePromise = loadStripe(globals.STRIPE_PUBLIC_KEY);

const StripeContainer = ({ stage }) => (
  <>
    {stage === 'stripe-intro' && <StripeIntro />}
    {stage === 'stripe-form' && (
    <Elements
      stripe={stripePromise}
      fonts={[
        { cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,500,600' },
      ]}
    >
      <StripeForm />
    </Elements>
    )}
    {stage === 'stripe-outro' && <StripeOutro />}
  </>
);

StripeContainer.propTypes = {
  stage: PropTypes.string.isRequired,
};

export default StripeContainer;
