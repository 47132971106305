import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import {
  INSIGHT_STATUS_APPROVED,
  INSIGHT_STATUS_PUBLISHED,
} from '@targetable/targetable-web-framework/lib/react/constants';

import api from '../../services/api';
import { setBusinessInsights, setBusinessInsightsLoading } from '../../actions';
import { selectBusiness } from '../../selectors';
import logger from '../../services/logger';

export default function* getInsightsByBusiness({ payload }) {
  const business = yield select(selectBusiness);
  const expired = _.get(payload, 'expired', false);
  try {
    yield put(setBusinessInsightsLoading(true));
    let insights;
    if (expired) {
      insights = yield call(api.getInsights, business.id, INSIGHT_STATUS_PUBLISHED, expired);
    } else {
      insights = yield call(api.getInsights, business.id, INSIGHT_STATUS_APPROVED);
    }
    yield put(setBusinessInsights(insights));
    yield put(setBusinessInsightsLoading(false));
    return insights;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getInsightsByBusiness' },
      params: { business },
    });
    return undefined;
  }
}
