import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

import FullScreenDialog from '@targetable/targetable-web-framework/lib/react/containers/FullScreenDialog/FullScreenDialog';
import useFormValidation from '@targetable/targetable-web-framework/lib/hooks/useFormValidation';

import { selectUser, selectShowEditBusinessNameModal } from '../../selectors';
import { confirmEditBusinessName } from '../../actions';
import i18n from '../../services/i18n';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 613,
    width: '100%',
    margin: `${theme.spacing(3)}px auto`,
  },
}));

export const validation = (target, values) => {
  if (!values.name) {
    return { name: i18n.t('business_name_required') };
  }
  return {};
};

const EditBusinessNameModal = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const open = useSelector(selectShowEditBusinessNameModal);
  const dispatch = useDispatch();
  const businessName = _.get(user, 'businessName', '');

  const onSubmit = useCallback(({ name }) => {
    dispatch(confirmEditBusinessName(_.trim(name)));
  }, [dispatch]);

  const onCancel = useCallback(() => {
    dispatch(confirmEditBusinessName(null));
  }, [dispatch]);

  const {
    values,
    errors,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormValidation({ name: businessName }, validation, onSubmit);

  if (!open) {
    return null;
  }

  return (
    <FullScreenDialog
      open
      onClose={onCancel}
      title={t('business_name_edit_title')}
      buttons={[{
        text: t('save'),
        disabled: !isValid || values.name === businessName,
        onClick: handleSubmit,
      }]}
    >
      <Box className={classes.root}>
        <TextField
          name="name"
          data-cy="EditBusinessNameModal_name_input"
          onChange={handleChange}
          onBlur={handleBlur}
          value={_.get(values, 'name')}
          error={!!errors.name}
          helperText={errors.name}
          variant="outlined"
          label={t('business_name')}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </Box>
    </FullScreenDialog>
  );
};

export default EditBusinessNameModal;
