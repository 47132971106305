import { call } from 'redux-saga/effects';
import api from '../../services/api';
import logger from '../../services/logger';

export default function* authorizeMailchimp({ payload }) {
  const {
    businessId,
    noRedirect,
    onDone,
  } = payload;
  try {
    const response = yield call(
      api.authorizeMailchimp,
      businessId,
      noRedirect || false,
    );
    if (response && response?.url) {
      if (onDone) {
        onDone(response?.url);
      }
    }
  } catch (ex) {
    logger.error({
      error: ex,
      context: { saga: 'authorizeMailchimp' },
      params: { payload },
    });
  }
}
