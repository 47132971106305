import { SET_ACTIVE_TAB } from '../constants';

export default {
  defaultState: {
    activeTab: 0,
  },
  reducers: {
    [SET_ACTIVE_TAB]: (state, { payload }) => ({ ...state, activeTab: payload }),
  },
};
