import _ from 'lodash';
import {
  call,
  put,
  select,
  fork,
} from 'redux-saga/effects';

import { getPaidAdProductInfo, getPaidAAPlusProductInfo } from '@targetable/targetable-web-framework/lib/services/paywallUtils';
import {
  showAddStoreModal,
  showBackdrop,
  showToaster,
  setLoadingAsync,
} from '../../actions';
import { selectBusiness, selectSubscription } from '../../selectors';
import api from '../../services/api';
import logger from '../../services/logger';
import { getToasterOptions, takeAsyncAction } from '../helpers';
import { ADD_STORE_CONFIRM_PAYMENT } from '../../constants';

import addStoreToStripeSubscription from '../addStoreToStripeSubscription/addStoreToStripeSubscription';
import createMultiStore from '../createMultiStore/createMultiStore';
import getStoresByBusiness from '../getStoresByBusiness/getStoresByBusiness';
import verifyAddStore from '../verifyAddStore/verifyAddStore';
import getStripeSubscription from '../getStripeSubscription/getStripeSubscription';

export default function* handleAddStore({ payload }) {
  let storeId;
  const { onDone } = payload;
  try {
    let business = yield select(selectBusiness);
    const subscription = yield select(selectSubscription);
    let paidAdProductInfo = getPaidAdProductInfo(subscription);
    if (!paidAdProductInfo) {
      // Check then if user has AA PLUS product on Stripe
      paidAdProductInfo = getPaidAAPlusProductInfo(subscription);
    }

    const status = _.get(paidAdProductInfo, 'status');
    const shouldUpdateStripe = !(_.isNil(status) || status === 'canceled') && !business.disableAutomaticBillingIncrement;

    yield put(showAddStoreModal(true));
    if (shouldUpdateStripe) {
      yield fork(verifyAddStore);
    }

    let businessId;
    let store;
    do {
      yield put(showBackdrop(false));
      yield put(showAddStoreModal(true));
      const { payload: storeData } = yield call(takeAsyncAction, ADD_STORE_CONFIRM_PAYMENT);

      if (!storeData) {
        yield put(showAddStoreModal(false));
        yield put(showBackdrop(false));
        yield put(setLoadingAsync(false));
        return;
      }

      const storeName = _.get(storeData, 'name');

      if (_.isNil(storeName)) {
        yield put(showAddStoreModal(false));
        yield put(showBackdrop(false));
        yield put(setLoadingAsync(false));
        return;
      }

      yield put(showBackdrop(true));
      business = yield select(selectBusiness);
      businessId = _.get(business, 'id');

      store = yield call(createMultiStore, businessId, storeName);
    } while (_.isNil(store) || _.isEmpty(store));

    storeId = _.get(store, 'id');

    yield call(getStoresByBusiness, businessId);
    try {
      yield call(api.createStoreHubspotRecord, storeId);
    } catch (e) {
      logger.error({
        error: `Failed to create hubspot record for store ${storeId} and business ${businessId}`,
        context: { saga: 'handleAddStore', severity: 'debug' },
        params: { storeId, businessId },
      });
    }
    yield put(showAddStoreModal(false));
    if (shouldUpdateStripe) {
      yield call(addStoreToStripeSubscription, storeId);
      yield call(getStripeSubscription);
      yield fork(api.updateStoreHubspotRecord, storeId, { storeTargetableSubscription: true });
    }
    yield put(showBackdrop(false));
    yield put(setLoadingAsync(false));
    if (onDone) {
      onDone();
    }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'handleAddStore' },
      params: { storeId },
    });

    if (_.isNil(storeId) === false) {
      yield call(api.deleteStore, storeId);
    }
    yield put(showAddStoreModal(false));
    yield put(showBackdrop(false));
    yield put(setLoadingAsync(false));
    yield put(showToaster(getToasterOptions('generic_request_error', 'error')));
  }
}
