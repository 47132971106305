import { put, call, select } from 'redux-saga/effects';
import {
  CAMPAIGN_MAILCHIMP_TYPE,
} from '@targetable/targetable-web-framework/lib/react/constants';
import i18n from '../../services/i18n';
import api from '../../services/api';
import { showToaster, setLoadingAsync } from '../../actions';
import { selectBusiness } from '../../selectors';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import getSmartFeed from '../getSmartFeed/getSmartFeed';
import { getCampaignsRoute, getManagedEmailMarketingRoute } from '../../routes';
import {
  LIVE_TAB,
} from '../../constants';
import history from '../../services/history';

export default function* publishMarketingAd({ payload }) {
  const {
    onDone,
    campaignAd,
    onError,
    defaultNote,
  } = payload;

  const business = yield select(selectBusiness);

  try {
    const publishMessage = campaignAd.type === CAMPAIGN_MAILCHIMP_TYPE
      ? 'Publishing the email...'
      : 'Publishing the Ad...';
    yield put(showToaster(getToasterOptions(publishMessage,
      'publish', null, 7000)));

    if (campaignAd?.group?.ads && defaultNote) {
      const questionnaireInfo = [
        { question: i18n.t('feedback_suggested_campaign_saved_question_1') },
        { question: i18n.t('feedback_suggested_campaign_saved_question_2') },
        { question: i18n.t('feedback_suggested_campaign_saved_question_3') },
      ];
      const feedback = questionnaireInfo.map((listItem) => ({
        sentiment: 'satisfied',
        question: listItem.question,
      }));
      feedback.push({
        question: i18n.t('feedback_additional_thoughts'),
        sentiment: 'satisfied',
        answer: defaultNote,
      });

      yield call(api.setAdFeedback, campaignAd.id, feedback, 'approved');
    }

    yield put(setLoadingAsync(true));

    if (!campaignAd?.group?.ads) {
      yield call(api.publishAd, campaignAd?.id);
    } else {
      // Google Group Campaign
      yield call(api.publishGroupAd, campaignAd?.id);
    }

    // At this point, the publidhing was successful
    if (onDone) { onDone(); }

    if (campaignAd.type === CAMPAIGN_MAILCHIMP_TYPE) {
      history.push(getManagedEmailMarketingRoute(business.id));
      history.go(0);
    } else {
      // Reload SmartFeed
      yield call(getSmartFeed);
    }

    yield put(setLoadingAsync(false));
    yield put(showToaster(getToasterOptions(
      i18n.t('suggested_campaign_submitted', { name: campaignAd?.name }),
      'info',
      {
        title: 'go_to_campaigns',
        href: getCampaignsRoute(business.id, LIVE_TAB),
      },
      5000,
    )));
  } catch (e) {
    yield put(showToaster(getToasterOptions(e?.response?.data.message || e?.message?.data || 'Failed to publish the Suggested Campaign. Try again in a few minutes.', 'error')));
    logger.error({
      error: e,
      context: { saga: 'publishMarketingAd' },
      params: { payload },
    });
    if (onError) {
      onError();
    }
  }
}
