import { call, put } from 'redux-saga/effects';

import api from '../../services/api';
import { setUserActive, showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* getUser(userId, returnAuth0Data) {
  try {
    const user = yield call(api.getUser, userId, returnAuth0Data);
    yield put(setUserActive(user));
    return user;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getUser' },
      params: { userId, returnAuth0Data },
    });
    yield put(showToaster(getToasterOptions('generic_request_error', 'error')));
    return undefined;
  }
}
