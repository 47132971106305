import { call, put } from 'redux-saga/effects';
import _ from 'lodash';
import {
  setBillingModalStage, setShowBillingModal, showToaster,
} from '../../actions';
import { CREATE_STRIPE_PAYMENT_METHOD } from '../../constants';
import logger from '../../services/logger';
import getStripeSubscription from '../getStripeSubscription/getStripeSubscription';
import { getToasterOptions, takeAsyncAction } from '../helpers';
import createStripePaymentMethod from '../createStripePaymentMethod/createStripePaymentMethod';

export default function* handleUpdateStripePaymentMethod() {
  yield put(setBillingModalStage('stripe-form'));
  yield put(setShowBillingModal({ show: true, includeCoupon: true, isUpdate: true }));

  while (true) {
    try {
      const action = yield call(takeAsyncAction, CREATE_STRIPE_PAYMENT_METHOD);
      if (!_.get(action, ['payload', 'token', 'id'])) {
        yield put(setShowBillingModal({ show: false }));
        return;
      }

      const validCoupon = yield call(
        createStripePaymentMethod,
        _.get(action, 'payload.token.id'),
        _.get(action, 'payload.coupon'),
        _.get(action, 'payload.onInvalidCoupon'),
      );
      if (validCoupon) {
        break;
      }
    } catch (ex) {
      logger.error({
        error: ex,
        context: { saga: 'handleUpdateStripePaymentMethod' },
      });
      yield put(showToaster(getToasterOptions('error_payment_method_error')));
    }
  }

  yield call(getStripeSubscription);

  yield put(setShowBillingModal({ show: false }));
}
