import _ from 'lodash';
import { all, put } from 'redux-saga/effects';
import { MarketingChannelTypes } from '@targetable/targetable-types/dist/types/marketing-channel/types';

import api from '../../services/api';
import { reportsLoading, setCampaignReports } from '../../actions';

export default function* getReportsForCampaigns(campaigns) {
  yield put(reportsLoading(true));

  // Filtering only Facebook campaigns
  const facebookCampaigns = campaigns.filter(
    (c) => !c?.type || c?.type === MarketingChannelTypes.Facebook,
  );

  // todo turn async into separate saga
  const reports = yield all(facebookCampaigns.map(async ({ id }) => {
    try {
      const res = await api.getFacebookCampaignMetrics(id);
      return { id, error: false, ...res };
    } catch (e) {
      return { id, error: true };
    }
  }));

  yield put(setCampaignReports(_.keyBy(reports, 'id')));
  yield put(reportsLoading(false));
}
