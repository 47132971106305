import {
  CLOSE_TOASTER,
  SHOW_TOASTER,
} from '../constants';

export default {
  defaultState: {
    duration: null,
    message: null,
    open: false,
    type: null,
  },
  reducers: {
    [CLOSE_TOASTER]: (state, { payload }) => ({ ...payload, open: false }),
    [SHOW_TOASTER]: (state, { payload }) => ({ ...state, ...payload }),
  },
};
