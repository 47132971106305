import {
  call, fork, put, select,
} from 'redux-saga/effects';

import { AdStatus } from '@targetable/targetable-types/dist/types/ad-service/types';
import api from '../../services/api';
import { setCampaigns, setBusinessCampaignsLoading } from '../../actions';
import { selectBusiness, selectStores } from '../../selectors';
import logger from '../../services/logger';
import getReportsForCampaigns from '../getReportsForCampaigns/getReportsForCampaigns';

export default function* getCampaignsByStoreAndStatus({ payload }) {
  yield put(setCampaigns([]));
  yield put(setBusinessCampaignsLoading(true));

  const business = yield select(selectBusiness);
  const stores = yield select(selectStores);

  let campaigns = [];

  try {
    ({ campaigns } = yield call(
      api.getCampaigns,
      stores,
      payload.status,
      business.id,
      null,
      payload.tab,
      null,
      true,
    ));
  } catch (e) {
    yield put(setBusinessCampaignsLoading(false));
    logger.error({
      error: e,
      context: { saga: 'getCampaignsByStoreAndStatus - getCampaigns' },
      params: {
        businessId: business.id,
        status: payload.status,
        stores,
        tab: payload.tab,
      },
    });
  }

  yield put(setBusinessCampaignsLoading(false));
  const skipStatuses = [AdStatus.Draft, AdStatus.Failed];
  const statuses = payload?.status?.split(',');
  const skipReportsLoading = statuses.some((status) => skipStatuses.includes(status));
  if (!skipReportsLoading) {
    yield fork(getReportsForCampaigns, campaigns);
  }

  yield put(setCampaigns(campaigns));
  return campaigns;
}
