import moment from 'moment';
import { call, put } from 'redux-saga/effects';

import api from '../../services/api';
import {
  showToaster,
} from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import getSmartFeed from '../getSmartFeed/getSmartFeed';

export default function* saveSuggestedCampaign({ payload }) {
  const { updatedSuggestedCampaign, onError, onDone } = payload;

  try {
    if (updatedSuggestedCampaign) {
      yield call(
        api.updateSuggestedCampaign,
        updatedSuggestedCampaign.id,
        { ...updatedSuggestedCampaign, saved: moment.utc().valueOf() },
      );
    }
    if (onDone) { onDone(); }
    if (showToaster) {
      yield put(showToaster(getToasterOptions('preview_locations_updated', 'info', null, 5000)));
    }
    yield call(getSmartFeed);
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'saveSuggestedCampaign' },
      params: { payload },
    });
    yield put(showToaster(getToasterOptions('saving_campaign_draft_from_suggested_error', 'error')));
    if (onError) {
      onError();
    }
  }
}
