import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { showFacebookPermissionsModal } from '../../actions';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  text: {
    marginBottom: theme.spacing(2),
  },
}));

const FacebookPageAdAccountManageError = ({
  pageId,
  store,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleShowMoreInfo = () => {
    dispatch(showFacebookPermissionsModal({ show: true, store }));
  };

  return (
    <>
      <Typography variant="subtitle1" className={classes.title}>
        {t('facebook_ad_account_manage_explanation_header')}
      </Typography>
      <Typography
        variant="body1"
        className={classes.text}
        dangerouslySetInnerHTML={{
          __html: t('facebook_ad_account_manage_explanation_text1', {
            storeLink: `<a href="https://www.facebook.com/${pageId}" target="_blank" rel="noopener noreferrer">https://www.facebook.com/${pageId}</a>`,
            interpolation: { escapeValue: false },
          }),
        }}
      />
      <Typography variant="body1" className={classes.text}>
        {t('facebook_ad_account_manage_explanation_text2')}
      </Typography>
      <Typography variant="body1" className={classes.text}>
        {t('facebook_ad_account_manage_explanation_text3')}
      </Typography>
      <Button onClick={handleShowMoreInfo} variant="text" color="secondary">
        {t('more_information')}
      </Button>
    </>
  );
};

FacebookPageAdAccountManageError.propTypes = {
  pageId: PropTypes.string.isRequired,
  store: PropTypes.shape({}).isRequired,
};

export default FacebookPageAdAccountManageError;
