import {
  call, put, select,
} from 'redux-saga/effects';

import _ from 'lodash';
import { selectBusiness, selectSubscription } from '../../selectors';
import { setBillingModalStage, setShowBillingModal, showToaster } from '../../actions';
import { CREATE_STRIPE_PAYMENT_METHOD } from '../../constants';
import getStripeSubscription from '../getStripeSubscription/getStripeSubscription';
import api from '../../services/api';
import history from '../../services/history';
import { getToasterOptions, takeAsyncAction } from '../helpers';
import { getHomeRoute } from '../../routes';

export default function* handleReactivateUser() {
  const business = yield select(selectBusiness);
  const subscription = yield select(selectSubscription);

  yield put(setBillingModalStage('stripe-form'));
  yield put(setShowBillingModal({ show: true, includeCoupon: false }));

  while (true) {
    const action = yield call(takeAsyncAction, CREATE_STRIPE_PAYMENT_METHOD);
    if (!_.get(action, ['payload', 'token', 'id'])) {
      yield put(setShowBillingModal({ show: false }));
      return;
    }

    const invoice = yield call(
      api.reactivateStripeSubscription,
      _.get(business, 'stripeCustomerId'),
      { quantity: _.get(subscription, 'quantity'), token: _.get(action, ['payload', 'token', 'id']) },
    );

    if (_.get(invoice, 'status') === 'succeeded') {
      break;
    }

    if (_.includes(['requires_payment_method', 'card_error'], _.get(invoice, 'status'))) {
      yield put(showToaster(getToasterOptions('error_payment_method_error')));
    } else {
      yield put(showToaster(getToasterOptions('error_processing_payment_method')));
    }
  }

  yield call(getStripeSubscription);

  yield put(setShowBillingModal({ show: false }));
  history.push(getHomeRoute(business?.id));
}
