import _ from 'lodash';
import {
  call,
  delay,
  fork,
  put,
  select,
  take,
} from 'redux-saga/effects';

import { getUserFacebookIntegration } from '@targetable/targetable-web-framework/lib/services/user';

import { DATA_INSTAGRAM_ACCOUNT, supressPermissionsCalls } from '../../constants';
import api from '../../services/api';
import logger from '../../services/logger';
import buildId from '../../services/buildId';
import { selectBusiness, selectUser, selectFacebookPermissions } from '../../selectors';
import {
  setInstagramAccount,
  showFacebookConnectBusinessManagerModal,
  showFacebookConnectAdAccountModal,
  showInstagramSelectModal,
  setInstagramAccounts,
  setLoadingAsync,
  showBackdrop,
} from '../../actions';
import showFacebookConfirmationDialog from '../showFacebookConfirmationDialog/showFacebookConfirmationDialog';
import getStoresByBusiness from '../getStoresByBusiness/getStoresByBusiness';
import getStoreFacebookPermissions from '../getStoreFacebookPermissions/getStoreFacebookPermissions';

export default function* connectInstagramAccount({ payload }) {
  yield put(showBackdrop(true));

  const onDone = _.get(payload, 'onDone');
  const store = _.get(payload, 'store');

  const user = yield select(selectUser);
  const business = yield select(selectBusiness);

  const storeIntegrations = _.get(store, 'integrations');
  const facebookIntegration = _.find(storeIntegrations, ({ type }) => type === 'facebook');
  const facebookUserIntegration = getUserFacebookIntegration(user);

  const accessToken = _.get(facebookUserIntegration, 'accessToken');
  const adAccountId = _.get(facebookIntegration, 'adAccountId');
  const fbBusinessId = _.get(facebookIntegration, 'businessId');
  const storeId = _.get(store, 'id');
  const storeName = _.get(store, 'name', '');

  if (!supressPermissionsCalls) {
    // loop for 1 min until facebook permissions for the store are loaded
    // this handles the case when a user re-authenticates with fb at the beginning of this process
    let facebookPermissions = yield select(selectFacebookPermissions);
    let trys = 0;

    if (!_.get(facebookPermissions, `stores[${store.id}]`)) {
      // Try to get the permissions for the integrated Store
      yield call(getStoreFacebookPermissions, store);
    }

    while (trys <= 20) {
      facebookPermissions = yield select(selectFacebookPermissions);
      const gotPermissions = _.get(facebookPermissions, `stores[${store.id}]`);
      if (gotPermissions) {
        break;
      }

      trys += 1;
      yield delay(3000);
    }

    if (!facebookPermissions) {
      yield put(showBackdrop(false));
      logger.error({
        error: 'Failed to connect to IG, store facebook permissions took longer than 1 minute to load',
        context: { saga: 'connectInstagramAccount' },
        params: {
          user, business, storeId, storeName,
        },
      });
      return;
    }

    const { business: businessPermissions, adAccount } = _.get(facebookPermissions, `stores[${store.id}]`, {});
    const businessPermission = _.get(businessPermissions, 'permission', false);
    const businessName = _.get(businessPermissions, 'name');
    const adAccountPermission = _.get(adAccount, 'permission', false);
    const adAccountName = _.get(adAccount, 'name');

    yield fork(api.updateStoreHubspotRecord, storeId, { initialInstagramAttempt: true });

    if (!businessPermission) {
      yield put(showBackdrop(false));
      const businessId = _.get(store, 'businessId', '');
      yield call(showFacebookConfirmationDialog, {
        isOpen: true,
        text: 'business_store_permission_text',
        textParams: { businessId: buildId(businessId, businessName) },
        moreInfoDataCy: 'cta-business_permission_more_info',
        moreInfoText: 'more_info',
        cancelText: 'cancel',
        cancelDataCy: 'cta-business_permission_cancel',
        title: 'business_store_permission_title',
        titleParams: { storeName },
      });
      return;
    }

    if (!adAccountPermission) {
      yield put(showBackdrop(false));
      yield call(showFacebookConfirmationDialog, {
        isOpen: true,
        text: 'adaccount_store_permission_text',
        textParams: { adAccountId: buildId(adAccountId, adAccountName) },
        moreInfoDataCy: 'cta-business_permission_more_info',
        moreInfoText: 'more_info',
        cancelText: 'cancel',
        cancelDataCy: 'cta-business_permission_cancel',
        title: 'adaccount_store_permission_title',
        titleParams: { storeName },
      });
      return;
    }
  }

  let success = false;
  try {
    const bmInstagramAccounts = yield call(
      api.getBusinessManagerInstagramAccounts, fbBusinessId, accessToken,
    );

    if (bmInstagramAccounts && bmInstagramAccounts.length) {
      let instagramAccount;
      if (bmInstagramAccounts.length > 1) {
        // set instagram accounts to display to the user
        yield put(setInstagramAccounts(bmInstagramAccounts));
        // re-enable the async buttons, the modal button was disabled when it shouldn't have been
        yield put(setLoadingAsync(false));

        // disable the backdrop before showing the modal
        yield put(showBackdrop(false));

        yield put(showInstagramSelectModal(true));

        // wait for the user to select an ig account
        const { payload: selectedAccount } = yield take(DATA_INSTAGRAM_ACCOUNT);

        // show backdrop again after user selection
        yield put(showBackdrop(true));

        // no need to set the selected account, DATA_INSTAGRAM_ACCOUNT is being reduced
        // clean up
        yield put(setInstagramAccounts(null));
        yield put(showInstagramSelectModal(false));
        instagramAccount = selectedAccount;
      } else {
        ([instagramAccount] = bmInstagramAccounts);
        yield put(setInstagramAccount(instagramAccount));
      }
      const bmInstagramAccountId = _.get(instagramAccount, 'id');
      const bmInstagramAccountUsername = _.get(instagramAccount, 'username');

      const adAccountInstagramAccounts = yield call(
        api.getAdAccountInstagramAccounts, adAccountId, accessToken,
      );
      if (adAccountInstagramAccounts && adAccountInstagramAccounts.length) {
        const adAccountInstagramAccount = _.find(
          adAccountInstagramAccounts,
          { id: bmInstagramAccountId },
        );

        if (adAccountInstagramAccount) {
          // update the store with IG data
          facebookIntegration.instagramAccountId = bmInstagramAccountId;
          facebookIntegration.instagramUsername = bmInstagramAccountUsername;
          yield call(api.updateStore, storeId, _.omit(store, ['imageUrl']));

          yield call(api.updateStoreHubspotRecord, storeId, { igConnected: true });

          // clean up redux
          yield put(showFacebookConnectBusinessManagerModal({ show: false }));
          yield put(showFacebookConnectAdAccountModal({ show: false, store: null }));
          yield put(setInstagramAccount(null));
        } else {
          // set the username for modal link to IG account
          facebookIntegration.instagramUsername = bmInstagramAccountUsername;
          yield fork(api.updateStoreHubspotRecord, storeId, { initialInstagramFail: true });
          yield put(showBackdrop(false)); // disable the backdrop before showing the modal
          yield put(showFacebookConnectAdAccountModal({ show: true, store }));
        }
      } else {
        // set the username for modal link to IG account
        facebookIntegration.instagramUsername = bmInstagramAccountUsername;
        yield put(showBackdrop(false)); // disable the backdrop before showing the modal
        yield put(showFacebookConnectAdAccountModal({ show: true, store }));
      }
      yield call(getStoresByBusiness, _.get(business, 'id'));
      success = true;
    } else {
      // eslint-disable-next-line max-len
      yield fork(api.updateStoreHubspotRecord, storeId, { initialInstagramFail: true });

      // disable the backdrop before showing the modal
      yield put(showBackdrop(false));
      yield put(showFacebookConnectBusinessManagerModal({ show: true, store }));

      if (onDone) {
        onDone(false);
      }
      return;
    }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'connectInstagramAccount' },
      params: {
        user, business, storeId, storeName,
      },
    });
    yield fork(api.updateStoreHubspotRecord, storeId, { initialInstagramFail: true });
  }

  yield put(showBackdrop(false));

  if (success) {
    yield fork(api.updateStoreHubspotRecord, storeId, { initialInstagramSuccess: true });
  }

  if (onDone) {
    onDone(true);
  }
}
