import _ from 'lodash';
import { call, put, select } from 'redux-saga/effects';

import { getUserFacebookIntegration } from '@targetable/targetable-web-framework/lib/services/user';

import {
  showToaster,
  setFacebookPagesLoading,
  setFacebookPages,
} from '../../actions';
import { selectUser } from '../../selectors';
import api from '../../services/api';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* getFacebookPages() {
  const user = yield select(selectUser);

  try {
    const facebookIntegration = getUserFacebookIntegration(user);

    const fbUserId = _.get(facebookIntegration, 'userId');
    yield put(setFacebookPagesLoading(true));
    const facebookPages = yield call(api.getFacebookPages, fbUserId, user.id);
    yield put(setFacebookPages(facebookPages));
    yield put(setFacebookPagesLoading(false));
    return facebookPages;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getFacebookPages' },
      params: { user },
    });
    yield put(showToaster(getToasterOptions('generic_request_error', 'error')));
    yield put(setFacebookPagesLoading(false));
    return [];
  }
}
