import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import AsyncButton from '@targetable/targetable-web-framework/lib/react/components/AsyncButton/AsyncButton';
import i18n from '../../../../services/i18n';
import { billingModalStripeExit } from '../../../../actions';

const styles = (theme) => ({
  button: {
    width: '192px',
  },
  container: {
    padding: '0 16px',
    textAlign: 'center',
  },
  header: {
    margin: theme.spacing(5, 0, 4),
  },
  text: {
    margin: theme.spacing(0, 0, 4),
  },
});

const StripeOutro = ({ classes, billingModalStripeExitAction }) => (
  <div className={classes.container}>
    <Typography variant="h2" className={classes.header}>
      {i18n.t('stripe_outro_header')}
    </Typography>
    <Typography variant="subtitle1" className={classes.text}>
      {i18n.t('stripe_outro_text')}
    </Typography>
    <AsyncButton
      className={classes.button}
      variant="contained"
      color="secondary"
      onClick={billingModalStripeExitAction}
      data-cy="cta-complete_targetable_subscription_required"
    >
      {i18n.t('continue')}
    </AsyncButton>
  </div>
);

StripeOutro.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string,
    container: PropTypes.string,
    header: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
  billingModalStripeExitAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  billingModalStripeExitAction: billingModalStripeExit,
}, dispatch);

export default connect(
  null, mapDispatchToProps,
)(withStyles(styles)(StripeOutro));
