import { call, put } from 'redux-saga/effects';
import _ from 'lodash';

import api from '../../services/api';
import { showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import createStore from '../createStore/createStore';
import updateStore from '../updateStore/updateStore';

export default function* upsertStore({
  id,
  businessId,
  name,
}) {
  try {
    let storeId = id;
    let store;
    if (_.isNil(storeId)) {
      store = yield call(createStore, businessId, name);
      storeId = _.get(store, 'id');
    } else {
      store = yield call(updateStore, {
        payload: {
          id,
          businessId,
          name,
        },
      });
    }

    // create and update already handle themselves
    // having an error, so just return
    if (_.isNil(store)) {
      return undefined;
    }

    if (_.has(store, 'hubSpotId') === false) {
      try {
        yield call(api.createStoreHubspotRecord, storeId);
      } catch (e) {
        logger.error({
          error: `Failed to create hubspot record for store ${storeId}`,
          context: { saga: 'upsertStore', severity: 'debug' },
          params: {
            id, businessId, name,
          },
        });
      }
    }

    return store;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'upsertStore' },
      params: {
        storeId: id, businessId, name,
      },
    });
    yield put(showToaster(getToasterOptions('generic_request_error', 'error')));
    return undefined;
  }
}
