import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useList from 'react-use/lib/useList';
import useBoolean from 'react-use/lib/useBoolean';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Block from '@material-ui/icons/Block';
import Button from '@material-ui/core/Button';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';

import FeedbackHeader from '@targetable/targetable-web-framework/lib/react/campaignWizard/components/FeedbackHeader/FeedbackHeader';
import FeedbackSentimentQuestion from '@targetable/targetable-web-framework/lib/react/campaignWizard/components/FeedbackSentimentQuestion/FeedbackSentimentQuestion';
import TextField from '@targetable/targetable-web-framework/lib/react/components/TextField/TextField';
import { InsightTypes } from '@targetable/targetable-types/dist/types/insight';
import {
  LEGACY_CAMPAIGN,
  SMARTFEED_ACTION_APPROVED,
  SMARTFEED_ACTION_APPROVED_DRAFT,
  SMARTFEED_ACTION_REJECTED,
  SMARTFEED_TYPE_INSIGHT,
  SUBSCRIPTION_CANCELLATION,
} from '../../constants';
import {
  closeFeedbackDialog,
  submitFeedback,
  showConfirmationDialog,
  submitMarketingAdFeedback,
} from '../../actions';
import { selectFeedback } from '../../selectors';
import {
  getCancelCTA,
  getCardTitle,
  getFeedbackQuestions,
  getHeaderTitle,
  // getSubmitCTA,
  validate,
  getButtonText,
  getConfirmationText,
  getHeaderTitleParams,
} from '../../services/feedback';

/* eslint-disable max-len */
/**
 * @typedef {import('@targetable/targetable-types/dist/types').UserFeedback} UserFeedback
 */
/* eslint-enable max-len */

const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.up('sm')]: {
      padding: `0 ${theme.spacing(4)}px`,
    },
  },
  actions: {
    margin: `${theme.spacing(2)}px 0`,
  },
  approvedIcon: {
    color: '#2fc600',
  },
  rejectedIcon: {
    color: '#ed2a2c',
  },
  googleIcon: {
    fontSize: '17px',
    color: '#000000',
    position: 'relative',
    top: '2px',
  },
}));

/* List of campaign types that are supported by the new Ad API */
const AdTypes = ['google', 'mailchimp'];

/**
 * Translate the feedback dialog value into a human readable string
 * @param {number} value the satisfaction value (1, 3 or 5)
 * @returns {"satisfied" | "dissatisfied" | "neutral"}
 */
const valueToSentiment = (value) => {
  if (value === 5) return 'satisfied';
  if (value === 3) return 'neutral';
  return 'dissatisfied';
};

const GoogleIcon = () => (
  <SvgIcon>
    <path fillRule="evenodd" clipRule="evenodd" d="M0 12C0 5.373 5.373 0 12 0C18.627 0 24 5.373 24 12C24 18.627 18.627 24 12 24C5.373 24 0 18.627 0 12ZM12 13.4V11H18.61C18.67 11.35 18.721 11.7 18.721 12.16C18.721 16.16 16.04 19 12 19C8.13 19 5 15.87 5 12C5 8.13 8.13 5 12 5C13.89 5 15.47 5.69 16.69 6.83L14.79 8.66C14.27 8.16 13.36 7.58 12 7.58C9.61 7.58 7.66 9.56 7.66 12C7.66 14.441 9.61 16.42 12 16.42C14.77 16.42 15.81 14.429 15.97 13.4H12Z" fill="#9FA1AF" />
  </SvgIcon>
);

const FeedbackDialog = ({
  closeFeedbackDialogAction,
  feedback,
  submitFeedbackAction,
  showConfirmationDialogAction,
  submitAdFeedbackAction,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isSubmitting, setSubmitting] = useBoolean(false);
  const [list, { set }] = useList([]);
  const [textArea, setTextArea] = useState('');
  const itemType = feedback?.item?.type || LEGACY_CAMPAIGN;
  const feedbackQuestionnaire = feedback?.item?.questionnaire;

  // reset dialog after close
  // initialize list on open
  useEffect(() => {
    if (!feedback.open && list.length) {
      set([]);
      setTextArea('');
      setSubmitting(false);
    } else if (feedback.open && !list.length) {
      // Check the questionnaire Questions if provided, otherwise, use default hard-coded
      if (feedbackQuestionnaire) {
        set(feedbackQuestionnaire.questions);
      } else {
        set(getFeedbackQuestions(feedback.type, feedback.action, itemType));
      }
    }
  }, [
    feedback.open, feedbackQuestionnaire, feedback.action,
    feedback.type, list, set, setSubmitting, itemType,
  ]);

  // prevent prop type warnings and bugs when data is null
  if (!feedback.open) {
    return null;
  }

  const isValid = validate(list);
  const cancelCTA = getCancelCTA(feedback.type);
  // const submitCTA = getSubmitCTA(feedback.type);

  let ActionIcon;
  switch (feedback.action) {
    case SUBSCRIPTION_CANCELLATION:
    case SMARTFEED_ACTION_APPROVED:
    case SMARTFEED_ACTION_APPROVED_DRAFT:
      ActionIcon = <CheckCircleOutline className={classes.approvedIcon} variant="small" />;
      break;
    case SMARTFEED_ACTION_REJECTED:
      ActionIcon = <Block className={classes.rejectedIcon} variant="small" />;
      break;
    default:
  }

  const typeIcon = () => {
    if (feedback.type === SMARTFEED_TYPE_INSIGHT) {
      if (feedback.item?.type === InsightTypes.CampaignUpdate) {
        return GoogleIcon;
      }
      return NewReleasesIcon;
    }
    return CheckBoxIcon;
  };

  const handleCancel = () => {
    showConfirmationDialogAction({
      text: getConfirmationText(feedback.type, feedback.action),
      confirmDataCy: 'cta-feedback_cancel_confirmation_confirm',
      cancelDataCy: 'cta-feedback_cancel_confirmation_cancel',
      onClick: (confirmed) => {
        if (confirmed) {
          closeFeedbackDialogAction();
          if (feedback.type === SUBSCRIPTION_CANCELLATION) {
            feedback.item?.onCompleted();
          }
        }
      },
    });
  };

  const handleSelect = (index, val, icon) => {
    const newList = [...list];
    newList[index].displayOrder = index;
    newList[index].icon = icon;
    newList[index].value = val;
    set(newList);
  };

  const handleSubmit = () => {
    setSubmitting(true);

    const onError = () => {
      setSubmitting(false);
    };

    const isAdType = AdTypes.includes(itemType);
    if (!isAdType) {
      const newList = [...list];
      newList.push({
        displayOrder: newList.length + 1,
        question: t('feedback_additional_thoughts'),
        ...(textArea ? { value: textArea } : {}),
      });

      // Remove questionnaire if provided
      const { questionnaire, ...rawFeedback } = feedback.item;

      submitFeedbackAction({
        action: feedback.action,
        campaign: feedback.campaign,
        data: {
          ...rawFeedback,
          feedback: {
            questions: newList,
          },
        },
        onError,
        type: feedback.type,
        validatedCampaign: feedback.validatedCampaign,
        onCompleted: feedback?.item?.onCompleted || feedback?.onCompleted,
        onCancellingError: feedback?.item?.onError,
        subscriptionItems: feedback?.item?.subscriptionItems,
      });
    } else {
      /** @type UserFeedback[] */
      const adFeedback = list.map((listItem) => ({
        question: listItem.question,
        sentiment: valueToSentiment(listItem.value),
      }));

      // Add final general comments if any
      if (textArea.trim()) {
        adFeedback.push({
          question: t('feedback_additional_thoughts'),
          answer: textArea,
        });
      }

      submitAdFeedbackAction({
        campaignAd: feedback.item,
        feedback: adFeedback,
        decision: feedback.action === SMARTFEED_ACTION_APPROVED ? 'approved' : 'rejected',
        onError,
        hasUnSavedChanges: feedback.hasUnSavedChanges,
      });
    }
  };

  return (
    <Dialog
      onClose={handleCancel}
      maxWidth="sm"
      fullWidth
      open={feedback.open}
      classes={{
        paper: classes.dialog,
      }}
      data-cy="feedbackDialog_modal"
    >
      <FeedbackHeader
        actionIcon={ActionIcon}
        cancelCTA={cancelCTA}
        disableDismiss={isSubmitting}
        onCancel={handleCancel}
        title={getHeaderTitle(feedback.type, feedback.action, feedback.item)}
        titleParams={
          feedback.item?.type === InsightTypes.CampaignUpdate
          ? { typeName: getHeaderTitleParams(feedback.item) } : undefined
        }
        typeIcon={typeIcon()}
      />
      <DialogTitle disableTypography>
        <Typography variant="h2">
          {getCardTitle(feedback.item, feedback.campaign)}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {list.map((feedbackQuestion, index) => (
          <FeedbackSentimentQuestion
            key={feedbackQuestion.question}
            question={feedbackQuestion.question}
            onSelect={(val, icon) => handleSelect(index, val, icon)}
          />
        ))}
        <TextField
          margin="normal"
          variant="outlined"
          placeholder={t('feedback_additional_thoughts')}
          rows={8}
          multiline
          fullWidth
          value={textArea}
          onChange={(e) => setTextArea(e.target.value)}
          data-cy="feedbackDialog_additionalFeedback_textarea"
        />
        <Grid container className={classes.actions}>
          <Grid item xs={6} sm={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!isValid || isSubmitting}
              data-cy="feedbackDialog_approve_button"
              fullWidth
            >
              {getButtonText(feedback.type, feedback.action)}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

FeedbackDialog.propTypes = {
  closeFeedbackDialogAction: PropTypes.func.isRequired,
  feedback: PropTypes.shape({
    action: PropTypes.string,
    campaign: PropTypes.shape({}),
    item: PropTypes.shape({
      type: PropTypes.string,
      onCompleted: PropTypes.func,
      onError: PropTypes.func,
      subscriptionItems: PropTypes.arrayOf(PropTypes.shape({})),
      questionnaire: PropTypes.shape({
        questions: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
    open: PropTypes.bool,
    type: PropTypes.string,
    onCompleted: PropTypes.func,
    validatedCampaign: PropTypes.bool,
    onError: PropTypes.func,
    hasUnSavedChanges: PropTypes.bool,
  }).isRequired,
  submitFeedbackAction: PropTypes.func.isRequired,
  submitAdFeedbackAction: PropTypes.func.isRequired,
  showConfirmationDialogAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  feedback: selectFeedback(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  closeFeedbackDialogAction: closeFeedbackDialog,
  submitFeedbackAction: submitFeedback,
  submitAdFeedbackAction: submitMarketingAdFeedback,
  showConfirmationDialogAction: showConfirmationDialog,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackDialog);
