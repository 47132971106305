import {
  call, put, select, all,
} from 'redux-saga/effects';

import _ from 'lodash';
import api from '../../services/api';
import { selectBusiness } from '../../selectors';
import { showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import getStripeSubscription from '../getStripeSubscription/getStripeSubscription';

/**
 * Deletes de passed Subscription Items from the subscription
 * @param {Function} onCompleted a function to invoke once the saga has completed successfully.
 */
export default function* deleteStripeSubscriptionItem(payload) {
  const {
    onCompleted, subscriptionItems, onError,
  } = payload;

  const business = yield select(selectBusiness);

  try {
    const calls = [];
    _.forEach(subscriptionItems, ({ subscriptionId, subscriptionItemId }) => {
      calls.push(call(
        api.deleteStripeSubscriptionItem,
        business?.stripeCustomerId,
        subscriptionId,
        subscriptionItemId,
      ));
    });

    yield all(calls);

    // reload subscriptions info
    yield call(getStripeSubscription, { payload: business?.stripeCustomerId });
    yield put(showToaster(getToasterOptions('The subscription products have been removed.', 'success', null, 3000)));
    if (onCompleted) { onCompleted(); }
  } catch (ex) {
    logger.error({
      error: ex,
      context: { saga: 'deleteStripeSubscriptionItem' },
      params: { business, subscriptionItems },
    });
    yield put(showToaster(getToasterOptions('error_delete_subscription_item')));
    if (onError) { onError(); }
  }
}
