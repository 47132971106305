import {
  DATA_BILLING_MODAL_STAGE,
  DATA_FACEBOOK_PAYMENT_METHOD,
  DATA_SHOW_BILLING_MODAL,
  DATA_SUBSCRIPTION,
  DATA_SHOW_REACTIVATE_SUBSCRIPTION_MODAL,
  DATA_INVOICE_HISTORY,
  DATA_INVOICE_HISTORY_LOADING,
  DATA_BILLING_SUBSCRIPTION_LOADING,
} from '../constants';

export default {
  defaultState: {
    facebook: {
      paymentMethods: [],
    },
    subscription: {},
    subscriptionLoading: false,
    invoiceHistory: {
      loading: false,
      invoices: [],
    },
    modalStage: '',
    showModal: false,
    includeCoupon: false,
    isUpdate: false,
    showReactivateSubscriptionModal: false,
  },
  reducers: {
    [DATA_SHOW_BILLING_MODAL]: (state, { payload }) => ({
      ...state,
      showModal: payload.show,
      includeCoupon: payload.includeCoupon,
      isUpdate: payload.isUpdate,
    }),
    [DATA_SUBSCRIPTION]: (state, { payload }) => ({
      ...state,
      subscription: { ...state.subscription, ...payload },
    }),
    [DATA_BILLING_SUBSCRIPTION_LOADING]: (state, { payload: subscriptionLoading }) => ({
      ...state,
      subscriptionLoading,
    }),
    [DATA_BILLING_MODAL_STAGE]: (state, { payload }) => ({ ...state, modalStage: payload }),
    [DATA_FACEBOOK_PAYMENT_METHOD]: (state, { payload: paymentMethods }) => (
      { ...state, facebook: { ...state.facebook, paymentMethods } }
    ),
    [DATA_SHOW_REACTIVATE_SUBSCRIPTION_MODAL]: (state, { payload }) => ({
      ...state,
      showReactivateSubscriptionModal: payload,
    }),
    [DATA_INVOICE_HISTORY]: (state, { payload }) => ({
      ...state,
      invoiceHistory: {
        ...state.invoiceHistory,
        invoices: payload,
      },
    }),
    [DATA_INVOICE_HISTORY_LOADING]: (state, { payload }) => ({
      ...state,
      invoiceHistory: {
        ...state.invoiceHistory,
        loading: payload,
      },
    }),
  },
};
