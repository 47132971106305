import { DATA_PUBLISH_MARKETING_DIALOG } from '../constants';

const defaultState = {
  confirmText: undefined,
  confirmDataCy: undefined,
  isOpen: undefined,
  text: undefined,
  textParams: undefined,
  users: [],
};

export default {
  defaultState,
  reducers: {
    [DATA_PUBLISH_MARKETING_DIALOG]: (state, { payload }) => ({ ...state, ...payload }),
  },
};
