import moment from 'moment';
import _ from 'lodash';
import { getFacebookProfileUrl, getStoreFacebookIntegration } from '@targetable/targetable-web-framework/lib/services/store';
import { getStaticImage } from '@targetable/targetable-web-framework/lib/services/static';

import { CAMPAIGN_DRAFT } from '../constants';
import globals from '../globals';

// eslint-disable-next-line import/prefer-default-export
export const getDefaultCampaign = (user, stores, business) => {
  const beginDate = moment();
  const endDate = moment().add(6, 'd');

  return {
    businessId: business.id,
    status: CAMPAIGN_DRAFT,
    created: moment.utc().valueOf(),
    name: 'First Campaign',
    goal: 'standard',
    objective: 'reach',
    ads: stores.map((store) => {
      let url = getFacebookProfileUrl(user, stores, store.id);
      if (_.isEmpty(url)) {
        url = getStaticImage(globals.STATIC_BASE_URL, 'generic_profile_picture.png');
      }
      const facebookIntegration = getStoreFacebookIntegration(stores, store.id);
      const pageId = _.get(facebookIntegration, 'pageId');
      return (
        {
          storeId: store.id,
          ad: {
            adCards: [
              {
                type: 'image',
                image: { src: url },
                link: `https://www.facebook.com/${pageId}`,
                subMessage: 'Come in Today!',
              },
              { type: 'map' },
            ],
            adPlacement: {
              facebook: true,
              instagram: false,
            },
            callToAction: 'GET_DIRECTIONS',
            mainMessage: `Come in to ${business.name} today!`,
            /* seeMoreLink: `https://www.facebook.com/${pageId}`, */
            type: 'carousel',
          },
          budget: {
            type: 'lifetime',
            amount: 3500,
            currency: 'USD',
            locale: 'en-US',
            precision: 2,
          },
          schedule: {
            days: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
            startDate: { month: beginDate.format('MM'), day: beginDate.format('DD'), year: beginDate.format('YYYY') },
            endDate: { month: endDate.format('MM'), day: endDate.format('DD'), year: endDate.format('YYYY') },
            startTime: { hour: 0, minute: 0 },
            endTime: { hour: 23, minute: 59 },
          },
          targeting: {
            gender: 'all',
            languages: [{ id: '6', value: 'English (US)' }],
            minAge: 21,
            maxAge: 65,
            radius: 5,
            specificTargets: [],
          },
        }
      );
    }),
  };
};

export const getCampaignFromSuggestedCampaign = (suggestedCampaign) => {
  const clonedCampaign = _.cloneDeep(suggestedCampaign);
  clonedCampaign.status = 'draft';
  return _.omit(clonedCampaign, [
    'id', 'displayDate', 'displayTime', 'created', 'updated', 'feedback', 'published', 'responded',
  ]);
};
