import { put } from 'redux-saga/effects';

import { getCampaignsRoute } from '../../routes';
import history from '../../services/history';
import { setCampaign } from '../../actions';
import localStorage from '../../services/localStorage';

export default function* closeCampaignWizard() {
  const previousUrl = localStorage.getItem('CREATE_CAMPAIGN_BACK_URL_KEY');
  history.push(previousUrl || getCampaignsRoute());
  yield put(setCampaign(null));
}
