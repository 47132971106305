import _ from 'lodash';

export const getBusinessesNotAlreadyStores = (connectedBusinesses = [], stores = []) => {
  const businesses = [];

  if (_.size(connectedBusinesses)) {
    connectedBusinesses.forEach((business) => {
      const locations = _.get(business, 'locations');

      if (_.size(locations)) {
        locations.forEach((location) => {
          const tmpBusiness = { ...business, location };
          delete tmpBusiness.locations;

          const existingStore = _.find(stores, (store) => {
            const storeIntegration = _.find(_.get(store, 'integrations'), { type: 'facebook' });
            return _.get(storeIntegration, 'pageId') === _.get(tmpBusiness, 'pageId')
              && _.get(storeIntegration, 'location.id') === _.get(tmpBusiness, 'location.id');
          });

          if (_.isNil(existingStore)) {
            businesses.push(tmpBusiness);
          }
        });
      }
    });
  }

  return businesses;
};

export const hasPermissions = (facebookPermissions, storeId) => {
  const permissions = _.get(facebookPermissions, ['stores', storeId], {});
  const hasPermsCount = _.compact(
    Object.values(permissions).map(({ permission }) => permission),
  ).length;
  const permsCount = Object.keys(permissions).length;
  // must have at least one permission available to check against and has true for all permissions
  return !!permsCount && hasPermsCount === permsCount;
};

export const hasPermission = (facebookPermissions, storeId, permission) => {
  const permissions = _.get(facebookPermissions, ['stores', storeId], {});
  return _.get(permissions, [permission, 'permission']) === true;
};

export const hasAdAccountPermission = (facebookPermissions, storeId) => (
  hasPermission(facebookPermissions, storeId, 'adAccount')
);

export const hasPagePermission = (facebookPermissions, storeId) => (
  hasPermission(facebookPermissions, storeId, 'page')
);

export const hasBusinessPermission = (facebookPermissions, storeId) => (
  hasPermission(facebookPermissions, storeId, 'business')
);

export const getConnectedPageIdOfStore = (store) => {
  const integrations = _.get(store, 'integrations', []);
  const facebookIntegration = _.find(integrations, ({ type }) => type === 'facebook');
  return _.get(facebookIntegration, 'pageId');
};

export const isFacebookConnected = (store) => {
  const integrations = _.get(store, 'integrations', []);
  const fbIntegration = _.find(integrations, ({ type }) => type === 'facebook');
  const isConnected = fbIntegration
    && fbIntegration.adAccountId
    && fbIntegration.businessId
    && fbIntegration.location
    && fbIntegration.pageId;
  return !!isConnected;
};

export const getNotConnectedFacebookPages = (facebookPages, stores) => {
  const connectedStores = _.filter(stores, (store) => isFacebookConnected(store));
  return _.filter(facebookPages, (facebookPage) => (
    !_.some(connectedStores, (store) => getConnectedPageIdOfStore(store) === facebookPage.pageId)
  ));
};

export const mapConnectedFacebookPages = (facebookPages, stores) => {
  const connectedStores = _.filter(stores, (store) => isFacebookConnected(store));
  return _.map(facebookPages, (facebookPage) => {
    const connectedStore = _.find(
      connectedStores, (store) => getConnectedPageIdOfStore(store) === facebookPage.pageId,
    );
    return {
      ...facebookPage,
      isConnected: !_.isEmpty(connectedStore),
      ...(!_.isEmpty(connectedStore) && { storeName: connectedStore.name }),
    };
  });
};
