import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import { getFullName } from '@targetable/targetable-web-framework/lib/utils/user';
import api from '../../services/api';
import { showToaster, getBusinessUsers } from '../../actions';
import i18n from '../../services/i18n';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import { selectUser } from '../../selectors';

export default function* removeUser(action) {
  const currentUser = yield select(selectUser);
  const user = action.payload;
  const name = getFullName(user);
  const email = _.get(user, 'email');
  try {
    yield call(api.deleteUser, user.id);
    // yield call(api.createUserLogs, _.get(currentUser, 'id'), {
    //   timestamp: new Date().valueOf(),
    //   userId: _.get(currentUser, 'id'),
    //   viewedUrl: window.location.href,
    //   deletedUser: _.get(user, 'id'),
    // });
    yield put(showToaster(getToasterOptions(i18n.t('user_removed_successfully', { name, email }), 'info', null, 5000)));
    yield put(getBusinessUsers(true));
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'removeUser' },
      params: {
        userId: currentUser.id, user, name, email,
      },
    });
    yield put(showToaster(getToasterOptions(i18n.t('error_remove_user', { name, email }))));
  }
}
