import { call, put } from 'redux-saga/effects';

import { getSuggestedCampaignDetailRoute } from '../../routes';
import history from '../../services/history';
import {
  clearSuggestedCampaign,
  setCampaign,
  setSuggestedCampaign,
  showToaster,
  closeToaster,
} from '../../actions';
import { getCampaignFromSuggestedCampaign } from '../../services/campaign';
import getCampaignAdCardPresets from '../getCampaignAdCardPresets/getCampaignAdCardPresets';
import getCampaignUrlPresets from '../getCampaignUrlPresets/getCampaignUrlPresets';
import getCustomAudiences from '../getCustomAudiences/getCustomAudiences';
import { getToasterOptions } from '../helpers';

export default function* openSuggestedCampaignDetail({ payload }) {
  const { suggestedCampaign, locationHasChanged } = payload;
  yield put(clearSuggestedCampaign());
  yield put(showToaster(getToasterOptions('opening_existing_campaign', 'info', null, 10000)));
  yield put(setSuggestedCampaign(suggestedCampaign));

  const campaign = getCampaignFromSuggestedCampaign(suggestedCampaign);
  yield put(setCampaign(campaign));

  yield call(getCampaignUrlPresets, {
    payload: {
      businessId: suggestedCampaign.businessId,
      filter: true,
      backfill: true,
      reset: true,
    },
  });
  yield call(getCampaignAdCardPresets, suggestedCampaign, true, true);

  yield call(getCustomAudiences, suggestedCampaign.businessId, true);

  if (locationHasChanged) {
    yield put(showToaster({
      duration: 5000,
      message: 'preview_locations_updated',
      open: true,
      type: 'info',
    }));
  }

  history.push(getSuggestedCampaignDetailRoute(suggestedCampaign.businessId, suggestedCampaign.id));
  yield put(closeToaster());
}
