import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { showFacebookPermissionsModal } from '../../actions';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  text: {
    marginBottom: theme.spacing(2),
  },
}));

const AdAccountManageError = ({
  store,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleShowMoreInfo = () => {
    dispatch(showFacebookPermissionsModal({ show: true, store }));
  };

  return (
    <>
      <Typography variant="subtitle1" className={classes.title}>
        {t('ad_account_manage_explanation_header')}
      </Typography>
      <Typography variant="body1" className={classes.text}>
        {t('ad_account_manage_explanation_text')}
      </Typography>
      <Button onClick={handleShowMoreInfo} variant="text" color="secondary">
        {t('more_information')}
      </Button>
    </>
  );
};

AdAccountManageError.propTypes = {
  store: PropTypes.shape({}).isRequired,
};

export default AdAccountManageError;
