import React from 'react';
import { Route, Switch } from 'react-router-dom';
import qs from 'qs';
import reactLazyLoad from '@targetable/targetable-web-framework/lib/utils/react-lazy-retry';

import SecureRoute from '../SecureRoute/SecureRoute';
import auth from '../../services/auth';
import { initUser } from '../../actions';
import store from '../../store';
import * as routes from '../../routes';
import logger from '../../services/logger';

export const AddUser = reactLazyLoad(() => import('../../pages/AddUser/AddUser'));
export const Businesses = reactLazyLoad(() => import('../../pages/Businesses/Businesses'));
export const BusinessProfile = reactLazyLoad(() => import('../../pages/BusinessProfile/BusinessProfile'));
export const Campaigns = reactLazyLoad(() => import('../../pages/Campaigns/Campaigns'));
export const CampaignReview = reactLazyLoad(() => import('../../pages/CampaignReview/CampaignReview'));
export const CampaignWizardDetail = reactLazyLoad(() => import('../../pages/CampaignWizardDetail/CampaignWizardDetail'));
export const GlobalError = reactLazyLoad(() => import('../../pages/GlobalError/GlobalError'));
export const SignUpSuccess = reactLazyLoad(() => import('../../pages/SignUpSuccess/SignUpSuccess'));
export const Home = reactLazyLoad(() => import('../../pages/Home/Home'));
export const InviteInvalidCode = reactLazyLoad(() => import('../../pages/InviteInvalidCode/InviteInvalidCode'));
export const Loading = reactLazyLoad(() => import('../../pages/Loading/Loading'));
export const OnboardingStoreRegistration = reactLazyLoad(() => import('../../pages/OnboardingStoreRegistration/OnboardingStoreRegistration'));
export const OnboardingMetaRegistration = reactLazyLoad(() => import('../../pages/OnboardingMetaRegistration/OnboardingMetaRegistration'));
export const OnboardingMetaRegistrationAction = reactLazyLoad(() => import('../../pages/OnboardingMetaRegistration/OnboardingMetaRegistrationAction'));
export const OnboardingUserRegistration = reactLazyLoad(() => import('../../pages/OnboardingUserRegistration/OnboardingUserRegistration'));
export const ReactivateAccount = reactLazyLoad(() => import('../../pages/ReactivateAccount/ReactivateAccount'));
export const Settings = reactLazyLoad(() => import('../../pages/Settings/Settings'));
export const SuggestedCampaignDetail = reactLazyLoad(() => import('../../pages/SuggestedCampaignDetail/SuggestedCampaignDetail'));
export const VerifyEmail = reactLazyLoad(() => import('../../pages/VerifyEmail/VerifyEmail'));
export const ManagedSocialMedia = reactLazyLoad(() => import('../../pages/ManagedSocialMedia/ManagedSocialMedia'));
export const ManagedEmailMarketing = reactLazyLoad(() => import('../../pages/ManagedEmailMarketing/ManagedEmailMarketing'));
export const ChowlyRestaurantControlCenter = reactLazyLoad(() => import('../../pages/ChowlyRestaurantControlCenter/ChowlyRestaurantControlCenter'));
export const InvoiceHistory = reactLazyLoad(() => import('../../pages/InvoiceHistory/InvoiceHistory'));
export const MediaLibrary = reactLazyLoad(() => import('../../pages/MediaLibrary/MediaLibrary'));
export const ManagedLandingPages = reactLazyLoad(() => import('../../pages/ManagedLandingPages/ManagedLandingPages'));
export const Discoverability = reactLazyLoad(() => import('../../pages/Discoverability/Discoverability'));
export const GoogleCampaignDetail = reactLazyLoad(() => import('../../pages/GoogleCampaignDetail/GoogleCampaignDetail'));
export const MEMCampaignDetail = reactLazyLoad(() => import('../../pages/MEMCampaignDetail/MEMCampaignDetail'));
export const InsightDetail = reactLazyLoad(() => import('../../pages/InsightDetail/InsightDetail'));
export const PartnerFeed = reactLazyLoad(() => import('../../pages/PartnerFeed/PartnerFeed'));

// https://auth0.com/docs/quickstart/spa/react/01-login#process-the-authentication-result
const handleAuthentication = (props) => {
  const { location } = props;
  const { hash, search } = location;
  const params = qs.parse(search ? search.substring(1) : '');

  if (/access_token|id_token|error/.test(hash)) {
    auth.handleAuthentication()
    .then(async ({ authenticated, error, result }) => {
      if (authenticated) {
        return store.dispatch(initUser({
          couponCode: params.couponCode,
          email: params.email,
          inviteCode: params.inviteCode,
          inviteUserId: params.inviteUserId,
          originalTarget: params.originalTarget,
          businessId: params?.businessId,
          isRoot: params?.isRoot,
        }));
      }

      const errorDetails = {
        businessId: params.businessId,
        userId: result?.idTokenPayload?.sub || 'unknown',
        type: 'login_auth_error',
        ui: {
          title: 'Login failed due to invalid token',
          description: 'The user\'s login attempt failed due to an invalid token. Please check the user\'s device date and time configuration.',
        },
        meta: {
          currentSystemDate: new Date().toISOString(),
          params,
          hash,
          search,
          error,
          result,
        },
      };

      /**
       * Since we can not add an activityLog without a valid token,
       * just add the Airbrake error then.
       */
      logger.error({
        error: Error(errorDetails.ui.title),
        context: { component: 'Router' },
        params: {
          errorDetails,
        },
      });

      // if not authenticated, assume there was an issue logging in,
      // so ensure session is removed and send back to login
      return auth.logout();
    });
  }
};

const handleLoginRoute = (props, onboard = true) => {
  const { location } = props;
  const { search } = location;
  const params = qs.parse(search ? search.substring(1) : '');
  const {
    email,
    couponCode,
    ltab,
    inviteCode,
    inviteUserId,
  } = params;

  if (auth.isAuthenticated()) {
    return store.dispatch(initUser({
      email,
      couponCode,
      inviteCode,
      inviteUserId,
    }));
  }

  return auth.login({
    onboard,
    email,
    couponCode,
    ltab,
  });
};

const handleInviteRoute = (props) => {
  const { match, location } = props;
  const { search } = location;
  const { params } = match;
  const query = qs.parse(search ? search.substring(1) : '');
  const { c, ltab } = query;

  if (auth.isAuthenticated()) {
    return store.dispatch(initUser({ inviteUserId: params.id, inviteCode: c }));
  }

  return auth.login({
    inviteUserId: params.id,
    inviteCode: c,
    ltab,
  });
};

const handleDeeplinkRoute = (props, isRoot) => {
  const { location } = props;
  const { pathname, search } = location;
  const path = pathname + search;
  const businessId = pathname?.split('/')[2];

  if (auth.isAuthenticated()) {
    return true;
  }

  return auth.login({
    originalTarget: path,
    businessId,
    isRoot,
  });
};

/**
 * @param {boolean} [directNavigation] if true, it will redirect a returning user to the given route
 * after a successful authentication.
 */
function getSecureRoute(path, component, directNavigation) {
  return (
    <SecureRoute
      exact
      path={path}
      component={component}
      directNavigation={directNavigation}
    />
  );
}

function getRoute(path, component) {
  return (
    <Route
      exact
      path={path}
      component={component}
    />
  );
}

export default () => (
  <Switch>
    {getSecureRoute(routes.getAddUserRoute(), AddUser)}
    {getSecureRoute(routes.getBusinessesRoute(), Businesses)}
    {getSecureRoute(routes.getCampaignEditRoute(), CampaignReview)}
    {getSecureRoute(routes.getCampaignCreateRoute(), CampaignReview)}
    {getSecureRoute(routes.getLoadingRoute(), Loading)}
    {getSecureRoute(routes.getOnboardingStoreRegistrationRoute(), OnboardingStoreRegistration)}
    {getSecureRoute(
      routes.getOnboardingMetaRegistrationActionRoute(),
      OnboardingMetaRegistrationAction,
    )}
    {getSecureRoute(routes.getOnboardingMetaRegistrationRoute(), OnboardingMetaRegistration)}
    {getSecureRoute(routes.getOnboardingRegistrationRoute(), OnboardingUserRegistration)}
    {getSecureRoute(routes.getReactivateAccountRoute(), ReactivateAccount)}
    {getSecureRoute(routes.getVerifyEmailRoute(), VerifyEmail)}
    {getSecureRoute(routes.getDiscoverabilityRoute(), Discoverability, true)}
    {getSecureRoute(routes.getSignUpSuccessRoute(), SignUpSuccess)}

    <Route
      path={routes.getCallbackRoute()}
      render={(props) => {
        handleAuthentication(props);
        return <div />;
      }}
    />
    <Route
      exact
      path={routes.getLoginRoute()}
      render={(props) => {
        handleLoginRoute(props, false);
        return <div />;
      }}
    />
    <Route
      exact
      path={routes.getOnboardingLoginRoute()}
      render={(props) => {
        handleLoginRoute(props);
        return <div />;
      }}
    />
    <Route
      exact
      path={routes.getInviteRoute()}
      render={(props) => {
        handleInviteRoute(props);
        return <div />;
      }}
    />
    {getRoute(routes.getRejectedRoute(), GlobalError)}
    {getRoute(routes.getGlobalErrorRoute(), GlobalError)}
    {getRoute(routes.getInviteCodeInvalidRoute(), InviteInvalidCode)}
    {/* Deeplinking */}
    <Route
      exact
      path={routes.getManagedSocialMediaRoute()}
      render={(props) => {
        if (handleDeeplinkRoute(props)) {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <ManagedSocialMedia {...props} />;
        }
        return <div />;
      }}
    />
    <Route
      exact
      path={routes.getManagedLandingPagesRoute()}
      render={(props) => {
        if (handleDeeplinkRoute(props)) {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <ManagedLandingPages {...props} />;
        }
        return <div />;
      }}
    />
    <Route
      exact
      path={routes.getManagedEmailMarketingRoute()}
      render={(props) => {
        if (handleDeeplinkRoute(props)) {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <ManagedEmailMarketing {...props} />;
        }
        return <div />;
      }}
    />
    <Route
      exact
      path={routes.getChowlyRestaurantControlCenter()}
      render={(props) => {
        if (handleDeeplinkRoute(props)) {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <ChowlyRestaurantControlCenter {...props} />;
        }
        return <div />;
      }}
    />
    <Route
      exact
      path={routes.getCampaignsRoute()}
      render={(props) => {
        if (handleDeeplinkRoute(props)) {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <Campaigns {...props} />;
        }
        return <div />;
      }}
    />
    <Route
      exact
      path={routes.getCampaignWizardDetailRoute()}
      render={(props) => {
        if (handleDeeplinkRoute(props)) {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <CampaignWizardDetail {...props} />;
        }
        return <div />;
      }}
    />
    <Route
      exact
      path={routes.getSuggestedCampaignDetailRoute()}
      render={(props) => {
        if (handleDeeplinkRoute(props)) {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <SuggestedCampaignDetail {...props} />;
        }
        return <div />;
      }}
    />
    <Route
      exact
      path={routes.getGoogleCampaignDetailRoute()}
      render={(props) => {
        if (handleDeeplinkRoute(props)) {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <GoogleCampaignDetail {...props} />;
        }
        return <div />;
      }}
    />
    <Route
      exact
      path={routes.getMEMCampaignDetailRoute()}
      render={(props) => {
        if (handleDeeplinkRoute(props)) {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <MEMCampaignDetail {...props} />;
        }
        return <div />;
      }}
    />
    <Route
      exact
      path={routes.getMediaLibraryRoute()}
      render={(props) => {
        if (handleDeeplinkRoute(props)) {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <MediaLibrary {...props} />;
        }
        return <div />;
      }}
    />
    <Route
      exact
      path={routes.getBusinessProfileRoute()}
      render={(props) => {
        if (handleDeeplinkRoute(props)) {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <BusinessProfile {...props} />;
        }
        return <div />;
      }}
    />
    <Route
      exact
      path={routes.getSettingsRoute()}
      render={(props) => {
        if (handleDeeplinkRoute(props)) {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <Settings {...props} />;
        }
        return <div />;
      }}
    />
    <Route
      exact
      path={routes.getInvoiceHistoryRoute()}
      render={(props) => {
        if (handleDeeplinkRoute(props)) {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <InvoiceHistory {...props} />;
        }
        return <div />;
      }}
    />
    <Route
      exact
      path={routes.getInsightRoute()}
      render={(props) => {
        if (handleDeeplinkRoute(props)) {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <InsightDetail {...props} />;
        }
        return <div />;
      }}
    />
    <Route
      exact
      path={routes.getPartnerFeedRoute()}
      render={(props) => {
        if (handleDeeplinkRoute(props)) {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <PartnerFeed {...props} />;
        }
        return <div />;
      }}
    />
    <Route
      exact
      path={routes.getHomeRoute()}
      render={(props) => {
        if (handleDeeplinkRoute(props)) {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <Home {...props} />;
        }
        return <div />;
      }}
    />
    <Route
      exact
      path={routes.getRootRoute()}
      render={(props) => {
        handleDeeplinkRoute(props, true);
        return <div />;
      }}
    />
  </Switch>
);
