import { call, put } from 'redux-saga/effects';
import _ from 'lodash';

import { showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import createUser from '../createUser/createUser';
import updateUser from '../updateUser/updateUser';

export default function* upsertUser({ id, ...otherParams }) {
  try {
    let user;
    if (_.isNil(id)) {
      user = yield call(createUser, otherParams);
    } else {
      user = yield call(updateUser, { id, ...otherParams });
    }

    // create and update already handle themselves
    // having an error, so just return
    if (_.isNil(user)) {
      return undefined;
    }

    // this intentionally does not create a HS record
    // as we don't want to create more than the original one

    return user;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'upsertUser' },
      params: { userId: id, otherParams },
    });
    yield put(showToaster(getToasterOptions('generic_request_error', 'error')));
    return undefined;
  }
}
