import { call, put, select } from 'redux-saga/effects';

import { USERS_TAB } from '../../constants';
import api from '../../services/api';
import i18n from '../../services/i18n';
import history from '../../services/history';
import { getSettingsRoute } from '../../routes';
import { showToaster } from '../../actions';
import { selectBusiness, selectUser } from '../../selectors';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* inviteUser({ payload }) {
  const { firstName, lastName, email } = payload;
  const { id, businessId } = yield select(selectUser);
  const { name } = yield select(selectBusiness);

  const dataProperties = {
    businessId,
    businessName: name,
    givenName: firstName,
    familyName: lastName,
    email,
    invitingUserId: id,
  };

  try {
    yield call(api.inviteUser, businessId, dataProperties);
    // const userId = _.get(user, 'id');
    // if (userId) {
    //   yield call(api.createUserLogs, id, {
    //     timestamp: new Date().valueOf(),
    //     userId: id,
    //     viewedUrl: window.location.href,
    //     invitedUser: _.get(user, 'id'),
    //   });
    // }

    yield put(showToaster(getToasterOptions(i18n.t('user_invited_successfully', dataProperties), 'info', null, 5000)));
    history.push(getSettingsRoute(businessId, USERS_TAB));
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'inviteUser' },
      params: {
        userId: id, businessId, firstName, lastName, email,
      },
    });
    yield put(showToaster(getToasterOptions(i18n.t('duplicate_email_error', dataProperties))));
  }
}
