import _ from 'lodash';
import { call, put, select } from 'redux-saga/effects';

import auth from '../../services/auth';
import api from '../../services/api';
import { showToaster } from '../../actions';
import {
  selectBusiness,
  selectStores,
  selectUser,
} from '../../selectors';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* deleteBusinessSaga() {
  const business = yield select(selectBusiness);
  const stores = yield select(selectStores);
  const user = yield select(selectUser);

  if (_.isNil(business) === false && _.isEmpty(business) === false) {
    try {
      yield call(api.deleteCampaigns, business.id);
    } catch (e) {
      logger.error({
        error: e,
        context: { saga: 'deleteBusinessSaga - api.deleteCampaigns' },
        params: { user, businessId: business.id },
      });
      yield put(showToaster(getToasterOptions('error_delete_campaigns')));
      return;
    }
  }

  try {
    if (_.isNil(stores) === false && _.isEmpty(stores) === false) {
      for (let i = 0; i < stores.length; i += 1) {
        const store = stores[i];
        yield call(api.deleteStore, store.id);
      }
    }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'deleteBusinessSaga - api.deleteStore' },
      params: { user, stores },
    });
    yield put(showToaster(getToasterOptions('error_delete_store')));
    return;
  }

  if (_.isNil(business) === false && _.isEmpty(business) === false) {
    try {
      yield call(api.deleteBusiness, business.id);
    } catch (e) {
      logger.error({
        error: e,
        context: { saga: 'deleteBusinessSaga - api.deleteBusiness' },
        params: { user, businessId: business.id },
      });
      yield put(showToaster(getToasterOptions('error_delete_business')));
      return;
    }
  }

  try {
    yield call(api.deleteUser, user.id);
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'deleteBusinessSaga - api.deleteUser' },
      params: { user },
    });
    yield put(showToaster(getToasterOptions('error_delete_user')));
    return;
  }

  auth.logout();
}
