import {
  call,
  put,
  select,
  all,
} from 'redux-saga/effects';
import _ from 'lodash';

import { showToaster, showEditBusinessNameModal } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions, takeAsyncAction } from '../helpers';
import { selectUser } from '../../selectors';
import updateBusiness from '../updateBusiness/updateBusiness';
import updateUser from '../updateUser/updateUser';
import { CONFIRM_EDIT_BUSINESS_NAME } from '../../constants';
import api from '../../services/api';

export default function* handleEditBusinessName() {
  const activeUser = yield select(selectUser);
  const businessId = _.get(activeUser, 'businessId', '');
  const { users } = yield call(api.getUsers, businessId);

  yield put(showEditBusinessNameModal(true));
  const { payload: newName } = yield call(takeAsyncAction, CONFIRM_EDIT_BUSINESS_NAME);

  if (newName) {
    try {
      const calls = [
        call(updateBusiness, { payload: { id: businessId, name: newName } }),
        ...users?.filter((user) => user.businessId === businessId).map((user) => (
          call(updateUser, { id: user.id, businessName: newName }, true)
        )),
      ];
      yield all(calls);
      yield put(showToaster(getToasterOptions('edit_business_name_update_success', 'success', null, 5000)));
      yield put(showEditBusinessNameModal(false));
    } catch (e) {
      logger.error({
        error: e,
        context: { saga: 'handleEditBusinessName' },
        params: { activeUser, businessId, newName },
      });
      yield put(showToaster(getToasterOptions('generic_request_error', 'error')));
    }
  } else {
    yield put(showEditBusinessNameModal(false));
  }
}
