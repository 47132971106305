import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Label from '@material-ui/icons/Label';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useTranslation } from 'react-i18next';

import AsyncButton from '@targetable/targetable-web-framework/lib/react/components/AsyncButton/AsyncButton';

import { selectShowFacebookAntiScumModal } from '../../selectors';
import { continueFacebookAntiScum } from '../../actions';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  label: {
    fontSize: 16,
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  bullet: {
    padding: 0,
    alignItems: 'flex-start',
  },
  bulletText: {
    padding: 0,
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));

const FacebookAntiScumModal = () => {
  const show = useSelector(selectShowFacebookAntiScumModal);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleContinue = useCallback(() => {
    dispatch(continueFacebookAntiScum(true));
  }, [dispatch]);

  const handleCancel = useCallback(() => {
    dispatch(continueFacebookAntiScum(false));
  }, [dispatch]);

  return (
    <Dialog
      onClose={handleCancel}
      maxWidth="sm"
      open={show}
      data-cy="component_FacebookAntiScumModal"
    >
      <DialogContent>
        <Typography variant="subtitle1" className={classes.title}>
          {t('confirm_facebook_access_title')}
        </Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          {t('confirm_facebook_access_how_we_work')}
        </Typography>
        <List disablePadding>
          <ListItem className={classes.bullet}>
            <ListItemIcon>
              <Label className={classes.label} />
            </ListItemIcon>
            <ListItemText
              className={classes.bulletText}
              primary={t('confirm_facebook_access_bullet_one')}
            />
          </ListItem>
          <ListItem className={classes.bullet}>
            <ListItemIcon>
              <Label className={classes.label} />
            </ListItemIcon>
            <ListItemText
              className={classes.bulletText}
              primary={t('confirm_facebook_access_bullet_two')}
            />
          </ListItem>
          <ListItem className={classes.bullet}>
            <ListItemIcon>
              <Label className={classes.label} />
            </ListItemIcon>
            <ListItemText
              className={classes.bulletText}
              primary={t('confirm_facebook_access_bullet_three')}
            />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <AsyncButton
          className={classes.button}
          variant="text"
          color="secondary"
          onClick={handleCancel}
          data-cy="cta_FacebookAntiScumModal_cancel"
        >
          {t('cancel')}
        </AsyncButton>
        <AsyncButton
          className={classes.button}
          color="secondary"
          variant="text"
          onClick={handleContinue}
          data-cy="cta_FacebookAntiScumModal_continue"
        >
          {t('continue')}
        </AsyncButton>
      </DialogActions>
    </Dialog>
  );
};

export default FacebookAntiScumModal;
