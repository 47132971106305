import { CLOSE_FEEDBACK_DIALOG, SHOW_FEEDBACK_DIALOG } from '../constants';

const defaultState = {
  action: null,
  item: null,
  open: false,
  type: null,
};

export default {
  defaultState,
  reducers: {
    [CLOSE_FEEDBACK_DIALOG]: () => defaultState,
    [SHOW_FEEDBACK_DIALOG]: (state, { payload }) => ({ ...payload, open: true }),
  },
};
