import { call, put, select } from 'redux-saga/effects';

import api from '../../services/api';

import { setComments, setLoadingComments } from '../../actions';
import { selectComments } from '../../selectors';
import logger from '../../services/logger';

export default function* postComment({ payload }) {
  const {
    businessId,
    contentId,
    contentTitle,
    contentType,
    message,
  } = payload;
  try {
    yield put(setLoadingComments(true));
    const comment = yield call(
      api.createComment, businessId, contentId, message, contentType, contentTitle,
    );

    const comments = yield select(selectComments);

    yield put(setComments([...comments, comment]));
    yield put(setLoadingComments(false));
    return comments;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'postComment' },
      params: {
        businessId,
        contentId,
        contentTitle,
        contentType,
        message,
      },
    });
    return undefined;
  }
}
