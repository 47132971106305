import {
  call, put, select, delay,
} from 'redux-saga/effects';
import _ from 'lodash';
import { setStores, showBackdrop } from '../../actions';
import { selectStores } from '../../selectors';
import api from '../../services/api';

export default function* removeStoreIntegration(storeId, integrationType) {
  yield put(showBackdrop(true));
  const store = yield call(api.getStore, storeId);

  yield delay(3000);

  const updatedStore = {
    ...store,
    integrations: _.filter(store.integrations || [], (i) => i.type !== integrationType),
  };

  yield call(api.updateStore, updatedStore.id, updatedStore);

  const stores = yield select(selectStores);

  for (let i = 0; i < stores.length; i += 1) {
    if (stores[i].id === storeId) {
      stores[i].integrations = updatedStore.integrations;
    }
  }

  yield put(setStores(stores));

  return updatedStore;
}
