import { DATA_ACTIVITY_LOG_DIALOG } from '../constants';

const defaultState = {
  cancelText: undefined,
  cancelDataCy: undefined,
  confirmText: undefined,
  confirmDataCy: undefined,
  isOpen: undefined,
  text: undefined,
  textParams: undefined,
};

export default {
  defaultState,
  reducers: {
    [DATA_ACTIVITY_LOG_DIALOG]: (state, { payload }) => ({ ...state, ...payload }),
  },
};
