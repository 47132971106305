import {
  call,
  put,
  select,
  fork,
} from 'redux-saga/effects';

import _ from 'lodash';
import { getPaidAdProductInfo } from '@targetable/targetable-web-framework/lib/services/paywallUtils';
import api from '../../services/api';
import { selectBusiness, selectSubscription } from '../../selectors';
import { showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import getStripeSubscription from '../getStripeSubscription/getStripeSubscription';
import postSubscriptionActivation from '../postSubscriptionActivation/postSubscriptionActivation';

export default function* reactivateStripeSubscription(showToast) {
  const business = yield select(selectBusiness);
  const subscription = yield select(selectSubscription);
  const paidAdProductInfo = getPaidAdProductInfo(subscription);

  try {
    if (showToast) {
      yield put(showToaster(getToasterOptions('Reactivating your subscription...', 'info', null, 5000)));
    }
    yield call(
      api.updateStripeSubscription,
      _.get(business, 'stripeCustomerId'),
      paidAdProductInfo?.subscriptionId,
      {
        cancel_at_period_end: false,
        businessId: _.get(business, 'id'),
      },
    );
    yield call(getStripeSubscription);
    yield fork(postSubscriptionActivation);
  } catch (ex) {
    logger.error({
      error: ex,
      context: { saga: 'reactivateStripeSubscription' },
      params: { business, subscription },
    });
    yield put(showToaster(getToasterOptions('error_reactivate_subscription')));
  }
}
