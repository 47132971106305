import randomColor from 'randomcolor';

// randomColor generates a new batch of colors on every
// invocation, even if a seed is passed, so pre-cache
// the colors so we use the same ones every time.
// However, it generates a rainbow pattern by default,
// so this is being more explicit in the color
const HUES = [
  'red',
  'blue',
  'yellow',
  'green',
  'purple',
  'orange',
  'pink',
  'blue',
  'red',
  'yellow',
  'green',
  'purple',
  'orange',
  'pink',
  'red',
  'green',
  'purple',
  'yellow',
  'orange',
  'blue',
  'pink',
  'yellow',
  'red',
  'green',
  'purple',
  'orange',
  'blue',
  'pink',
  'red',
  'green',
  'purple',
  'orange',
  'blue',
  'pink',
  'yellow',
  'red',
  'yellow',
  'purple',
  'orange',
  'blue',
  'green',
  'pink',
  'yellow',
  'green',
  'purple',
  'orange',
  'blue',
  'pink',
  'yellow',
  'red',
];

const colors = HUES.map((hue, index) => randomColor({
  luminosity: 'dark',
  hue,
  seed: index,
}));

export default {
  getAvatarColors: (count) => {
    let returnValue = [];
    let remainingCount = count;
    while (remainingCount > colors.length) {
      returnValue = returnValue.concat(colors);
      remainingCount -= colors.length;
    }
    return returnValue.concat(colors.slice(0, remainingCount));
  },
};
