import { call, put } from 'redux-saga/effects';
import _ from 'lodash';

import api from '../../services/api';
import {
  setUserActive,
  showToaster,
} from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* createUser(user) {
  try {
    let updatedUser = yield call(api.createUser, user);
    updatedUser = _.merge({}, user, updatedUser);
    yield put(setUserActive(updatedUser));
    return updatedUser;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'createUser' },
      params: { user },
    });
    yield put(showToaster(getToasterOptions('generic_request_error', 'error')));
    return undefined;
  }
}
