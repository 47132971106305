import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import StoreCreatorModal from '@targetable/targetable-web-framework/lib/react/components/StoreCreatorModal/StoreCreatorModal';

import { getPaidAdProductInfo } from '@targetable/targetable-web-framework/lib/services/paywallUtils';
import {
 selectBusiness, selectShowAddStoreModal, selectStores, selectSubscription,
} from '../../selectors';
import { addStoreConfirmPayment } from '../../actions';
import AddStorePaymentModal from '../../pages/AddStorePaymentModal/AddStorePaymentModal';

const AddStoreModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const business = useSelector(selectBusiness);
  const { show } = useSelector(selectShowAddStoreModal);
  const stores = useSelector(selectStores) || [];
  const [storeData, setStoreData] = useState(null);
  const [showPayment, setShowPayment] = useState();
  const subscription = useSelector(selectSubscription);
  const paidAdProductInfo = getPaidAdProductInfo(subscription);
  const disableAutomaticBillingIncrement = Boolean(business?.disableAutomaticBillingIncrement);
  const status = _.get(paidAdProductInfo, 'status');
  const shouldDisplayPaymentModal = !(_.isNil(status) || status === 'canceled');

  const storeNames = stores.map((store) => store.name);

  const handlePlaceSelect = useCallback(({ name }) => {
    setStoreData({ name });
    if (!disableAutomaticBillingIncrement) {
      setShowPayment(true);
    }

    // if (!shouldDisplayPaymentModal) dispatch(addStoreConfirmPayment({ name }));
    if (!shouldDisplayPaymentModal || disableAutomaticBillingIncrement) {
      dispatch(addStoreConfirmPayment({ name }));
    }
  }, [setStoreData, shouldDisplayPaymentModal, dispatch, disableAutomaticBillingIncrement]);

  const handlePaymentConfirm = useCallback(() => {
    dispatch(addStoreConfirmPayment(storeData));
  }, [dispatch, storeData]);

  const handleCancel = useCallback(() => {
    dispatch(addStoreConfirmPayment(false));
  }, [dispatch]);

  useEffect(() => {
    if (!show) {
      setShowPayment(false);
    }
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <div data-cy="AddStoreModal_component">
      <StoreCreatorModal
        existingStoreNames={storeNames}
        open
        onSelect={handlePlaceSelect}
        onCancel={handleCancel}
        title={t('add_store')}
        text={t('place_selector_title_add_store')}
        subtext={t('place_selector_subtitle_add_store')}
        confirmLabel={t('continue')}
      />
      {showPayment && shouldDisplayPaymentModal && (
        <AddStorePaymentModal
          onConfirm={handlePaymentConfirm}
          onCancel={handleCancel}
          title={t('add_store')}
        />
      )}
    </div>
  );
};

export default AddStoreModal;
