import {
  spawn,
  put,
  select,
} from 'redux-saga/effects';

import { ASSET_TYPE_PROMOTION, ASSET_TYPE_SITELINK } from '@targetable/targetable-web-framework/lib/react/constants';
import { selectGoogleAssets } from '../../selectors';
import {
  setGoogleSitelinks,
  setGooglePromotions,
} from '../../actions';
import fetchGoogleAssets from './fetchGoogleAssets';

/**
 * Returns a Google Ad Asset by assetType
 * @param {object} payload
 * @param {string} payload.marketingChannelId The Marketing Channel Id currently selected
 * @param {string} payload.assetType Asset Type
 * @returns The Google Ad Assets
 */
export default function* getGoogleAssets({ payload }) {
  const { assetType, marketingChannelId } = payload;
  let assetSetter;
  const assetTypeStateName = `${assetType.toLowerCase()}s`;

  switch (assetType) {
    case ASSET_TYPE_SITELINK:
      assetSetter = setGoogleSitelinks;
      break;
    case ASSET_TYPE_PROMOTION:
      assetSetter = setGooglePromotions;
      break;

    default:
      return;
  }

  const googleAssets = yield select(selectGoogleAssets);

  // Check if the asset by MarketingChannelId already exists in Redux
  const foundMarketingAsset = googleAssets?.[assetTypeStateName]?.[marketingChannelId];

  if (!foundMarketingAsset) {
    yield put(assetSetter({
      marketingChannelId,
      [assetTypeStateName]: {},
      loading: true,
    }));

    // Try to fetch the assets for the passed marketingChannelId
    // And put the results in the State
    // spawn is used for managing concurrent tasks in a way that ensures each task is independent.
    // Otherwise using 'call', the latest call's state might be overriding the previous calls
    // because they are not managed concurrently.
    yield spawn(fetchGoogleAssets, marketingChannelId, assetType, assetSetter);
  }
}
