import _ from 'lodash';

import { DATA_USER_ACTIVE, DATA_USER_ALL_USERS, DATA_SET_USER_INFO } from '../constants';

export default {
  defaultState: {
    active: null,
    all: [],
  },
  reducers: {
    [DATA_USER_ACTIVE]: (state, { payload: active }) => ({ ...state, active }),
    [DATA_USER_ALL_USERS]: (state, { payload: all }) => ({ ...state, all }),
    [DATA_SET_USER_INFO]: (state, { payload: userInfo }) => ({
      active: state.active && userInfo.id === state.active.id
        ? { ...state.active, ...userInfo }
        : state.active,
      all: state.all.map((user) => {
        if (user.id === userInfo.id) {
          if (state.active && user.id === state.active.id) {
            return { ...user, ...userInfo };
          }
          return {
            ...user,
            ..._.pick(userInfo, ['businessId', 'businessName', 'backgroundColor']),
          };
        }
        return user;
      }),
    }),
  },
  excludeFromReset: true,
};
