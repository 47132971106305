import { DATA_FACEBOOK_PAGES_LOADING, DATA_FACEBOOK_PAGES_ITEMS } from '../constants';

export default {
  defaultState: {
    loading: false,
    items: [],
  },
  reducers: {
    [DATA_FACEBOOK_PAGES_LOADING]: (state, { payload }) => ({ ...state, loading: payload }),
    [DATA_FACEBOOK_PAGES_ITEMS]: (state, { payload }) => ({ ...state, items: payload }),
  },
};
