import _ from 'lodash';
import { put, select } from 'redux-saga/effects';
import logger from '../../services/logger';
import { setBellNotifications } from '../../actions';
import { selectBellNotifications, selectBusiness } from '../../selectors';

// TODO parse data as in getAllActivityLogsByBusiness
export default function* handleIncomingDataStream(eventData) {
  const business = yield select(selectBusiness);
  let maybeParsedTwice = eventData;
  if (typeof eventData === 'string') {
    // should parse again, there is a bug somewhere that is stringifying twice
    maybeParsedTwice = JSON.parse(eventData);
  }
  const bellNotifications = yield select(selectBellNotifications);

  try {
    // if a user is under more than one business will receive events from every business
    if (business?.id === maybeParsedTwice?.businessId) {
      // upserts bellNotification state array
      const index = _.findIndex(bellNotifications, { id: maybeParsedTwice.id });
      let notifications = [];
      if (index === -1) {
        notifications = [
          ...bellNotifications,
          maybeParsedTwice,
        ];
      } else {
        notifications = bellNotifications.map((item) => {
          if (item?.id === maybeParsedTwice?.id) return maybeParsedTwice;
          return item;
        });
      }
      yield put(setBellNotifications(notifications));
    }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'handleIncomingDataStream' },
      params: {
        maybeParsedTwice,
      },
    });
  }
}
