import _ from 'lodash';
import moment from 'moment';
import {
 call, fork, all, put, select,
} from 'redux-saga/effects';

import history from '../../services/history';
import logger from '../../services/logger';
import { setUserActive, showCampaignWizard, openSuggestedCampaignDetail } from '../../actions';
import { selectUserAllUsers } from '../../selectors';
import getBusiness from '../getBusiness/getBusiness';
import getStoresByBusiness from '../getStoresByBusiness/getStoresByBusiness';
import {
  getHomeRoute,
  getRejectedRoute,
} from '../../routes';
import handleEntrySubscription from '../handleEntrySubscription/handleEntrySubscription';
import validateFacebookConnection from '../validateFacebookConnection/validateFacebookConnection';
import updateUser from '../updateUser/updateUser';

import api from '../../services/api';

const extractCampaignIdAndType = (url) => {
  const regex = /\/businesses\/([^/]+)\/campaigns\/(google|mem|wizard|suggested)\/([^/]+)\/(details|detail)/;
  const matches = url.match(regex);
  if (matches) {
    const id = matches[3];
    const type = matches[2];
    return { id, type };
  }
  return { id: null, type: null };
};

export default function* handleReturningUser(user, originalTargetRoute, biz, isRoot) {
  const userId = _.get(user, 'id');
  const businessId = _.isNil(biz)
      ? _.get(user, 'businessId')
      : biz;

  try {
    yield call(updateUser, { id: userId, lastLogin: moment.utc().valueOf() });
    // validate facebook connection before fetching
    // stores, facebook permissions, and facebook pages
    yield fork(validateFacebookConnection);

    const [business] = yield all([
      call(getBusiness, businessId),
      call(getStoresByBusiness, businessId),
    ]);

    if (_.get(business, 'stripeCustomerId')) {
      yield call(handleEntrySubscription, business, user);
    }

    if (!_.isNil(biz)) {
      const users = yield select(selectUserAllUsers);
      const userChanged = _.find(users, ['businessId', businessId]);
      yield put(setUserActive(userChanged));
    }

    if (originalTargetRoute) {
      if (isRoot) {
        history.push(getHomeRoute(businessId));
      } else if (!_.isNil(business)) {
        const { id: campaignId, type } = extractCampaignIdAndType(originalTargetRoute);
        if (!campaignId) {
          history.push(originalTargetRoute);
        } else {
          const requests = {
            google: () => call(api.getAdById, campaignId),
            mem: () => call(api.getAdById, campaignId),
            wizard: () => call(api.getCampaign, campaignId),
            suggested: () => call(api.getSuggestedCampaign, campaignId),
          };
          const campaign = yield requests[type]();
          if (type !== 'suggested') {
            yield put(showCampaignWizard(campaign));
          } else {
            yield put(openSuggestedCampaignDetail({
              suggestedCampaign: campaign,
              locationHasChanged: false,
            }));
          }
        }
      }
    } else {
      history.push(getHomeRoute(businessId));
    }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'handleReturningUser' },
      params: { userId, businessId },
    });
    history.push(getRejectedRoute());
  }
}
