import { call } from 'redux-saga/effects';
import api from '../../services/api';
import logger from '../../services/logger';
import globals from '../../globals';

export default function* authorizeGoogle({ payload }) {
  const {
    businessId,
    onDone,
  } = payload;
  const { GOOGLE_MCC_ACCOUNT_IDS } = globals;
  const mccAccountIds = GOOGLE_MCC_ACCOUNT_IDS;

  try {
    const response = yield call(
      api.authorizeGoogle,
      // TODO: ability to choose the desired MCC account
      { businessId, mccAccount: mccAccountIds[0] },
    );
    if (response && response?.grantUrl) {
      if (onDone) {
        onDone(response?.grantUrl);
      }
    }
  } catch (ex) {
    logger.error({
      error: ex,
      context: { saga: 'authorizeGoogle' },
      params: { payload },
    });
  }
}
