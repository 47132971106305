import {
  DATA_BUSINESS,
  DATA_BUSINESS_INSIGHTS,
  DATA_BUSINESS_INSIGHTS_LOADING,
  DATA_BUSINESS_USERS,
  DATA_BUSINESS_USERS_LOADING,
  DATA_BUSINESS_SUGGESTED_CAMPAIGNS,
  DATA_BUSINESS_SUGGESTED_CAMPAIGNS_LOADING,
  DATA_BUSINESS_LEAD_FORMS,
} from '../constants';

export default {
  defaultState: {
    business: null,
    insights: [],
    suggestedCampaigns: [],
    insightsLoading: false,
    users: [],
    usersLoading: false,
    suggestedCampaignsLoading: false,
  },
  reducers: {
    [DATA_BUSINESS]: (state, { payload: business }) => ({ ...state, business }),
    [DATA_BUSINESS_INSIGHTS]: (state, { payload: insights }) => ({ ...state, insights }),
    [DATA_BUSINESS_INSIGHTS_LOADING]: (state, { payload: insightsLoading }) => ({
      ...state,
      insightsLoading,
    }),
    [DATA_BUSINESS_USERS]: (state, { payload: users }) => ({ ...state, users }),
    [DATA_BUSINESS_USERS_LOADING]: (state, { payload: usersLoading }) => ({
      ...state,
      usersLoading,
    }),
    [DATA_BUSINESS_SUGGESTED_CAMPAIGNS]: (state, { payload }) => ({
      ...state,
      suggestedCampaigns: payload,
    }),
    [DATA_BUSINESS_SUGGESTED_CAMPAIGNS_LOADING]: (
      state, { payload: suggestedCampaignsLoading },
    ) => ({
      ...state,
      suggestedCampaignsLoading,
    }),
    [DATA_BUSINESS_LEAD_FORMS]: (state, { payload: leadForms }) => ({
      ...state,
      leadForms,
    }),
  },
};
