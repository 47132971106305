import {
  call,
  select,
  all,
} from 'redux-saga/effects';

import _ from 'lodash';
import api from '../../services/api';
import { selectSubscription, selectStores } from '../../selectors';
import logger from '../../services/logger';

export default function* postSubscriptionActivation() {
  const stores = yield select(selectStores);
  const subscription = yield select(selectSubscription);
  const subscriptionStatus = _.get(subscription, 'status');

  if (subscriptionStatus === 'active') {
    try {
      yield all(
        stores.map((store) => call(
          api.updateStoreHubspotRecord,
          store.id,
          { storeTargetableSubscription: true },
        )),
      );
    } catch (ex) {
      logger.error({
        error: ex,
        context: { saga: 'postSubscriptionActivation' },
        params: { stores, subscriptionStatus },
      });
    }
  }
}
