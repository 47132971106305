import { MARKETING_CHANNELS_LOADING, DATA_MARKETING_CHANNELS } from '../constants';

export default {
  defaultState: {
    marketingChannels: [],
    loading: false,
  },
  reducers: {
    [MARKETING_CHANNELS_LOADING]: (state, { payload }) => ({ ...state, loading: payload }),
    [DATA_MARKETING_CHANNELS]: (state, { payload }) => ({ ...state, marketingChannels: payload }),
  },
};
