import {
  DATA_CAMPAIGN_METRICS,
  DATA_CAMPAIGN_TRENDS,
  DATA_CAMPAIGN_METRICS_LOADING,
  DATA_CAMPAIGN_METRICS_LOADING_ERROR,
} from '../constants';

export default {
  defaultState: {
    campaign: null,
    trends: null,
    campaignLoading: false,
    campaignLoadingError: false,
  },
  reducers: {
    [DATA_CAMPAIGN_METRICS]: (state, { payload }) => ({ ...state, campaign: payload }),
    [DATA_CAMPAIGN_TRENDS]: (state, { payload }) => ({ ...state, trends: payload }),
    [DATA_CAMPAIGN_METRICS_LOADING]: (state, { payload }) => ({
      ...state, campaignLoading: payload,
    }),
    [DATA_CAMPAIGN_METRICS_LOADING_ERROR]: (state, { payload }) => ({
      ...state, campaignLoadingError: payload,
    }),
  },
};
