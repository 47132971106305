const { AIRBRAKE_ID } = process.env;
const { AIRBRAKE_KEY } = process.env;
const { ALGOLIA_APP_ID } = process.env;
const { ALGOLIA_API_SEARCH_KEY } = process.env;
const { API_BASE_URL } = process.env;
const { AUTH0_CLIENT_ID } = process.env;
const { AUTH0_DOMAIN } = process.env;
const { ADMIN_BASE_URL } = process.env;
const { CIRCLE_BUILD_NUM } = process.env;
const { CLOUDINARY_KEY } = process.env;
const { GOOGLE_MCC_ACCOUNT_IDS } = process.env;

// const { DATADOG_KEY } = process.env;
// const { DATADOG_REGION } = process.env;
const { ENVIRONMENT_NAME } = process.env;
const { STRIPE_PUBLIC_KEY } = process.env;
const { STATIC_BASE_URL } = process.env;

// Stripe Products
const { STRIPE_PRODUCT_PAID_AD } = process.env;
const { STRIPE_PRODUCT_FB_IG_TW } = process.env;
const { STRIPE_PRODUCT_LINKEDIN } = process.env;
const { STRIPE_PRODUCT_PINTEREST } = process.env;
const { STRIPE_PRODUCT_YOUTUBE } = process.env;
const { STRIPE_PRODUCT_GOOGLE_BS } = process.env;
const { STRIPE_PRODUCT_TGT_CM } = process.env;
const { STRIPE_PRODUCT_AA_PLUS } = process.env;
const { STRIPE_PRODUCT_MANAGED_LP_BASE } = process.env;
const { STRIPE_PRODUCT_ADDITIONAL_LP } = process.env;
const { STRIPE_PRODUCT_DISCOVERABILITY } = process.env;
const { STRIPE_PRODUCT_GOOGLE_AD_SEARCH } = process.env;
const { STRIPE_PRODUCT_MANAGED_EMAIL_MARKETING } = process.env;
const { STRIPE_PRODUCT_MANAGED_EMAIL_MARKETING_PLUS } = process.env;
const { STRIPE_PRODUCT_MARKETING_ADVISORY_SERVICES } = process.env;
const { STRIPE_PRODUCT_PRESENCE } = process.env;
const { STRIPE_PRODUCT_PRESENCE_PLUS } = process.env;
const { STRIPE_PRICE_DIGITAL_DISCOVERY } = process.env;
const { STRIPE_PRODUCT_AA_GOOGLE_SEARCH_ADS } = process.env;
const { STRIPE_PRODUCT_AA_PLUS_GOOGLE_SEARCH_ADS } = process.env;
const { STRIPE_PRODUCT_DISCOVERABILITY_PLUS } = process.env;

const { DEFAULT_CANCELLATION_QUESTIONNAIRE_ID } = process.env;

export default {
  AIRBRAKE_ID,
  AIRBRAKE_KEY,
  ALGOLIA_APP_ID,
  ALGOLIA_API_SEARCH_KEY,
  API_BASE_URL,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  ADMIN_BASE_URL,
  CLOUDINARY_KEY,
  GOOGLE_MCC_ACCOUNT_IDS: (GOOGLE_MCC_ACCOUNT_IDS || '').split(',').map((id) => String(id).trim()).filter(Boolean),
  // DATADOG_KEY,
  // DATADOG_REGION,
  ENVIRONMENT_NAME,
  BUILD_VERSION: CIRCLE_BUILD_NUM || 'local',
  STRIPE_PUBLIC_KEY,
  STATIC_BASE_URL,
  STRIPE_PRODUCT_PAID_AD,
  STRIPE_PRODUCT_FB_IG_TW,
  STRIPE_PRODUCT_LINKEDIN,
  STRIPE_PRODUCT_PINTEREST,
  STRIPE_PRODUCT_YOUTUBE,
  STRIPE_PRODUCT_GOOGLE_BS,
  STRIPE_PRODUCT_TGT_CM,
  STRIPE_PRODUCT_AA_PLUS,
  STRIPE_PRODUCT_MANAGED_LP_BASE,
  STRIPE_PRODUCT_ADDITIONAL_LP,
  STRIPE_PRODUCT_DISCOVERABILITY,
  STRIPE_PRODUCT_GOOGLE_AD_SEARCH,
  DEFAULT_CANCELLATION_QUESTIONNAIRE_ID,
  STRIPE_PRODUCT_MANAGED_EMAIL_MARKETING,
  STRIPE_PRODUCT_MANAGED_EMAIL_MARKETING_PLUS,
  STRIPE_PRODUCT_MARKETING_ADVISORY_SERVICES,
  STRIPE_PRODUCT_PRESENCE,
  STRIPE_PRODUCT_PRESENCE_PLUS,
  STRIPE_PRICE_DIGITAL_DISCOVERY,
  STRIPE_PRODUCT_AA_GOOGLE_SEARCH_ADS,
  STRIPE_PRODUCT_AA_PLUS_GOOGLE_SEARCH_ADS,
  STRIPE_PRODUCT_DISCOVERABILITY_PLUS,
};
