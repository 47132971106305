import auth from '@targetable/targetable-web-framework/lib/services/auth';
import logger from './logger';

import env from '../globals';

const scopes = [
  'activity-logs',
  'billing',
  'businesses',
  'campaigns',
  'insights',
  'media',
  'stores',
  'store-url-presets',
  'users',
  'locations',
  'reports',
  'tags',
].join(' ');

export default auth(logger, env.AUTH0_DOMAIN, env.AUTH0_CLIENT_ID, 'https://api.targetable.io', scopes);
