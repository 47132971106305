import {
  DATA_INSTAGRAM_ACCOUNTS,
  DATA_INSTAGRAM_ACCOUNT,
  SHOW_INSTAGRAM_SELECT_MODAL,
} from '../constants';

export default {
  defaultState: {
    instagramAccount: null,
    instagramAccounts: null,
    showInstagramSelect: false,
  },
  reducers: {
    [DATA_INSTAGRAM_ACCOUNTS]: (state, { payload }) => ({ ...state, instagramAccounts: payload }),
    [DATA_INSTAGRAM_ACCOUNT]: (state, { payload }) => ({
      ...state,
      instagramAccount: payload,
    }),
    [SHOW_INSTAGRAM_SELECT_MODAL]: (state, { payload }) => ({
      ...state,
      showInstagramSelect: payload,
    }),
  },
};
