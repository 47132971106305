import {
  DATA_BELL_NOTIFICATIONS,
} from '../constants';

export default {
  defaultState: [],
  reducers: {
    [DATA_BELL_NOTIFICATIONS]: (state, { payload }) => [].concat(payload),
  },
};
