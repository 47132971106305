import { call, put } from 'redux-saga/effects';

import api from '../../services/api';
import { setBusiness, showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* updateBusiness({ payload }) {
  try {
    yield call(api.updateBusiness, payload.id, payload);
    const business = yield call(api.getBusiness, payload.id);
    yield put(setBusiness(business));
    return business;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'updateBusiness' },
      params: { businessId: payload.id, payload },
    });
    yield put(showToaster(getToasterOptions('generic_request_error', 'error')));
    return undefined;
  }
}
