import _ from 'lodash';
import axios from 'axios';
import { call, put, select } from 'redux-saga/effects';
import api from '../../services/api';
import history from '../../services/history';
import { getRejectedRoute, getMediaLibraryRoute } from '../../routes';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import { showToaster } from '../../actions';
import { selectBusiness } from '../../selectors';

export default function* getVideoThumbnail({
    payload: {
      publicId, startOffset, setThumbnailFrame, onClose,
    },
}) {
  try {
    const thumbnailUrl = yield call(api.getVideoThumbnail, publicId, startOffset);
    yield axios.get(thumbnailUrl);
    if (setThumbnailFrame) setThumbnailFrame(_.find(thumbnailUrl?.split('/'), (s) => _.startsWith(s, 'so_'))?.replace('so_', '')?.replace('/jpg', ''));
    yield put(showToaster(getToasterOptions('Thumbnail frame updated!', 'success', null, 3000)));
    const business = yield select(selectBusiness);
    onClose();
    history.push(getMediaLibraryRoute(business.id));
    return thumbnailUrl;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getVideoThumbnail' },
      params: { publicId, startOffset },
    });
    history.push(getRejectedRoute());
    return undefined;
  }
}
