import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

import FullScreenDialog from '@targetable/targetable-web-framework/lib/react/containers/FullScreenDialog/FullScreenDialog';
import useFormValidation from '@targetable/targetable-web-framework/lib/hooks/useFormValidation';
import { selectShowEditStoreNameModal, selectStoreEditStoreNameModal, selectStores } from '../../selectors';
import { confirmEditStoreName } from '../../actions';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 613,
    width: '100%',
    margin: `${theme.spacing(3)}px auto`,
  },
}));

const EditStoreNameModal = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const open = useSelector(selectShowEditStoreNameModal);
  const store = useSelector(selectStoreEditStoreNameModal);
  const stores = useSelector(selectStores);
  const dispatch = useDispatch();
  const storeName = _.get(store, 'name', '');

  const validation = useCallback((target, values) => {
    if (!values.name) {
      return { name: t('store_name_required') };
    }

    const valueName = _.trim(values.name);
    const otherStores = stores.filter((s) => s.id !== store.id);
    if (otherStores.some((s) => s.name === valueName)) {
      return { name: t('store_name_existing') };
    }
    return {};
  }, [stores, store.id, t]);

  const onSubmit = useCallback(({ name }) => {
    dispatch(confirmEditStoreName(_.trim(name)));
  }, [dispatch]);

  const onCancel = useCallback(() => {
    dispatch(confirmEditStoreName(null));
  }, [dispatch]);

  const {
    values,
    errors,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormValidation({ name: storeName }, validation, onSubmit);

  if (!open) {
    return null;
  }

  return (
    <FullScreenDialog
      open
      onClose={onCancel}
      title={t('store_name_edit_title')}
      buttons={[{
        text: t('save'),
        disabled: !isValid || values.name === storeName,
        onClick: handleSubmit,
      }]}
    >
      <Box className={classes.root}>
        <TextField
          name="name"
          data-cy="EditStoreNameModal_name_input"
          onChange={handleChange}
          onBlur={handleBlur}
          value={_.get(values, 'name')}
          error={!!errors.name}
          helperText={errors.name}
          variant="outlined"
          label={t('store_name')}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </Box>
    </FullScreenDialog>
  );
};

export default EditStoreNameModal;
