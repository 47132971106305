import { call, put, select } from 'redux-saga/effects';

import api from '../../services/api';

import { setStores } from '../../actions';
import { selectStores } from '../../selectors';
import logger from '../../services/logger';

export default function* getStore(id) {
  try {
    const store = yield call(api.getStore, id);
    const stores = yield select(selectStores);
    yield put(setStores(stores.map((s) => (s.id === id ? store : s))));
    return store;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getSmartFeed' },
      params: { storeId: id },
    });
    return undefined;
  }
}
