import { take, put, call } from 'redux-saga/effects';
import api from '../../services/api';
import logger from '../../services/logger';

import { setPublishMarketingDialog } from '../../actions';
import { RESULT_PUBLISH_MARKETING_DIALOG } from '../../constants';

export default function* showPublishMarketingDialog({ payload }) {
  const {
    confirmText,
    confirmDataCy,
    title,
    text,
    textParams,
    onClick,
    businessId,
  } = payload;

  let usersData = { users: [] };

  try {
    usersData = yield call(api.getUsers, businessId);
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'showPublishMarketingDialogGetUsers' },
      params: { businessId },
    });
  }

  yield put(setPublishMarketingDialog({
    isOpen: true,
    confirmText,
    confirmDataCy,
    title,
    text,
    textParams,
    users: usersData?.users,
  }));

  const { payload: info } = yield take(RESULT_PUBLISH_MARKETING_DIALOG);
  yield put(setPublishMarketingDialog({
    isOpen: false,
  }));
  if (info === false) return;
  onClick({ payload: info });
}
