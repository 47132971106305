import { DATA_AD_CARD_PRESETS } from '../constants';

const defaultState = {
  cards: {},
};

export default {
  defaultState,
  reducers: {
    [DATA_AD_CARD_PRESETS]: (state, { payload: cards }) => ({ ...state, cards }),
  },
};
