import { call, put, select } from 'redux-saga/effects';

import { getToasterOptions } from '../helpers';
import { setCampaign, showToaster as showToasterAction } from '../../actions';
import { selectCampaign } from '../../selectors';
import saveCampaign from '../saveCampaign/saveCampaign';
import history from '../../services/history';
import { getCampaignWizardDetailRoute, getCampaignsRoute } from '../../routes';
import { CAMPAIGN_SUBMITTED_WITH_ISSUES, CAMPAIGN_SUBMITTED_FAILED, DRAFT_TAB } from '../../constants';

export default function* saveCampaignForm({ payload }) {
  const {
    onDone,
    onError,
    showToaster,
    updatedCampaign,
  } = payload;

  const campaign = updatedCampaign || (yield select(selectCampaign));
  const savedCampaign = yield call(saveCampaign, campaign);

  if (savedCampaign) {
    yield put(setCampaign(savedCampaign));

    if (onDone) { onDone(); }

    let toastMessage = 'campaign_draft_saved';
    if (campaign.status === CAMPAIGN_SUBMITTED_WITH_ISSUES) {
      toastMessage = 'campaign_with_issues_saved_for_retry';
      history.push(getCampaignWizardDetailRoute(campaign.businessId, savedCampaign.id));
    }
    if (campaign.status === CAMPAIGN_SUBMITTED_FAILED) {
      toastMessage = 'campaign_draft_saved_from_failed';
      history.push(getCampaignsRoute(savedCampaign.businessId, DRAFT_TAB));
    }
    if (showToaster) {
      yield put(showToasterAction(getToasterOptions(toastMessage, 'info', null, 5000)));
    }
  } else {
    if (onError) {
      onError();
    }

    yield put(showToasterAction(getToasterOptions('error_update_campaign', 'error')));
  }
}
