import {
  call, put, select, take,
} from 'redux-saga/effects';

import _ from 'lodash';
import { SlackTemplateType, ActivityLogType } from '@targetable/targetable-types/dist/types';
import { selectBusiness, selectUser } from '../../selectors';
import { setLoadingAsync, showActivityLogNoteDialog, showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import createActivityLog from '../createActivityLog/createActivityLog';
import { RESULT_ACTIVITY_LOG_DIALOG } from '../../constants';

/**
 * Cancels the subscriptions contained in the subscription object.
 * @param {Function} onCompleted a function to invoke once the saga has completed successfully.
 * @param {Function} onError a function to invoke if the saga has not completed successfully.
 */
export default function* subscriptionCancellationRequestSaga({ payload }) {
  const {
    onError,
  } = payload;

  yield put(setLoadingAsync(false));
  yield put(showActivityLogNoteDialog({
    title: 'Subscription Cancellation Request',
    text: 'An account representative will reach out to confirm your cancellation request. In the meantime, we would love to hear about your experience using our product to better understand where we can improve.',
    confirmText: 'Continue',
    cancelText: 'Cancel',
    onClick: _.identity,
  }));

  const { payload: activityLogNote } = yield take(RESULT_ACTIVITY_LOG_DIALOG);
  if (activityLogNote === false) return;

  yield put(setLoadingAsync(true));

  yield put(showToaster(getToasterOptions(
    'Sending Subscription Cancellation Request...',
    'info', null, 7000, true, null, 'bottom', 'right',
  )));

  const business = yield select(selectBusiness);
  const currentUser = yield select(selectUser);

  try {
    yield call(createActivityLog, {
      businessId: business.id,
      userId: currentUser?.id,
      ui: {
        title: 'Subscription Cancellation Request by Customer',
        description: `The User ${_.get(currentUser, 'givenName')} ${_.get(currentUser, 'familyName')} has requested cancellation of their subscription.`,
      },
      type: ActivityLogType.CancelSubscription,
      slack: {
        templateType: SlackTemplateType.SubscriptionCancellationRequest,
      },
      meta: {
        userInfo: {
          email: currentUser?.email,
          givenName: currentUser?.givenName,
          familyName: currentUser?.familyName,
        },
        businessName: business?.name,
        entity: {
          ...(business?.stripeCustomerId && { stripeCustomerId: business?.stripeCustomerId }),
          ...(business?.maxioCustomerId && { maxioCustomerId: business?.maxioCustomerId }),
        },
        notes: activityLogNote,
      },
    });
    yield put(showToaster(getToasterOptions('Subscription Cancellation Request Sent.', 'success', null, 3000)));
  } catch (ex) {
    logger.error({
      error: ex,
      context: { saga: 'cancelStripeSubscriptionSaga' },
      params: { business, currentUser },
    });
    yield put(showToaster(getToasterOptions('error_cancel_subscription')));
    if (onError) { onError(); }
  }
}
