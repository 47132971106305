import { mergeCampaignAdItem } from '@targetable/targetable-web-framework/lib/react/campaignWizard/services/campaign/campaign';
import _ from 'lodash';

import {
  DATA_CAMPAIGN,
  DATA_CAMPAIGN_ADS,
  DATA_CAMPAIGN_NAME,
  DATA_CAMPAIGN_GOAL,
  DATA_CAMPAIGN_OBJECTIVE,
  DATA_CAMPAIGN_UPDATE_ADS,
  DATA_CAMPAIGN_PIXEL_EVENT_TYPE,
  DATA_CAMPAIGN_AD_TYPE,
  DATA_CAMPAIGN_OVERRIDE_AUTO_AGING,
  DATA_CAMPAIGN_EVENT_ID,
  DATA_CAMPAIGN_OPTIMIZATION_GOAL,
} from '../constants';

export default {
  defaultState: null,
  reducers: {
    [DATA_CAMPAIGN]: (state, { payload }) => ({ ...payload }),
    [DATA_CAMPAIGN_ADS]: (state, { payload }) => ({
      ...state,
      ads: payload,
    }),
    [DATA_CAMPAIGN_NAME]: (state, { payload: name }) => ({
      ...state,
      name,
    }),
    [DATA_CAMPAIGN_GOAL]: (state, { payload: goal }) => ({
      ...state,
      goal,
    }),
    [DATA_CAMPAIGN_OBJECTIVE]: (state, { payload }) => ({
      ...state,
      objective: payload,
    }),
    [DATA_CAMPAIGN_UPDATE_ADS]: (state, { payload }) => ({
      ...state,
      ads: state.ads.map((adItem) => mergeCampaignAdItem(adItem, payload)),
    }),
    [DATA_CAMPAIGN_PIXEL_EVENT_TYPE]: (state, { payload }) => ({
      ...state,
      pixelEventType: payload,
    }),
    [DATA_CAMPAIGN_OPTIMIZATION_GOAL]: (state, { payload }) => ({
      ...state,
      optimizationGoal: payload,
    }),
    [DATA_CAMPAIGN_AD_TYPE]: (state, { payload }) => ({
      ...state,
      ads: state.ads.map((adItem) => {
        const ad = _.get(adItem, ['ad']);
        return { ...adItem, ad: { ...ad, type: payload } };
      }),
    }),
    [DATA_CAMPAIGN_OVERRIDE_AUTO_AGING]: (state, { payload }) => ({
      ...state,
      overrideAutoAging: payload,
    }),
    [DATA_CAMPAIGN_EVENT_ID]: (state, { payload: eventId }) => ({
      ...state,
      campaign: {
        ...state.campaign,
        eventId,
      },
    }),
  },
};
