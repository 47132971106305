import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import api from '../../services/api';
import { selectBusiness } from '../../selectors';
import { setSubscription, setSubscriptionLoading } from '../../actions';

export default function* getStripeSubscription() {
  yield put(setSubscriptionLoading(true));
  const business = yield select(selectBusiness);

  if (business?.stripeCustomerId) {
    const subscriptions = yield call(api.getStripeSubscriptionStatus, _.get(business, 'stripeCustomerId'));

    yield put(setSubscription(subscriptions));
    yield put(setSubscriptionLoading(false));

    return subscriptions;
  }
  return {};
}
