import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import InfoStepperModal from '../InfoStepperModal/components/InfoStepperModal/InfoStepperModal';

import {
  connectInstagramAccountToAdAccount,
  showFacebookConnectAdAccountModal,
} from '../../actions';
import { selectShowFacebookConnectAdAccountModal } from '../../selectors';

const InfoStepperConnectAdAccount = ({
  connectInstagramAccountToAdAccountAction,
  showInfo,
  showInfoModalConnectAdAccountAction,
}) => {
  const { t } = useTranslation();

  const { store, show } = showInfo;
  const storeIntegrations = _.get(store, 'integrations');
  const facebookIntegration = _.find(storeIntegrations, (int) => int.type === 'facebook' || int.connection === 'facebook');

  return (
    <InfoStepperModal
      onClose={() => showInfoModalConnectAdAccountAction({ show: false, store: null })}
      open={show}
      title={t('instagram_connect_ad_account_title')}
      steps={[
        {
          label: t('instagram_connect_ad_account_step1_label'),
          line1: t('instagram_connect_ad_account_step1_line1'),
          line2: t('instagram_connect_modal_issues_line'),
          externalLink: `https://www.instagram.com/${_.get(facebookIntegration, 'instagramUsername')}`,
          linkText: t('instagram_account'),
          nextEnabled: true,
          nextOverride: () => { connectInstagramAccountToAdAccountAction({ store }); },
        },
      ]}
    />
  );
};

InfoStepperConnectAdAccount.propTypes = {
  connectInstagramAccountToAdAccountAction: PropTypes.func.isRequired,
  showInfo: PropTypes.shape({
    show: PropTypes.bool,
    store: PropTypes.shape({}),
  }).isRequired,
  showInfoModalConnectAdAccountAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  showInfo: selectShowFacebookConnectAdAccountModal(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectInstagramAccountToAdAccountAction: connectInstagramAccountToAdAccount,
  showInfoModalConnectAdAccountAction: showFacebookConnectAdAccountModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InfoStepperConnectAdAccount);
