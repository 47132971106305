import { call, put } from 'redux-saga/effects';
import moment from 'moment';

import api from '../../services/api';

import { setAdMetrics, setAdMetricsLoading, setAdMetricsLoadingError } from '../../actions';
import logger from '../../services/logger';

export default function* getAdMetrics(campaign) {
  if (!campaign.google?.ad?.id) {
    logger.error({
      error: 'Ad id not exists',
      context: { saga: 'getAdMetrics' },
      params: { campaign },
    });
    return undefined;
  }
  try {
    yield put(setAdMetrics([]));
    yield put(setAdMetricsLoading(true));
    yield put(setAdMetricsLoadingError(false));

    const { startDate, endDate } = campaign;
    const reports = yield call(
      api.getGoogleSearchAdMetricsByAdGroupId,
      campaign.marketingChannelId,
      campaign.google?.ad?.id,
      // from
      moment(startDate).format('YYYY-MM-DD'),
      // to
      endDate ? moment(endDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'), // if evergreen => no endDate
    );

    yield put(setAdMetrics(reports));
    yield put(setAdMetricsLoading(false));

    return reports;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getAdMetrics' },
      params: { campaign },
    });

    yield put(setAdMetricsLoading(false));
    yield put(setAdMetricsLoadingError(true));

    return undefined;
  }
}
