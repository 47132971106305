import React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';

import StripeContainer from '../StripeContainer/StripeContainer';
import { selectBillingModalStage, selectShowBillingModal } from '../../../../selectors';
import {
  billingModalStripeSubscribe,
  createStripePaymentMethod,
  billingModalStripeExit,
} from '../../../../actions';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 4,
    height: '100%',
    margin: `${theme.spacing(4)}px auto 0`,
  },
  container: {
    minHeight: '490px',
    '&:focus': {
      outline: 'none',
    },
  },
  closeButtonContainer: {
    position: 'relative',
    height: theme.spacing(3.5),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#757575',
    padding: 0,
  },
}));

export default () => {
  const classes = useStyles();
  const stage = useSelector(selectBillingModalStage);
  const open = useSelector(selectShowBillingModal);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(billingModalStripeSubscribe());
    dispatch(createStripePaymentMethod());
    dispatch(billingModalStripeExit());
  };

  return (
    <>
      <Modal disableAutoFocus onClose={onClose} open={open}>
        <Grid container justifyContent="center" className={classes.container}>
          <Grid item xs={11} sm={10} md={8} lg={5}>
            <Paper className={classes.root} square>
              <div className={classes.closeButtonContainer}>
                <IconButton aria-label="Close" className={classes.closeButton} disableRipple onClick={onClose} data-cy="cta-cancel_targetable_subscription_required">
                  <CloseIcon />
                </IconButton>
              </div>
              {_.includes(['stripe-intro', 'stripe-form', 'stripe-outro'], stage) && <StripeContainer stage={stage} />}
            </Paper>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};
