import _ from 'lodash';
import {
  call, put, select, fork,
} from 'redux-saga/effects';

import { getUserFacebookIntegration } from '@targetable/targetable-web-framework/lib/services/user';

import api from '../../services/api';
import {
  showBackdrop,
  showToaster,
  setFacebookTokenExpired,
  showAccountIssuesModal,
} from '../../actions';
import logger from '../../services/logger';
import { facebookLoginPromise, getToasterOptions } from '../helpers';
import { selectUser } from '../../selectors';
import updateUser from '../updateUser/updateUser';
import getStoresFacebookPermissions from '../getStoresFacebookPermissions/getStoresFacebookPermissions';

export default function* connectFacebookAccount({ payload }) {
  yield put(showBackdrop(true));

  const onDone = _.get(payload, 'onDone');
  const showToast = _.get(payload, 'showToast');

  const facebookInfo = yield call(facebookLoginPromise);
  let tokenSaved = !!facebookInfo;

  if (facebookInfo) {
    const user = yield select(selectUser);
    const userId = _.get(user, 'id');
    try {
      const longLivedToken = yield call(
        api.getFacebookLongLivedToken,
        userId,
        facebookInfo.accessToken,
      );

      const facebookIntegration = getUserFacebookIntegration(user);
      yield call(updateUser, {
        id: userId,
        integrations: [
          ...(_.filter(user.integrations, (i) => i !== facebookIntegration)),
          {
            accessToken: longLivedToken,
            userId: facebookInfo.userId,
            type: 'facebook',
          },
        ],
      });

      yield put(setFacebookTokenExpired(false));

      yield fork(getStoresFacebookPermissions);
      yield put(showAccountIssuesModal(false));

      if (showToast) {
        yield put(showToaster(getToasterOptions('facebook_connected_to_user_successfully', 'success', null, 5000)));
      }

      tokenSaved = true;
    } catch (e) {
      logger.error({
        error: e,
        context: { saga: 'connectFacebookAccount' },
        params: { user },
      });
      yield put(showToaster(getToasterOptions('generic_request_error', 'error')));
      tokenSaved = false;
    }
  }

  yield put(showBackdrop(false));

  if (onDone) {
    onDone(tokenSaved);
  }

  return tokenSaved;
}
