import { DATA_GOOGLE_PROMOTIONS_BY_MARKETING_CHANNEL_ID, DATA_GOOGLE_SITELINKS_BY_MARKETING_CHANNEL_ID } from '../constants';

const defaultState = {
  sitelinks: {},
  promotions: {},
};

export default {
  defaultState,
  reducers: {
    [DATA_GOOGLE_SITELINKS_BY_MARKETING_CHANNEL_ID]: (state, { payload }) => (
      {
        ...state,
        sitelinks: {
          ...state?.sitelinks,
          [payload?.marketingChannelId]: {
            loading: payload?.loading,
            data: payload?.sitelinks,
          },
        },
      }
    ),
    [DATA_GOOGLE_PROMOTIONS_BY_MARKETING_CHANNEL_ID]: (state, { payload }) => (
      {
        ...state,
        promotions: {
          ...state?.promotions,
          [payload?.marketingChannelId]: {
            loading: payload?.loading,
            data: payload?.promotions,
          },
        },
      }
    ),
  },
};
