import { call, put } from 'redux-saga/effects';

import api from '../../services/api';
import { setCoordinates, setLoadingCoordinates } from '../../actions';
import logger from '../../services/logger';

export default function* translateAddressToCoordinates({ payload: address }) {
  try {
    yield put(setLoadingCoordinates(true));

    const coordinates = yield call(api.translateAddressToCoordinates, address);

    yield put(setCoordinates(coordinates));
    yield put(setLoadingCoordinates(false));
    return coordinates;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'translateAddressToCoordinates' },
      params: { address },
    });
    yield put(setLoadingCoordinates(false));
    return undefined;
  }
}
