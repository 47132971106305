import getStore from '@targetable/targetable-web-framework/lib/store';

import reducers from './reducers';
import rootSaga from './sagas';
import history from './services/history';
import globals from './globals';

const store = getStore(history, reducers, rootSaga, globals.ENVIRONMENT_NAME !== 'prod');

if (module.hot) {
  module.hot.accept('./reducers', () => {
    // eslint-disable-next-line global-require
    const nextRootReducer = require('./reducers').default;
    store.replaceReducer(nextRootReducer);
  });
}

export default store;
