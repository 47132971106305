import { call, select, put } from 'redux-saga/effects';

import _ from 'lodash';
import api from '../../services/api';
import { selectBusiness, selectUser } from '../../selectors';
import logger from '../../services/logger';
import {
  showToaster,
} from '../../actions';

import { getToasterOptions } from '../helpers';

export default function* createStripePaymentMethod(token) {
  const business = yield select(selectBusiness);
  const stripeCustomerId = _.get(business, 'stripeCustomerId');
  const businessId = _.get(business, 'id');

  const user = yield select(selectUser);
  const userId = _.get(user, 'id');
  const email = _.get(user, 'email');

  try {
    const result = yield call(
      api.createStripePaymentMethod, stripeCustomerId, {
        token, businessId, userId, email,
      },
    );
    if (!result) yield put(showToaster(getToasterOptions('subscription_fail_toaster', 'error', null, 5000)));
    return result;
  } catch (error) {
    logger.error({
      error,
      context: { saga: 'createStripeCustomer' },
      params: {
        business, stripeCustomerId, token,
      },
    });
    return false;
  }
}
