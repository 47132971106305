import _ from 'lodash';
import { call, put } from 'redux-saga/effects';

import api from '../../services/api';

import { setStoresCustomAudiences, showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* getCustomAudiences(businessId, reset) {
  if (reset) {
    yield put(setStoresCustomAudiences(null));
  }

  const audiences = {};

  try {
    const storesAudiences = yield call(api.getCustomAudiences, businessId);

    Object.keys(storesAudiences || {}).forEach((key) => {
      const filteredAudiences = storesAudiences[key].filter((o) => _.get(o, 'delivery_status.code') === 200);
      if (_.size(filteredAudiences)) {
        audiences[key] = filteredAudiences;
      }
    });

    yield put(setStoresCustomAudiences(audiences));
  } catch (e) {
    yield put(showToaster(getToasterOptions('Failed getting custom audiences. Try again in a few minutes.', 'error', null, 5000)));

    logger.error({
      error: e,
      context: { saga: 'getCustomAudiences' },
      params: { businessId },
    });
  }

  return audiences;
}
