import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  img: {
    width: '100%',
    height: 'intrinsic', // fix Safari bug
    margin: theme.spacing(1, 0, 1, 0),
  },
}));

const InfoStepImage = ({
  imageDefinition,
}) => {
  const classes = useStyles();
  return (
    <Grid container justifyContent="center">
      <Hidden smUp>
        <img className={classes.img} src={_.get(imageDefinition, 'xs')} alt="" />
      </Hidden>
      <Hidden xsDown mdUp>
        <img className={classes.img} src={_.get(imageDefinition, 'sm')} alt="" />
      </Hidden>
      <Hidden smDown lgUp>
        <img className={classes.img} src={_.get(imageDefinition, 'md')} alt="" />
      </Hidden>
      <Hidden mdDown>
        <img className={classes.img} src={_.get(imageDefinition, 'lg')} alt="" />
      </Hidden>
    </Grid>
  );
};

InfoStepImage.propTypes = {
  imageDefinition: PropTypes.shape({}).isRequired,
};

export default InfoStepImage;
