import { call, put } from 'redux-saga/effects';
import api from '../../services/api';
import { showToaster } from '../../actions';
import i18n from '../../services/i18n';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* updateImageTags({ payload }) {
  const { publicId, tags, onDone } = payload;

  try {
    yield call(api.updateImageTags, publicId, tags);
    yield put(showToaster(getToasterOptions(i18n.t('image_tags_updated_successfully'), 'success', null, 5000)));

    if (onDone) { onDone(); }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'updateImageTags' },
      params: {
        tags,
        publicId,
      },
    });
    yield put(showToaster(getToasterOptions(i18n.t('error_update_image'))));
  }
}
