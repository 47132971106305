import { call, put } from 'redux-saga/effects';
import { showToaster } from '../../actions';
import { getToasterOptions } from '../helpers';
import api from '../../services/api';
import logger from '../../services/logger';

export default function* adRequest({ payload }) {
  const {
    businessId,
    data,
    onDone,
    onError,
  } = payload;
  try {
    yield put(showToaster(getToasterOptions('Sending request...', 'info', null, 10000, true, null, 'bottom', 'right')));

    yield call(
      api.createAdRequest,
      businessId,
      data,
    );
    yield put(showToaster(getToasterOptions('Request successfully sent!', 'success', null, 3000)));
    if (onDone) {
      onDone();
    }
  } catch (ex) {
    if (onError) { onError(); }
    logger.error({
      error: ex,
      context: { saga: 'adRequest' },
      params: { payload },
    });
    yield put(showToaster(getToasterOptions('error_whoops')));
  }
}
