import { call, put, select } from 'redux-saga/effects';
import api from '../../services/api';
import logger from '../../services/logger';
import i18n from '../../services/i18n';
import { showToaster, closeToaster } from '../../actions';
import { getToasterOptions } from '../helpers';
import { selectBusiness } from '../../selectors';

export default function* sendMailChimpTestEmail({ payload }) {
  const {
    onDone,
    onError,
    marketingChannelId,
    campaignId,
    recipients,
  } = payload;

  const currentBusiness = yield select(selectBusiness);

  try {
    yield put(showToaster(getToasterOptions(
      'Sending mailchimp test email...',
      'info',
    )));
    yield call(
      api.sendMailChimpTestEmail,
      marketingChannelId,
      campaignId,
      { recipients },
    );
    if (onDone) onDone();
    yield put(closeToaster());
    yield put(showToaster(getToasterOptions(i18n.t('test_emails_sent'), 'info', null, 5000)));
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'sendMailChimpTestEmail' },
      params: {
        marketingChannelId,
        campaignId,
        currentBusiness,
      },
    });
    if (onError) onError();
    yield put(showToaster(getToasterOptions('Error when trying to send mailchimp test email', 'error')));
  }
}
