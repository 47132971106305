import { call, put, select } from 'redux-saga/effects';

import api from '../../services/api';
import { selectBusiness } from '../../selectors';
import {
  setInvoiceHistory,
  setInvoiceHistoryLoading,
} from '../../actions';
import logger from '../../services/logger';

export default function* getInvoiceHistorySaga({ payload }) {
  try {
    const business = yield select(selectBusiness);
    if (!business.stripeCustomerId) {
      yield put(setInvoiceHistory([]));
      return;
    }

    yield put(setInvoiceHistoryLoading(true));
    const invoices = yield call(
      api.getInvoiceHistory,
      business.stripeCustomerId,
      payload?.lastKey,
      payload?.limit,
    );

    yield put(setInvoiceHistory(invoices));
    yield put(setInvoiceHistoryLoading(false));
  } catch (e) {
    yield put(setInvoiceHistoryLoading(false));
    yield put(setInvoiceHistory([]));
    logger.error({
      error: e,
      context: { saga: 'getInvoiceHistorySaga' },
      params: { payload },
    });
  }
}
