import {
  call,
  put,
  select,
} from 'redux-saga/effects';
import {
  CAMPAIGN_MAILCHIMP_TYPE,
} from '@targetable/targetable-web-framework/lib/react/constants';
import {
  setLoadingAsync,
  showToaster,
  closeFeedbackDialog,
  closeToaster,
} from '../../actions';
import { selectBusiness } from '../../selectors';
import api from '../../services/api';
import logger from '../../services/logger';
import publishMarketingAd from '../publishMarketingAd/publishMarketingAd';
import { getToasterOptions } from '../helpers';

import { getManagedEmailMarketingRoute, getHomeRoute } from '../../routes';
import history from '../../services/history';

/* eslint-disable max-len */
/**
 * @typedef {import('@targetable/targetable-types/dist/types').UserFeedback} UserFeedback
 * @typedef {import('@targetable/targetable-types/dist/types').SetAdFeedbackResponse} SetAdFeedbackResponse
 */
/* eslint-enable max-len */

/**
 * Sets the feedback of an ad
 * @param {object} reduxAction
 * @param {object} reduxAction.payload
 * @param {object} reduxAction.payload.campaignAd the ad campaign
 * @param {string} reduxAction.payload.campaignAd.id the id of the ad to update
 * @param {string} reduxAction.payload.campaignAd.name the name of the ad to update
 * @param {UserFeedback[]} reduxAction.payload.feedback the id of the ad to update
 * @param {'approved' | 'rejected'} reduxAction.payload.decision the id of the ad to update
 * @param {function} [reduxAction.payload.onError] a method to invoke if the action failed.
 */
export default function* submitAdFeedback(reduxAction) {
  const { payload } = reduxAction;
  const {
    campaignAd, feedback, decision, onError, hasUnSavedChanges,
  } = payload;

  const business = yield select(selectBusiness);

  try {
    yield put(setLoadingAsync(true));

    const shouldPublish = decision === 'approved';

    if (hasUnSavedChanges && shouldPublish) {
      const toastMessage = 'Saving the Ad...';
      yield put(showToaster(getToasterOptions(
        toastMessage, 'info', null, 7000, true, null, 'bottom', 'right',
      )));
      yield call(api.updateAdDraft, campaignAd.id, campaignAd);
      yield put(closeToaster());
    }

    // Set feedback responses
    yield call(api.setAdFeedback, campaignAd.id, feedback, decision);

    // Publish the ad
    if (shouldPublish) {
      yield call(publishMarketingAd, reduxAction);
    }

    yield put(closeFeedbackDialog());

    // The publishing process already re-loads the smartfeed/Email Marketing
    if (!shouldPublish) {
      yield put(setLoadingAsync(false));
      if (campaignAd.type === CAMPAIGN_MAILCHIMP_TYPE) {
        history.push(getManagedEmailMarketingRoute(business.id));
        history.go(0);
      } else {
        // Reload SmartFeed
        history.push(getHomeRoute(business.id));
      }
    }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'submitAdFeedback' },
      params: { info: 'Failed to set ad feedback', feedback, campaignAd },
    });
    yield put(setLoadingAsync(false));

    const error = e?.response?.data || 'submit_feedback_error';
    yield put(showToaster(getToasterOptions(error, 'error')));

    onError && onError();
  }
}
