import { call, put } from 'redux-saga/effects';

import api from '../../services/api';
import { showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import getStoresByBusiness from '../getStoresByBusiness/getStoresByBusiness';

export default function* updateStore({ payload }) {
  try {
    const store = yield call(api.updateStore, payload.id, payload);
    yield call(getStoresByBusiness, payload.businessId);
    return store;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'updateStore' },
      params: { storeId: payload.id, payload },
    });
    yield put(showToaster(getToasterOptions('generic_request_error', 'error')));
    return undefined;
  }
}
