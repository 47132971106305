import { call, put, select } from 'redux-saga/effects';

import history from '../../services/history';
import { getRejectedRoute } from '../../routes';
import api from '../../services/api';
import {
  setBusinessUsers,
  setUsersLoading,
} from '../../actions';
import { selectUser } from '../../selectors';
import logger from '../../services/logger';

// export default function* getBusinessUsers({ payload: logUserView }) {
export default function* getBusinessUsers() {
  const currentUser = yield select(selectUser);
  yield put(setUsersLoading(true));

  try {
    const { users } = yield call(api.getUsers, currentUser.businessId);
    yield put(setBusinessUsers(users));
    yield put(setUsersLoading(false));

    // if (logUserView && _.size(users) > 0) {
    //   yield call(api.createUserLogs, currentUser.id, {
    //     timestamp: new Date().valueOf(),
    //     userId: currentUser.id,
    //     viewedUrl: window.location.href,
    //     viewedUsers: _.map(users, (user) => _.get(user, 'id')),
    //   });
    // }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getBusinessUsers' },
      params: { currentUser },
    });
    yield put(setUsersLoading(false));
    history.push(getRejectedRoute());
  }
}
