import _ from 'lodash';
import { InsightTypes, InsightCampaignUpdateTypes } from '@targetable/targetable-types/dist/types/insight';

import {
  SMARTFEED_ACTION_APPROVED,
  SMARTFEED_ACTION_APPROVED_DRAFT,
  SMARTFEED_ACTION_REJECTED,
  SMARTFEED_TYPE_INSIGHT,
  SUBSCRIPTION_CANCELLATION,
} from '../constants';
import i18n from './i18n';

export function getCancelCTA(type) {
  if (type === SMARTFEED_TYPE_INSIGHT) {
    return 'cta-cancel_feedback_insight';
  }

  return 'cta-cancel_feedback_suggested_campaign';
}

export function getCardTitle(item, campaign) {
  if (campaign) { return _.get(campaign, 'name'); }
  return _.get(item, 'title') || _.get(item, 'name');
}

export function getFeedbackQuestions(type, action, insightType) {
  if (type === SMARTFEED_TYPE_INSIGHT) {
    if (insightType === InsightTypes.ManagedSocialMedia) {
      return [
        { question: i18n.t('feedback_insight_managed_social_question_1') },
        { question: i18n.t('feedback_insight_managed_social_question_2') },
        { question: i18n.t('feedback_insight_managed_social_question_3') },
      ];
    }

    if (insightType === InsightTypes.ManagedLandingPages) {
      return [
        { question: i18n.t('feedback_insight_managed_landing_question_1') },
        { question: i18n.t('feedback_insight_managed_landing_question_2') },
        { question: i18n.t('feedback_insight_managed_landing_question_3') },
      ];
    }

    if (insightType === InsightTypes.AcquisitionAndAwarenessPlus) {
      return [
        { question: i18n.t('feedback_insight_aaaplus_question_1') },
        { question: i18n.t('feedback_insight_aaaplus_question_2') },
        { question: i18n.t('feedback_insight_aaaplus_question_3') },
      ];
    }

    if (action === SMARTFEED_ACTION_APPROVED) {
      return [
        { question: i18n.t('feedback_insight_approved_question_1') },
        { question: i18n.t('feedback_insight_approved_question_2') },
        { question: i18n.t('feedback_insight_approved_question_3') },
      ];
    }

    if (action === SMARTFEED_ACTION_REJECTED) {
      return [
        { question: i18n.t('feedback_insight_rejected_question_1') },
        { question: i18n.t('feedback_insight_rejected_question_2') },
        { question: i18n.t('feedback_insight_rejected_question_3') },
      ];
    }
  }

  if (action === SMARTFEED_ACTION_APPROVED) {
    return [
      { question: i18n.t('feedback_suggested_campaign_approved_question_1') },
      { question: i18n.t('feedback_suggested_campaign_approved_question_2') },
      { question: i18n.t('feedback_suggested_campaign_approved_question_3') },
    ];
  }

  if (action === SMARTFEED_ACTION_APPROVED_DRAFT) {
    return [
      { question: i18n.t('feedback_suggested_campaign_saved_question_1') },
      { question: i18n.t('feedback_suggested_campaign_saved_question_2') },
      { question: i18n.t('feedback_suggested_campaign_saved_question_3') },
    ];
  }

  if (action === SMARTFEED_ACTION_REJECTED) {
    return [
      { question: i18n.t('feedback_suggested_campaign_rejected_question_1') },
      { question: i18n.t('feedback_suggested_campaign_rejected_question_2') },
      { question: i18n.t('feedback_suggested_campaign_rejected_question_3') },
    ];
  }

  return [];
}

export const getHeaderTitleParams = (item) => {
  let text = '';
  switch (item?.campaignUpdate?.updateType) {
    case InsightCampaignUpdateTypes.Budget:
      text = 'Budget';
      break;
    case InsightCampaignUpdateTypes.HeadlinesAndDescriptions:
      text = 'Headlines and Descriptions';
      break;
    case InsightCampaignUpdateTypes.Keywords:
      text = 'Keywords';
      break;
    case InsightCampaignUpdateTypes.EndDate:
      text = 'End Date';
      break;
    case InsightCampaignUpdateTypes.Locations:
      text = 'Locations';
      break;
    case InsightCampaignUpdateTypes.Promotions:
      text = 'Promotions';
      break;
    default:
      break;
  }
  return text;
};

export function getHeaderTitle(type, action, item) {
  if (type === SMARTFEED_TYPE_INSIGHT) {
    if (item?.type === InsightTypes.CampaignUpdate) {
      if (action === SMARTFEED_ACTION_APPROVED) {
        return 'insight_approved_campaign_update';
      }
      return 'insight_rejected_campaign_update';
    }
    if (action === SMARTFEED_ACTION_APPROVED) {
      return 'insight_approved';
    }
    return 'insight_rejected';
  }

  if (action === SMARTFEED_ACTION_APPROVED) {
    return 'suggested_campaign_approved';
  }

  if (action === SMARTFEED_ACTION_APPROVED_DRAFT) {
    return 'suggested_campaign_saved';
  }

  if (type === SUBSCRIPTION_CANCELLATION) {
    return 'cancel_subscription';
  }

  return 'suggested_campaign_dismissed';
}

export function getSubmitCTA(type) {
  if (type === SMARTFEED_TYPE_INSIGHT) {
    return 'cta-submit_feedback_insight';
  }

  return 'cta-submit_feedback_suggested_campaign';
}

export function validate(list) {
  return _.filter(list, (question) => question.value).length === list.length;
}

export function getButtonText(type, action) {
  if (type === SMARTFEED_TYPE_INSIGHT) {
    if (action === SMARTFEED_ACTION_APPROVED) {
      return i18n.t('feedback_button_insight_approve');
    }
    return i18n.t('feedback_button_insight_reject');
  }
  if (action === SMARTFEED_ACTION_APPROVED) {
    return i18n.t('feedback_button_campaign_publish');
  }
  if (action === SMARTFEED_ACTION_APPROVED_DRAFT) {
    return i18n.t('feedback_button_campaign_save');
  }
  if (type === SUBSCRIPTION_CANCELLATION) {
    return i18n.t('business_cancel_button');
  }
  return i18n.t('feedback_button_campaign_dismiss');
}

export function getConfirmationText(type, action) {
  if (type === SMARTFEED_TYPE_INSIGHT) {
    if (action === SMARTFEED_ACTION_APPROVED) {
      return i18n.t('feedback_cancel_confirmation_insight_approve');
    }
    return i18n.t('feedback_cancel_confirmation_insight_reject');
  }
  if (action === SMARTFEED_ACTION_APPROVED) {
    return i18n.t('feedback_cancel_confirmation_campaign_publish');
  }
  if (action === SMARTFEED_ACTION_APPROVED_DRAFT) {
    return i18n.t('feedback_cancel_confirmation_campaign_save');
  }
  if (type === SUBSCRIPTION_CANCELLATION) {
    return i18n.t('feedback_cancel_questionnaire_dismiss');
  }
  return i18n.t('feedback_cancel_confirmation_campaign_dismiss');
}
