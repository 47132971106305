import _ from 'lodash';
import {
  call, put, select, take,
} from 'redux-saga/effects';
import { closeToaster, setConfirmationDialog, setLoadingAsync } from '../../actions';
import { HANDLE_FEEDBACK_DIALOG, RESULT_CONFIRMATION_DIALOG, SUBMIT_CAMPAIGN_FORM } from '../../constants';
import {
  selectCampaignAds,
  selectStores,
  selectUser,
  selectBusiness,
  selectCampaign,
} from '../../selectors';
import createActivityLog from '../createActivityLog/createActivityLog';

function buildStoreList(stores) {
  let list = '<div><br/>';
  stores.forEach(({ isValid, name }) => {
    if (!isValid) {
      list += `<div>- ${name}</div>`;
    }
  });
  list += '</div>';
  return list;
}

const areAllStoresDisabled = (ads, stores) => {
  const storeIds = _.map(ads, 'storeId');

  const campaignStores = _.compact(_.map(storeIds, (storeId) => _.find(stores, { id: storeId })));
  return {
    allDisabled: _.every(campaignStores, ['disabled', true]) && campaignStores.length > 0,
    stores: campaignStores,
  };
};

export default function* ensureAtLeastOneEnabledStore(...args) {
  const action = _.last(args || []);
  const actionType = _.get(action, 'type');
  let onDone = _.get(action, 'payload.onDone');

  let ads = [];
  let campaign;
  switch (actionType) {
    case HANDLE_FEEDBACK_DIALOG:
      ads = _.get(action, 'payload.campaign.ads');
      campaign = _.get(action, 'payload.item');
      break;
    case SUBMIT_CAMPAIGN_FORM:
      ads = yield select(selectCampaignAds);
      campaign = yield select(selectCampaign);
      break;
    default:
      if (action?.campaign) {
        campaign = action.campaign;
        ads = campaign?.ads;
      }
      if (!onDone && action?.onDone) {
        onDone = action.onDone;
      }
      break;
  }

  const stores = yield select(selectStores);
  const user = yield select(selectUser);
  const business = yield select(selectBusiness);

  // Validate if at least one of the asociated stores is active
  const disabledStores = areAllStoresDisabled(ads, stores);
  if (disabledStores.allDisabled) {
    yield put(closeToaster());
    yield put(setLoadingAsync(false));

    yield put(setConfirmationDialog({
      isOpen: true,
      cancelText: 'ok',
      cancelDataCy: 'ensureAtLeastOneEnabledStore_ok_button',
      confirmText: '',
      text: 'campaign_publish_disabled_stores_dialog_text',
      title: 'campaign_publish_disabled_stores_dialog_title',
      textParams: { list: buildStoreList(disabledStores.stores) },
    }));
    yield take(RESULT_CONFIRMATION_DIALOG);
    yield put(setConfirmationDialog({ isOpen: false }));
    if (onDone) onDone();

    // Create Activity Log on attempt for publishing with disabled stores
    yield call(createActivityLog, {
      businessId: business?.id,
      userId: user?.id,
      type: 'disabled_campaign_store',
      ui: {
        title: 'The Campaign\'s Stores are inactive',
        description: 'A publishing attempt has failed due to all the stores associated to the campaign are disabled',
      },
      meta: {
        ...(actionType === HANDLE_FEEDBACK_DIALOG && { suggestedCampaignId: campaign.id }),
        ...(actionType !== HANDLE_FEEDBACK_DIALOG && { campaignId: campaign.id }),
        disabledStores: _.map(
          disabledStores.stores,
          (store) => ({ id: store.id, name: store.name, disabled: store.disabled }),
        ),
      },
    });
    return false;
  }
  if (args.length && typeof args[0] === 'function') {
    yield call(...args);
  }
  if (onDone) onDone();
  return true;
}
