import { call } from 'redux-saga/effects';

import api from '../../services/api';
import logger from '../../services/logger';

export default function* getSilectisFacebookMetrics({
  payload: {
    businessId,
    onSuccess,
    onError,
  },
}) {
  try {
    const results = yield call(api.getSilectisFacebookMetrics, businessId);
    // eslint-disable-next-line no-unused-expressions
    onSuccess && onSuccess(results);
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getSilectisFacebookMetrics' },
      params: { businessId },
    });
    // eslint-disable-next-line no-unused-expressions
    onError && onError();
  }
}
