import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';

import {
  showAccountIssuesModal,
  setBillingModalStage,
  setShowBillingModal,
  showBackdrop,
  showToaster,
} from '../../actions';
import { CREATE_STRIPE_PAYMENT_METHOD } from '../../constants';
import { selectBusiness, selectUser } from '../../selectors';
import logger from '../../services/logger';
import createStripeSubscription from '../createStripeSubscription/createStripeSubscription';
import { getToasterOptions, takeAsyncAction } from '../helpers';
import createStripePaymentMethod from '../createStripePaymentMethod/createStripePaymentMethod';
import createStripeCustomer from '../createStripeCustomer/createStripeCustomer';
import updateBusiness from '../updateBusiness/updateBusiness';

export default function* handleAddStripePaymentMethod() {
  yield put(setShowBillingModal({ show: true, includeCoupon: true }));
  yield put(setBillingModalStage('stripe-form'));
  const user = yield select(selectUser);
  const business = yield select(selectBusiness);

  // Create stripe customer if the user hasn't already
  try {
    if (_.isNil(_.get(business, 'stripeCustomerId'))) {
      const customerId = yield call(createStripeCustomer, _.get(business, 'id'), _.get(business, 'name'), _.get(user, 'email'));
      if (customerId) {
        yield call(updateBusiness, {
          payload: {
            id: _.get(business, 'id'),
            stripeCustomerId: customerId,
            onboarded: true,
          },
        });
      } else {
        throw new Error('Failed to create billing record');
      }
    }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'handleAddStripePaymentMethod' },
    });

    yield put(showToaster(getToasterOptions('error_payment_method_error')));
    yield put(setShowBillingModal({ show: false }));
    return;
  }

  // handle payment method form
  // loop until the payment method and subscription is created successfully
  let paymentMethodCreated = false;
  while (true) {
    const action = yield call(takeAsyncAction, CREATE_STRIPE_PAYMENT_METHOD);

    if (!_.get(action, ['payload', 'token', 'id'])) {
      yield put(setShowBillingModal({ show: false }));
      return;
    }

    yield put(showBackdrop(true));

    if (!paymentMethodCreated) {
      paymentMethodCreated = yield call(createStripePaymentMethod, _.get(action, 'payload.token.id'));
    }

    if (paymentMethodCreated) {
      // create subscription and check if coupon is valid
      const isValid = yield call(
        createStripeSubscription,
        _.get(action, 'payload.coupon'),
        _.get(action, 'payload.onError'),
      );

      if (isValid) {
        break;
      }
    }

    paymentMethodCreated = null;
    yield put(showBackdrop(false));
    yield put(showToaster(getToasterOptions('subscription_fail_toaster', 'error', null, 5000)));
  }

  paymentMethodCreated = null;
  yield put(showBackdrop(false));
  yield put(showAccountIssuesModal(false));
  yield put(showToaster(getToasterOptions('subscription_success_toaster', 'success', null, 5000)));
  yield put(setShowBillingModal({ show: false }));
}
