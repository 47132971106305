import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import _ from 'lodash';

import adCardPresetsReducers from './adCardPresets';
import billingReducers from './billing';
import businessesReducers from './business';
import campaignReducers from './campaign';
import campaignsReducers from './campaigns';
import confirmationDialogReducers from './confirmationDialog';
import connectInstagram from './connectInstagram';
import facebookMetricsReducers from './facebookMetrics';
import feedbackReducers from './feedback';
import facebookPagesReducers from './facebookPages';
import facebookPermissions from './facebookPermissions';
import imagesReducers from './images';
import imagePickerReducers from './imagePicker';
import imagesMamReducers from './imagesMam';
import integrationsReducers from './integrations';
import modalsReducers from './modals';
import loadingReducers from './loading';
import onboardingBusinessReducers from './onboardingBusiness';
import reportsReducers from './reports';
import smartFeedReducers from './smartFeed';
import storesReducers from './stores';
import storesCustomAudiencesReducers from './storesCustomAudiences';
import suggestedCampaign from './suggestedCampaign';
import toasterReducers from './toaster';
import userReducers from './user';
import urlPresetsReducers from './urlPresets';
import googleMetrics from './googleMetrics';
import bellNotification from './bellNotification';
import marketingChannels from './marketingChannels';
import searchAdCombinations from './searchAdCombinations';
import publishMarketingDialog from './publishMarketingDialog';
import locations from './locations';
import comments from './comments';
import googleAssets from './googleAssets';
import activityLogNote from './activityLogNote';

import * as constants from '../constants';

// From redux
// The reducers may never return undefined for any action. Instead, they should return their
// initial state if the state passed to them was undefined, and the current
// state for any unrecognized action.
export const createReducer = ({ defaultState, reducers, excludeFromReset }) => (state, action) => {
  if (_.isUndefined(state) || (_.get(action, 'type') === constants.RESET_APP && excludeFromReset !== true)) {
    return defaultState;
  }

  const reducer = reducers[action.type];
  return _.isNil(reducer) ? state : reducer(state, action);
};

// https://github.com/supasate/connected-react-router#step-1
export default (history) => combineReducers({
  activityLogNote: createReducer(activityLogNote),
  adCardPresets: createReducer(adCardPresetsReducers),
  bellNotifications: createReducer(bellNotification),
  billing: createReducer(billingReducers),
  business: createReducer(businessesReducers),
  campaign: createReducer(campaignReducers),
  campaigns: createReducer(campaignsReducers),
  confirmationDialog: createReducer(confirmationDialogReducers),
  connectInstagram: createReducer(connectInstagram),
  facebookMetrics: createReducer(facebookMetricsReducers),
  facebookPages: createReducer(facebookPagesReducers),
  facebookPermissions: createReducer(facebookPermissions),
  feedback: createReducer(feedbackReducers),
  googleMetrics: createReducer(googleMetrics),
  googleAssets: createReducer(googleAssets),
  images: createReducer(imagesReducers),
  imagesMam: createReducer(imagesMamReducers),
  imagePicker: createReducer(imagePickerReducers),
  marketingChannels: createReducer(marketingChannels),
  modals: createReducer(modalsReducers),
  integrations: createReducer(integrationsReducers),
  loading: createReducer(loadingReducers),
  onboardingBusiness: createReducer(onboardingBusinessReducers),
  reports: createReducer(reportsReducers),
  router: connectRouter(history),
  searchAdCombinations: createReducer(searchAdCombinations),
  smartFeed: createReducer(smartFeedReducers),
  stores: createReducer(storesReducers),
  storesCustomAudiences: createReducer(storesCustomAudiencesReducers),
  suggestedCampaign: createReducer(suggestedCampaign),
  toaster: createReducer(toasterReducers),
  user: createReducer(userReducers),
  urlPresets: createReducer(urlPresetsReducers),
  publishMarketingDialog: createReducer(publishMarketingDialog),
  locations: createReducer(locations),
  comments: createReducer(comments),
});
