import { call, put } from 'redux-saga/effects';

import api from '../../services/api';
import { showToaster, setMarketingChannels, setMarketingChannelsLoading } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* getMarketingChannels({ payload: businessId }) {
  try {
    yield put(setMarketingChannelsLoading(true));
    const marketingChannels = yield call(api.getMarketingChannelsByBusinessId, businessId);
    yield put(setMarketingChannels(marketingChannels));
    yield put(setMarketingChannelsLoading(false));
  } catch (e) {
    yield put(setMarketingChannelsLoading(false));
    logger.error({
      error: e,
      context: { saga: 'getMarketingChannels' },
      params: { businessId },
    });
    yield put(showToaster(getToasterOptions('generic_request_error', 'error')));
  }
}
