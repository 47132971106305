import {
  DATA_LOADING_ASYNC,
  DATA_BACKDROP,
  DATA_LOADING_PERMISSIONS,
  DATA_LOADING_CAMPAIGNS,
} from '../constants';

export default {
  defaultState: {
    async: false,
    backdrop: false,
    permissions: false,
    campaigns: false,
  },
  reducers: {
    [DATA_LOADING_ASYNC]: (state, { payload }) => ({ ...state, async: payload }),
    [DATA_BACKDROP]: (state, { payload }) => ({ ...state, backdrop: payload }),
    [DATA_LOADING_PERMISSIONS]: (state, { payload }) => ({ ...state, permissions: payload }),
    [DATA_LOADING_CAMPAIGNS]: (state, { payload }) => ({ ...state, campaigns: payload }),
  },
  excludeFromReset: true,
};
