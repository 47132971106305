import { call, put } from 'redux-saga/effects';
import { MarketingChannelTypes } from '@targetable/targetable-types/dist/types/marketing-channel/types';
import api from '../../services/api';
import { showToaster } from '../../actions';
import i18n from '../../services/i18n';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* retryCampaign({ payload }) {
  const { campaign, onDone, onError } = payload;

  try {
    yield put(showToaster(getToasterOptions(i18n.t('retrying_campaign'), 'info', null, 20000)));
    if ([MarketingChannelTypes.Google, MarketingChannelTypes.MailChimp].includes(campaign?.type)) {
      // Retry Marketing Ad ?[resetStatus=true]
      yield call(api.publishAd, campaign.id, true);
    } else {
      yield call(api.retryCampaign, campaign.id);
    }
    yield put(showToaster(getToasterOptions(i18n.t('successfully_retry_campaign'), 'success', null, 5000)));
    if (onDone) { onDone(); }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'retryCampaign' },
      params: {
        campaignId: campaign?.id,
      },
    });
    if (onError) { onError(); }
    yield put(showToaster(getToasterOptions(i18n.t('error_retry_campaign', { details: JSON.stringify(e?.response?.data || e) }))));
  }
}
