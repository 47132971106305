import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import FullScreenDialog from '@targetable/targetable-web-framework/lib/react/containers/FullScreenDialog/FullScreenDialog';
import AsyncButton from '@targetable/targetable-web-framework/lib/react/components/AsyncButton/AsyncButton';
import PageContentContainer from '@targetable/targetable-web-framework/lib/react/containers/PageContentContainer/PageContentContainer';
import { getUserFacebookIntegration } from '@targetable/targetable-web-framework/lib/services/user';
import { getToasterOptions } from '../../sagas/helpers';

import { selectShowFacebookAdAccountSelectModal, selectUser } from '../../selectors';
import { adAccountSelect, showToaster } from '../../actions';
import api from '../../services/api';
import logger from '../../services/logger';
import AdAccountManageError from '../AdAccountManageError/AdAccountManageError';
import AdAccountPaymentMethodError from '../AdAccountPaymentMethodError/AdAccountPaymentMethodError';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  text: {
    marginBottom: theme.spacing(1),
  },
  top: {
    flex: 1,
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  button: {
    width: 200,
  },
  errorContainer: {
    marginTop: theme.spacing(2),
  },
}));

const AdAccountSelectModal = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    show,
    store,
    adAccounts,
    businessManagerId,
  } = useSelector(selectShowFacebookAdAccountSelectModal);
  const user = useSelector(selectUser);

  const [adAccountId, setAdAccountId] = useState('');
  const [adAccountData, setAdAccountData] = useState({
    loading: false,
    permissions: true,
    hasPaymentMethod: true,
    valid: true,
  });

  const adAccount = _.find(adAccounts, { id: adAccountId });

  const getAdAccountData = useCallback(async () => {
    setAdAccountData({
      loading: true,
      hasPaymentMethod: true,
      permissions: true,
      valid: true,
    });

    const facebookIntegration = getUserFacebookIntegration(user);
    const accessToken = _.get(facebookIntegration, 'accessToken');
    const userId = _.get(facebookIntegration, 'userId');

    try {
      const permissions = await api.getAdAccountPermissions(
        store.id, businessManagerId, adAccountId, userId, accessToken,
      );

      const permissionList = _.get(permissions, 'permissions', []);
      const hasManagePermission = permissionList.includes('MANAGE');

      if (hasManagePermission) {
        // const paymentMethods = await api.getPaymentMethodsFacebook(
        //   businessManagerId, adAccountId, accessToken,
        // );

        // setAdAccountData({
        //   loading: false,
        //   permissions: true,
        //   hasPaymentMethod: paymentMethods.active,
        //   valid: paymentMethods.active,
        // });

        // return paymentMethods.active;
        setAdAccountData({
          loading: false,
          permissions: true,
          hasPaymentMethod: true,
          valid: true,
        });

        return true;
      }

      setAdAccountData({
        loading: false,
        permissions: false,
        hasPaymentMethod: false,
        valid: false,
      });

      return false;
    } catch (e) {
      logger.error({
        error: e,
        context: { component: 'AdAccountSelectModal' },
        params: { user },
      });
      setAdAccountData({
        loading: false,
        permissions: true,
        hasPaymentMethod: true,
        valid: false,
      });
      return false;
    }
  }, [adAccountId, user, store, businessManagerId]);

  const handleSubmit = useCallback(() => {
    dispatch(adAccountSelect(adAccount));
  }, [dispatch, adAccount]);

  const handleRetry = useCallback(() => {
    getAdAccountData().then((valid) => {
      if (valid) {
        handleSubmit();
      } else {
        dispatch(showToaster(getToasterOptions('error_whoops', 'error')));
      }
    });
  }, [dispatch, getAdAccountData, handleSubmit]);

  const handleCancel = useCallback(() => {
    dispatch(adAccountSelect(null));
  }, [dispatch]);

  useEffect(() => {
    if (!show) {
      setAdAccountId('');
    }
  }, [show]);

  useEffect(() => {
    if (adAccountId && businessManagerId && store) {
      getAdAccountData();
    }
  }, [adAccountId, store, businessManagerId, getAdAccountData]);

  if (!show) {
    return null;
  }

  const { loading } = adAccountData;

  return (
    <FullScreenDialog
      open
      onClose={() => handleCancel()}
      title={t('select_facebook_page')}
      data-cy="AdAccountSelectModal_component"
    >
      <PageContentContainer className={classes.container}>
        <Box className={classes.top}>
          <Typography variant="h2" className={classes.title}>
            {t('ad_account_select_modal_title')}
          </Typography>
          <Typography variant="body1" className={classes.text}>
            {t('ad_account_select_modal_instructions')}
          </Typography>
          <TextField
            select
            variant="outlined"
            size="medium"
            margin="normal"
            label={t('ad_accounts')}
            value={adAccountId}
            onChange={(e) => setAdAccountId(e.target.value)}
            autoComplete="off"
            fullWidth
            autoFocus
            disabled={loading}
            SelectProps={{
              classes: { icon: classes.selectIcon },
            }}
          >
            {
              adAccounts.map((adAccountItem) => (
                <MenuItem
                  key={adAccountItem.id}
                  value={adAccountItem.id}
                >
                  {adAccountItem.name}
                </MenuItem>
              ))
            }
          </TextField>
          {!adAccountData.permissions && (
            <div className={classes.errorContainer}>
              <AdAccountManageError store={store} />
            </div>
          )}
          {adAccountData.permissions && !adAccountData.hasPaymentMethod && (
            <div className={classes.errorContainer}>
              <AdAccountPaymentMethodError adAccountId={adAccountId} />
            </div>
          )}
        </Box>
        <Box className={classes.bottom}>
          <AsyncButton
            className={classes.button}
            variant="outlined"
            onClick={handleCancel}
            data-cy="AdAccountSelectModal_cancel_button"
            size="large"
          >
            {t('cancel')}
          </AsyncButton>
          <AsyncButton
            className={classes.button}
            color="secondary"
            variant="contained"
            onClick={adAccountData.valid ? handleSubmit : handleRetry}
            data-cy="AdAccountSelectModal_connect_button"
            size="large"
            disabled={!adAccount || loading}
          >
            {adAccountData.valid ? t('connect') : t('retry')}
          </AsyncButton>
        </Box>
      </PageContentContainer>
    </FullScreenDialog>
  );
};

export default AdAccountSelectModal;
