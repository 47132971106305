import { DATA_URL_PRESETS, DATA_URL_PRESETS_LOADING } from '../constants';

export default {
  defaultState: {
    presetsLoading: false,
    presets: [],
  },
  reducers: {
    [DATA_URL_PRESETS_LOADING]: (state, { payload }) => ({ ...state, presetsLoading: payload }),
    [DATA_URL_PRESETS]: (state, { payload }) => ({ ...state, presets: payload }),
  },
};
