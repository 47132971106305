import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Dinero from 'dinero.js';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import AsyncButton from '@targetable/targetable-web-framework/lib/react/components/AsyncButton/AsyncButton';

import { STRIPE_DEFAULT_PRICE } from '../../../../constants';
import i18n from '../../../../services/i18n';
import { billingModalStripeSubscribe } from '../../../../actions';
import { selectSubscription } from '../../../../selectors';

const styles = (theme) => ({
  button: {
    width: '192px',
  },
  container: {
    padding: '0 16px',
    textAlign: 'center',
  },
  header: {
    margin: theme.spacing(5, 0, 4),
  },
  text: {
    margin: theme.spacing(0, 0, 4),
  },
  subText: {
    margin: theme.spacing(6, 0),
  },
});

const StripeIntro = ({
  classes,
  billingModalStripeSubscribeAction,
  discountAmount,
  discountPercentage,
  periodAmount,
  quantity,
}) => (
  <div className={classes.container}>
    <Typography variant="h2" className={classes.header}>
      {i18n.t('stripe_intro_header')}
    </Typography>
    <Typography variant="subtitle1" className={classes.text}>
      {i18n.t('stripe_intro_text',
        {
          amount: Dinero({ amount: periodAmount * quantity, precision: 2 }).toFormat(),
        })}
    </Typography>
    <AsyncButton
      className={classes.button}
      variant="contained"
      color="secondary"
      onClick={() => billingModalStripeSubscribeAction(true)}
      data-cy="cta-continue_targetable_subscription_required"
    >
      {i18n.t('subscribe')}
    </AsyncButton>
    {discountAmount ? (
      <Typography variant="subtitle1" className={classes.subText}>
        {i18n.t('stripe_discount_amount_text',
          {
            discount: Dinero({ amount: discountAmount, precision: 2 }).toFormat(),
          })}
      </Typography>
    ) : null}
    {discountPercentage ? (
      <Typography variant="subtitle1" className={classes.subText}>
        {i18n.t('stripe_discount_percentage_text',
          {
            discount: discountPercentage,
          })}
      </Typography>
    ) : null}
  </div>
);

StripeIntro.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string,
    container: PropTypes.string,
    header: PropTypes.string,
    subText: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
  billingModalStripeSubscribeAction: PropTypes.func.isRequired,
  discountAmount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  discountPercentage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  periodAmount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  quantity: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  const subscription = selectSubscription(state);
  return {
    discountAmount: _.get(subscription, 'discountAmount', 0),
    discountPercentage: _.get(subscription, 'discountPercentage', 0),
    periodAmount: _.get(subscription, 'periodAmount', STRIPE_DEFAULT_PRICE),
    quantity: _.get(subscription, 'quantity', 1),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  billingModalStripeSubscribeAction: billingModalStripeSubscribe,
}, dispatch);

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withStyles(styles)(StripeIntro));
