import globals from './globals';

export const getRootRoute = () => '/';
export const getHomeRoute = (businessId = ':businessId') => `/businesses/${businessId}/`;

export const getAddUserRoute = () => '/users/add';

export const getBusinessesRoute = () => '/businesses';

export const getBusinessProfileRoute = (businessId = ':businessId', tab) => `/businesses/${businessId}/profile${tab ? `?tab=${tab}` : ''}`;

export const getInviteRoute = (id = ':id') => `/invite/${id}`;
export const getInviteCodeInvalidRoute = (auth0Id, userId, errorType) => `/invite/invalid/code${auth0Id ? `?auth0_id=&${auth0Id || ''}&userId=${userId || ''}&error_type=${errorType || ''}` : ''}`;

export const getLoginRoute = () => '/login';
export const getOnboardingLoginRoute = () => '/onboard';
export const getOnboardingRegistrationRoute = () => '/onboard/registration';
export const getOnboardingStoreRegistrationRoute = () => '/onboard/store';
export const getOnboardingMetaRegistrationActionRoute = (action = ':action') => `/onboard/meta/${action}`;
export const getOnboardingMetaRegistrationRoute = () => '/onboard/meta';
export const getOnboardingAccessRoute = () => '/onboard/access';
export const getOnboardingAdAccountRoute = () => '/onboard/adaccount';
export const getOnboardingRejectedBusinessAdminRoute = () => '/onboard/rejected/businessadmin';
export const getSignUpSuccessRoute = () => '/success';

export const getMultiStoreAdAccountRoute = () => '/multistore/adaccount';
export const getMultiStoreBusinessRoute = () => '/multistore/business';
export const getMultiStoreRejectedLocationRoute = () => '/multistore/rejected/location';
export const getMultiStoreRejectedNoMoreLocationsRoute = () => '/multistore/rejected/locations';

// export const getCampaignsRoute = (tab) => `/campaigns${tab ? `?tab=${tab}` : ''}`;
export const getCampaignsRoute = (businessId = ':businessId', tab) => `/businesses/${businessId}/campaigns${tab ? `?tab=${tab}` : ''}`;

export const getCampaignWizardDetailRoute = (businessId = ':businessId', id = ':id', tab) => `/businesses/${businessId}/campaigns/wizard/${id}/details${tab ? `?tab=${tab}` : ''}`;
export const getCampaignEditRoute = (id = ':id') => `/campaigns/edit/${id}`;
export const getCampaignCreateRoute = () => '/campaigns/create';

export const getMediaLibraryRoute = (businessId = ':businessId') => `/businesses/${businessId}/media`;

export const getLoadingRoute = () => '/loading';

export const getRejectedRoute = () => '/rejected';

export const getSettingsRoute = (businessId = ':businessId', tab) => `/businesses/${businessId}/settings${tab ? `?tab=${tab}` : ''}`;

export const getCallbackRoute = () => '/callback';

export const getGlobalErrorRoute = () => '/error';

export const getReactivateAccountRoute = () => '/reactivate';

export const getSuggestedCampaignDetailRoute = (businessId = ':businessId', id = ':id') => `/businesses/${businessId}/campaigns/suggested/${id}/detail`;

export const getVerifyEmailRoute = () => '/verify/email';

export const getManagedSocialMediaRoute = (businessId = ':businessId') => `/businesses/${businessId}/managedsocialmedia`;
export const getDiscoverabilityRoute = () => '/discoverability';
export const getInvoiceHistoryRoute = (businessId = ':businessId') => `/businesses/${businessId}/invoice/history`;

// Admin Portal Routes
export const getBusinessAdminUrl = (businessId) => `${globals.ADMIN_BASE_URL}/businesses/${encodeURIComponent(businessId)}`;

export const getManagedLandingPagesRoute = (businessId = ':businessId') => `/businesses/${businessId}/managedlandingpages`;
export const getManagedEmailMarketingRoute = (businessId = ':businessId', tab) => `/businesses/${businessId}/managedemailmarketing${tab ? `?tab=${tab}` : ''}`;
export const getChowlyRestaurantControlCenter = (businessId = ':businessId') => `/businesses/${businessId}/chowly-restaurant-control-center`;

export const getGoogleCampaignDetailRoute = (businessId = ':businessId', id = ':id', tab) => `/businesses/${businessId}/campaigns/google/${id}/details${tab ? `?tab=${tab}` : ''}`;
export const getMEMCampaignDetailRoute = (businessId = ':businessId', id = ':id') => `/businesses/${businessId}/campaigns/mem/${id}/details`;
export const getCampaignDetailRoute = (businessId = ':businessId', campaignId = ':campaignId') => `/businesses/${businessId}/campaigns/${campaignId}/detail`;
export const getSuggestedDetailRoute = (businessId = ':businessId', campaignId = ':campaignId') => `${globals.ADMIN_BASE_URL}/businesses/${businessId}/campaigns/suggested/${campaignId}/detail`;

export const getInsightRoute = (businessId = ':businessId', insightId = ':insightId') => `/businesses/${businessId}/insight/${insightId}/details`;

export const getPartnerFeedRoute = (businessId = ':businessId') => `/businesses/${businessId}/partnerfeed`;
