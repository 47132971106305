import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Toaster from '@targetable/targetable-web-framework/lib/react/components/Toaster/Toaster';

import { selectToaster } from '../../selectors';
import { closeToaster } from '../../actions';

const mapStateToProps = (state) => selectToaster(state);

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onClose: closeToaster,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Toaster);
