import { call, put } from 'redux-saga/effects';

import api from '../../services/api';
import { showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* updateActivityLog({ payload }) {
  try {
    const activityLog = yield call(api.updateActivityLog, payload.id, payload);
    return activityLog;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'updateActivityLog' },
      params: { activityLogId: payload.id, payload },
    });
    yield put(showToaster(getToasterOptions('generic_request_error', 'error')));
    return undefined;
  }
}
