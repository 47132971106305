import _ from 'lodash';
import { call, select, put } from 'redux-saga/effects';

import { getPaidAdProductInfo } from '@targetable/targetable-web-framework/lib/services/paywallUtils';
import getStripeSubscription from '../getStripeSubscription/getStripeSubscription';
import { selectStores } from '../../selectors';
import api from '../../services/api';
import { setSubscription } from '../../actions';
import handleEntryAnalytics from '../handleEntryAnalytics/handleEntryAnalytics';

export default function* handleEntrySubscription(business, user) {
  const subscription = yield call(getStripeSubscription);
  const paidAdProductInfo = getPaidAdProductInfo(subscription);

  const subscriptionStatus = paidAdProductInfo?.status;
  const scheduledForCancel = paidAdProductInfo?.scheduledForCancel === true;
  const hasSubscription = _.isNil(paidAdProductInfo?.subscriptionId) === false;

  const isActive = subscriptionStatus === 'active';
  const isTrial = subscriptionStatus === 'trialing';
  const anyActiveStatuses = isActive || isTrial;

  yield call(handleEntryAnalytics, business, user, subscription);

  if ((anyActiveStatuses === false && scheduledForCancel === false)) {
    if (hasSubscription === false) {
      const stores = yield select(selectStores);
      const price = yield call(api.getStripePrice, 'default', { quantity: stores.length || 1, businessId: business?.id });
      yield put(setSubscription({ periodAmount: _.get(price, 'periodAmount'), quantity: stores.length || 1 }));
    }
  }
}
