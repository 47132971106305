import { call } from 'redux-saga/effects';
import api from '../../services/api';
import logger from '../../services/logger';

export default function* updateUserHubspotRecord(userId, data) {
  try {
    yield call(api.updateUserHubspotRecord, userId, data);
  } catch (err) {
    logger.error({
      error: err,
      context: { saga: 'updateUserHubspotRecord' },
      params: { userId, data },
    });
  }
}
