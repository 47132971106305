import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import auth from '../../services/auth';

// this has side affects, so should not be pure
// eslint-disable-next-line react/prefer-stateless-function
class SecureRoute extends Component {
  render() {
    const { authenticated, path, directNavigation } = this.props;

    if (authenticated()) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return (<Route {...this.props} />);
    }

    auth.login(directNavigation ? { originalTarget: path } : {});
    return null;
  }
}

SecureRoute.defaultProps = {
  directNavigation: false,
  authenticated: () => auth.isAuthenticated(),
};

SecureRoute.propTypes = {
  authenticated: PropTypes.func,
  path: PropTypes.string.isRequired,
  /**
   * Indicates the route can be accessed directly via url.
   * Returning users will be automatically redirected to the route
   * after a successful authentication.
   */
  directNavigation: PropTypes.bool,
};

export default SecureRoute;
