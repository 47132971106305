import moment from 'moment';
import { call, fork, put } from 'redux-saga/effects';

// import api from '../../services/api';
import history from '../../services/history';
import logger from '../../services/logger';

import getBusiness from '../getBusiness/getBusiness';
import getStoresByBusiness from '../getStoresByBusiness/getStoresByBusiness';

import {
  getHomeRoute,
  getInviteCodeInvalidRoute,
  getRejectedRoute,
} from '../../routes';
import { setLoadingAsync } from '../../actions';
import api from '../../services/api';
import handleEntrySubscription from '../handleEntrySubscription/handleEntrySubscription';
import updateUser from '../updateUser/updateUser';
import handleUserRegistration from '../handleUserRegistration/handleUserRegistration';
import getActiveUserAndAllUsers from '../getActiveUserAndAllUsers/getActiveUserAndAllUsers';
import localStorage from '../../services/localStorage';

const getErrorType = (invitedUser, auth0Id, inviteCode) => {
  if (!invitedUser || !invitedUser?.id) {
    return 'invite_link_invalid_user';
  }
  if (!invitedUser?.businessId) {
    return 'invite_link_missed_business';
  }
  if (invitedUser?.auth0Id !== auth0Id) {
    return 'invite_link_already_used';
  }
  if (inviteCode !== invitedUser?.inviteCode) {
    return 'invite_link_invalid_invite_code';
  }
  return 'invite_link_invalid';
};

export default function* handleInvitedUser(auth0Id, inviteCode, inviteUserId) {
  try {
    const invitedUser = yield call(api.updateInvite, inviteUserId, auth0Id);
    if (!invitedUser?.id
      || inviteCode !== invitedUser?.inviteCode
      || !invitedUser?.businessId
      || invitedUser.auth0Id !== auth0Id) {
      history.push(getInviteCodeInvalidRoute(
        auth0Id,
        inviteUserId,
        getErrorType(invitedUser, auth0Id, inviteCode, inviteUserId),
      ));
      return;
    }

    yield call(handleUserRegistration, invitedUser, null, true);

    yield put(setLoadingAsync(true));
    yield fork(updateUser, {
      id: inviteUserId,
      onboarded: true,
      lastLogin: moment.utc().valueOf(),
    });

    const business = yield call(getBusiness, invitedUser?.businessId);
    yield call(getStoresByBusiness, invitedUser?.businessId);
    yield call(handleEntrySubscription, business, invitedUser);

    localStorage.setItem(`${auth0Id}:last_viewed_user_id`, inviteUserId);
    yield call(getActiveUserAndAllUsers);

    try {
      yield call(api.associateUserHubspotRecordToBusinessHubspotRecord, invitedUser?.id);
    } catch (e) {
      logger.error({
        error: `Failed to associate invited user ${invitedUser?.id} with business ${invitedUser?.businessId}`,
        context: { saga: 'handleInvitedUser' },
        params: { auth0Id, inviteCode, inviteUserId },
      });
    }

    yield put(setLoadingAsync(false));

    history.push(getHomeRoute(business?.id));
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'handleInvitedUser' },
      params: { auth0Id, inviteCode, inviteUserId },
    });
    history.push(getRejectedRoute());
  }
}
