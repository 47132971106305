import React from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const ModalWrapper = ({ modal: Modal, selector }) => {
  const open = useSelector(selector);
  if (!open) return null;
  return (
    <Modal />
  );
};

ModalWrapper.propTypes = {
  modal: PropTypes.func.isRequired,
  selector: PropTypes.func.isRequired,
};

export default ModalWrapper;
