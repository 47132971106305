import { DATA_IMAGES_MAM, SEARCH_IMAGES, DATA_IS_SEARCHING_MAM } from '../constants';

export default {
  defaultState: {
    isSearching: false,
    imageList: [],
    searchTerm: null,
  },
  reducers: {
    [SEARCH_IMAGES]: (state, { payload }) => ({ ...state, searchTerm: payload }),
    [DATA_IS_SEARCHING_MAM]: (state) => ({ ...state, isSearching: true }),
    [DATA_IMAGES_MAM]: (state, { payload }) => ({
      ...state,
      isSearching: false,
      imageList: payload,
    }),
  },
};
