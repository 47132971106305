import { take, put } from 'redux-saga/effects';

import { setActivityLogNoteDialog } from '../../actions';
import { RESULT_ACTIVITY_LOG_DIALOG } from '../../constants';

export default function* showActivityLogNoteDialog({ payload }) {
  const {
    cancelText,
    cancelDataCy,
    confirmText,
    confirmDataCy,
    title,
    text,
    textParams,
    onClick,
  } = payload;

  yield put(setActivityLogNoteDialog({
    isOpen: true,
    cancelText,
    cancelDataCy,
    confirmText,
    confirmDataCy,
    title,
    text,
    textParams,
  }));

  const info = yield take(RESULT_ACTIVITY_LOG_DIALOG);
  yield put(setActivityLogNoteDialog({
    isOpen: false,
  }));
  onClick(info);
}
