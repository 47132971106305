import { call, put } from 'redux-saga/effects';
import api from '../../services/api';
import { setBellNotifications } from '../../actions';
import logger from '../../services/logger';

export default function* getAllActivityLogsByBusiness(businessId) {
  try {
    const activityLogs = yield call(api.getAllActivityLogs, { businessId });
    yield put(setBellNotifications(activityLogs));
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getAllActivityLogsByBusiness' },
      params: { businessId },
    });
  }
}
