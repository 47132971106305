import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import i18n from '../../services/i18n';
import TargetableLogo from '../../components/Images/TargetableLogo/TargetableLogo';

const styles = {
  page: {
    backgroundColor: '#ffffff',
    color: '#1d173b',
    height: '100vh',
    padding: '21px',
    margin: '0 auto',
    width: '100%',
    maxWidth: '600px',
  },
  logo: {
    margin: '1rem auto 2rem',
    display: 'block',
  },
  headline: {
    marginBottom: '2rem',
  },
  body: {
    marginTop: '1rem',
  },
  hbstCtn: {
    marginTop: '40px',
  },
};

const GlobalError = ({ classes }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/v2.js';
    // Check if navigator is IE <= 8
    let versionNumber = false;
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older, return version number
      versionNumber = parseInt(
        ua.substring(
          msie + 5, ua.indexOf('.', msie),
        ), 10,
      );
    }
    if (versionNumber && versionNumber <= 8) { // lte IE 8
      script.src = '//js.hsforms.net/forms/v2-legacy.js';
    }
    document.head.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '5437706',
          formId: '3f24dae6-1b39-4996-a8d0-a8610f48f43e',
          target: '#hbst-ctn',
        });
      }
    });
  }, []);

  return (
    <div className={classes.page}>
      <TargetableLogo
        height={70}
        width={70}
        className={classes.logo}
      />
      <Typography className={classes.headline} variant="h2">
        {i18n.t('error_page_message_headline')}
      </Typography>
      <Typography className={classes.body} variant="body1">
        {i18n.t('error_page_message_body')}
      </Typography>
      <div id="hbst-ctn" className={classes.hbstCtn} />
    </div>
  );
};

GlobalError.propTypes = {
  classes: PropTypes.shape({
    body: PropTypes.string,
    headline: PropTypes.string,
    logo: PropTypes.string,
    page: PropTypes.string,
    hbstCtn: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(GlobalError);
