import {
  SHOW_ADD_BUSINESS_MODAL,
  SHOW_ADD_BUSINESS_CONFIRM_MODAL,
  SHOW_ADD_STORE_MODAL,
  SHOW_FACEBOOK_ANTI_SCUM_MODAL,
  SHOW_FACEBOOK_CONNECT_AD_ACCOUNT_MODAL,
  SHOW_FACEBOOK_CONNECT_BUSINESS_MANAGER_MODAL,
  SHOW_FACEBOOK_PAGE_SELECT_MODAL,
  SHOW_FACEBOOK_PERMISSIONS_MODAL,
  SHOW_FACEBOOK_AD_ACCOUNT_SELECT_MODAL,
  SHOW_ACCOUNT_ISSUES_MODAL,
  SHOW_EDIT_BUSINESS_NAME_MODAL,
  SHOW_EDIT_STORE_NAME_MODAL,
} from '../constants';

export default {
  defaultState: {
    showAddBusiness: { show: false },
    showAddBusinessConfirm: false,
    showAddStore: { show: false },
    showFacebookAntiScum: false,
    showFacebookConnectAdAccount: { show: false, store: null },
    showFacebookConnectBusinessManager: { show: false, store: null },
    showFacebookPageSelect: { show: false, retry: false, store: null },
    showFacebookPermissions: false,
    showFacebookAdAccountSelect: {
      show: false,
      adAccounts: [],
      store: null,
      businessManagerId: null,
    },
    showEditBusinessNameModal: false,
    showEditStoreNameModal: {
      show: false,
      store: null,
    },
    showAccountIssuesModal: false,
  },
  reducers: {
    [SHOW_ADD_BUSINESS_MODAL]: (state, { payload }) => ({
      ...state,
      showAddBusiness: { show: payload },
    }),
    [SHOW_ADD_BUSINESS_CONFIRM_MODAL]: (state, { payload }) => ({
      ...state,
      showAddBusinessConfirm: payload,
    }),
    [SHOW_ADD_STORE_MODAL]: (state, { payload }) => ({
      ...state,
      showAddStore: { show: payload },
    }),
    [SHOW_FACEBOOK_ANTI_SCUM_MODAL]: (state, { payload }) => ({
      ...state,
      showFacebookAntiScum: payload,
    }),
    [SHOW_FACEBOOK_CONNECT_AD_ACCOUNT_MODAL]: (state, { payload }) => ({
      ...state,
      showFacebookConnectAdAccount: payload,
    }),
    [SHOW_FACEBOOK_CONNECT_BUSINESS_MANAGER_MODAL]: (state, { payload }) => ({
      ...state,
      showFacebookConnectBusinessManager: payload,
    }),
    [SHOW_FACEBOOK_PAGE_SELECT_MODAL]: (state, { payload }) => ({
      ...state,
      showFacebookPageSelect: payload,
    }),
    [SHOW_FACEBOOK_PERMISSIONS_MODAL]: (state, { payload }) => ({
      ...state,
      showFacebookPermissions: payload,
    }),
    [SHOW_FACEBOOK_AD_ACCOUNT_SELECT_MODAL]: (state, { payload }) => ({
      ...state,
      showFacebookAdAccountSelect: payload,
    }),
    [SHOW_EDIT_BUSINESS_NAME_MODAL]: (state, { payload }) => ({
      ...state,
      showEditBusinessNameModal: payload,
    }),
    [SHOW_EDIT_STORE_NAME_MODAL]: (state, { payload }) => ({
      ...state,
      showEditStoreNameModal: payload,
    }),
    [SHOW_ACCOUNT_ISSUES_MODAL]: (state, { payload }) => ({
      ...state,
      showAccountIssuesModal: payload,
    }),
  },
  excludeFromReset: true,
};
