import {
  call, put, select, all,
} from 'redux-saga/effects';

import _ from 'lodash';
import api from '../../services/api';
import { selectBusiness, selectSubscription } from '../../selectors';
import { showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import getStripeSubscription from '../getStripeSubscription/getStripeSubscription';

export default function* cancelStripeSubscription(payload) {
  const {
    onCompleted, onError,
  } = payload;
  const business = yield select(selectBusiness);
  const subscription = yield select(selectSubscription);
  const activeSubscriptions = _.uniqBy(_.filter(subscription?.stripeProducts, (p) => p.status === 'active'), 'subscriptionId');

  try {
    const calls = [];
    _.forEach(activeSubscriptions, ({ subscriptionId }) => {
      calls.push(call(
        api.updateStripeSubscription,
        business?.stripeCustomerId,
        subscriptionId,
        {
          cancel_at_period_end: true,
          businessId: _.get(business, 'id'),
        },
      ));
    });
    yield all(calls);

    // reload subscriptions info
    yield call(getStripeSubscription, { payload: business?.stripeCustomerId });
    yield put(showToaster(getToasterOptions('Business subscription has been canceled.', 'success', null, 3000)));
    if (onCompleted) { onCompleted(); }
  } catch (ex) {
    logger.error({
      error: ex,
      context: { saga: 'cancelStripeSubscriptionSaga' },
      params: { business, subscription },
    });
    yield put(showToaster(getToasterOptions('error_cancel_subscription')));
    if (onError) { onError(); }
  }
}
