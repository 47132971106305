import _ from 'lodash';
import { call, select } from 'redux-saga/effects';

import { getUserFacebookIntegration } from '@targetable/targetable-web-framework/lib/services/user';

import { selectUser } from '../../selectors';
import validateFacebookConnection from '../validateFacebookConnection/validateFacebookConnection';
import connectFacebookAccount from '../connectFacebookAccount/connectFacebookAccount';

export default function* ensureFacebookConnection(...args) {
  const action = _.last(args || []);
  const onDone = _.get(action, 'payload.onDone');

  const user = yield select(selectUser);
  const facebookIntegration = getUserFacebookIntegration(user);
  const tokenValid = yield call(validateFacebookConnection);

  // logger.error({
  //   error: 'Ensure fb token on publish',
  //   context: { saga: 'ensureFacebookConnection', severity: 'debug' },
  //   params: { user, tokenValid, hasToken: !!_.get(facebookIntegration, 'accessToken') },
  // });

  // connect to facebook if not connected yet or invalid
  if (!_.get(facebookIntegration, 'accessToken') || !tokenValid) {
    const connected = yield call(connectFacebookAccount, { payload: { showToast: false } });

    if (connected) {
      if (args.length && typeof args[0] === 'function') {
        yield call(...args);
        return true;
      }
    } else {
      if (onDone) onDone();
      return false;
    }
  }

  if (args.length && typeof args[0] === 'function') {
    yield call(...args);
  }

  return true;
}
