import {
  call,
  put,
  select,
  take,
} from 'redux-saga/effects';
import _ from 'lodash';

import {
  showFeedbackModal,
  showToaster,
  setConfirmationDialog,
  setLoadingAsync,
  showConfirmationDialog,
  openSuggestedCampaignDetail,
} from '../../actions';
import { selectRouterLocationPath } from '../../selectors';
import { getToasterOptions } from '../helpers';
import ensureFacebookConnection from '../ensureFacebookConnection/ensureFacebookConnection';

import api from '../../services/api';
import {
  RESULT_CONFIRMATION_DIALOG,
  SMARTFEED_ACTION_APPROVED,
  SMARTFEED_TYPE_SUGGESTED_CAMPAIGN,
  SMARTFEED_TYPE_SUGGESTED_CAMPAIGN_CARD,
} from '../../constants';
import validateCampaignUrlPresets from '../validateCampaignUrlPresets/validateCampaignUrlPresets';

// this is only here so the subscription can be managed
// via sagas in rootSaga like all the others
export default function* handleFeedbackDialog(reduxAction) {
  const { payload } = reduxAction;
  const {
    action, campaign, item, type, onError,
  } = payload;

  if (action === SMARTFEED_ACTION_APPROVED
    && (type === SMARTFEED_TYPE_SUGGESTED_CAMPAIGN
      || type === SMARTFEED_TYPE_SUGGESTED_CAMPAIGN_CARD)) {
    const isConnected = yield call(ensureFacebookConnection);
    if (!isConnected) {
      return;
    }

    // validate url presets but not if the URL is live
    // TODO: find the way for removing this validation from here
    // and live it only in the submitCampaign saga
    const presetsValid = yield call(validateCampaignUrlPresets, campaign.businessId, campaign);
    const campaignCTA = _.get(campaign, 'ads.0.ad.callToAction');
    if (!presetsValid.valid && campaignCTA !== 'CALL_NOW') {
      if (presetsValid.error === 'invalid_preset') {
        yield put(setLoadingAsync(false));
        const pathname = yield select(selectRouterLocationPath);
        const inFormView = pathname.includes('campaigns/suggested');

        // When backDrop is clicked, it should returns the 'cancel' string
        // in the RESULT_CONFIRMATION_DIALOG
        yield put(setConfirmationDialog({
          resultOnClose: 'cancel',
        }));

        yield put(showConfirmationDialog({
          text: 'url_preset_publish_missing_error',
          confirmText: 'edit',
          cancelText: 'continue',
          onClick: _.identity,
        }));

        // true is edit, false is continue
        const { payload: confirm } = yield take(RESULT_CONFIRMATION_DIALOG);

        // Cancel the publishing
        if (confirm === 'cancel') return;

        yield put(setLoadingAsync(true));

        if (confirm) {
          if (!inFormView) {
            yield put(openSuggestedCampaignDetail({
              suggestedCampaign: item,
              locationHasChanged: false,
            }));
            return;
          }

          if (onError) {
            onError('url');
          }

          return;
        }
      } else {
        if (onError) {
          onError('url');
        }

        if (presetsValid.error === 'invalid_url' && type === SMARTFEED_TYPE_SUGGESTED_CAMPAIGN_CARD) {
          yield put(
            showToaster(getToasterOptions('campaign_invalid_url_error', 'error', null, 10000, true, {
              href: 'https://www.targetable.com/support',
              title: 'contact_support',
            })),
          );
        }
        return;
      }
    }
  }

  if (item.questionnaireId) {
    // Fetch actual questions
    const questionnaireInfo = yield call(api.getQuestionnaireById, item.questionnaireId);
    item.questionnaire = questionnaireInfo;
  }

  // Avoid second validation on submitCampaign
  yield put(showFeedbackModal({ ...payload, validatedCampaign: true }));
}
