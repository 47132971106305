import { call, put } from 'redux-saga/effects';
import _ from 'lodash';
import * as presetService from '@targetable/targetable-web-framework/lib/services/urlPresets';

import api from '../../services/api';

import { setUrlPresets, setUrlPresetsLoading, showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* getUrlPresets({ payload }) {
  const {
    businessId, storeId, filter, backfill, onError,
  } = payload;

  yield put(setUrlPresetsLoading(true));
  yield put(setUrlPresets([]));

  let stores;
  try {
    // refresh stores
    stores = yield call(api.getStores, businessId);
    stores = _.sortBy(stores, 'name');
  } catch (e) {
    if (onError) onError();
    yield put(showToaster(getToasterOptions('Failed getting latest store data. Try again in a few minutes.', 'error', null, 5000)));
    yield put(setUrlPresetsLoading(false));
    logger.error({
      error: e,
      context: { saga: 'getUrlPresets' },
      params: { businessId },
    });
    return false;
  }

  try {
    let presets = yield call(api.getUrlPresets, businessId);

    // filter out removed presets
    if (filter) {
      presets = presetService.filterRemoved(presets);
    }

    // map latest store name and additional meta for ui
    presets = presetService.updateUIMeta(presets, stores);

    // backfill any missing stores
    if (backfill) {
      presets = presetService.backfillMissingStores(presets, stores);
    }

    // filter out stores
    if (storeId) {
      presets = presetService.filterByStore(presets, storeId);
    }

    // convert stores object to array
    presets = presetService.convertStoresObjectToArray(presets);

    yield put(setUrlPresets(presets));
    yield put(setUrlPresetsLoading(false));
  } catch (e) {
    if (onError) onError();
    yield put(showToaster(getToasterOptions('Failed getting URL preset data. Try again in a few minutes.', 'error', null, 5000)));
    yield put(setUrlPresetsLoading(false));

    logger.error({
      error: e,
      context: { saga: 'getUrlPresets' },
      params: {
        businessId, storeId, filter, backfill,
      },
    });

    return false;
  }

  return true;
}
