import { call, put } from 'redux-saga/effects';
import _ from 'lodash';
import logger from '../../services/logger';

import createBusiness from '../createBusiness/createBusiness';
import updateBusiness from '../updateBusiness/updateBusiness';
import { showToaster } from '../../actions';
import { getToasterOptions } from '../helpers';

export default function* upsertBusiness({ id, name, stripeCoupon }) {
  // let actionType;
  let businessId = id;
  let business;

  try {
    if (_.isNil(businessId)) {
      // actionType = 'create';
      business = yield call(createBusiness, name, stripeCoupon);
      businessId = _.get(business, 'id');
    } else {
      // actionType = 'update';
      business = yield call(updateBusiness, { payload: { id, name, stripeCoupon } });
    }

    // create and update already handle themselves
    // having an error, so just return
    if (_.isNil(business)) {
      return undefined;
    }

    return business;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'upsertBusiness' },
      params: {
        businessId, business, name, stripeCoupon,
      },
    });
    yield put(showToaster(getToasterOptions('generic_request_error', 'error')));
    return undefined;
  }
}
