import _ from 'lodash';
import { call, put } from 'redux-saga/effects';

import { showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import api from '../../services/api';
import getStoresByBusiness from '../getStoresByBusiness/getStoresByBusiness';
import globals from '../../globals';

export default function* createStore(businessId, name) {
  const storeData = {
    businessId,
    name,
  };

  // We only set internalTraffic to true for dev and stage environments
  const { ENVIRONMENT_NAME } = globals;
  if (_.includes(['stage', 'dev', 'local'], ENVIRONMENT_NAME)) {
    _.set(storeData, 'internalTraffic', true);
  }

  _.set(storeData, 'defaultRadius', 5);
  _.set(storeData, 'defaultRadiusEmployment', 15);
  _.set(storeData, 'defaultRadiusHousing', 15);

  try {
    const store = yield call(api.createStore, storeData);

    yield call(getStoresByBusiness, businessId);

    return store;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'createStore' },
      params: { storeData },
    });
    yield put(showToaster(getToasterOptions('generic_request_error', 'error')));
    return undefined;
  }
}
