import { call, put } from 'redux-saga/effects';

import api from '../../services/api';
import history from '../../services/history';
import {
  clearCampaignWizard,
  setCampaign,
  showToaster,
} from '../../actions';
import { getCampaignsRoute } from '../../routes';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* deleteCampaignSaga({ payload }) {
  try {
    const { campaignId, onDone } = payload;
    yield call(api.deleteCampaign, campaignId);
    history.push(getCampaignsRoute());
    yield put(setCampaign(null));
    yield put(clearCampaignWizard());
    if (onDone) {
      onDone();
    }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'deleteCampaignSaga' },
      params: { campaignId: payload.campaignId },
    });
    yield put(showToaster(getToasterOptions('error_delete_campaign')));
  }
}
