import { call } from 'redux-saga/effects';
import api from '../../services/api';
import logger from '../../services/logger';

export default function* createActivityLog(data) {
  const payload = data.payload || data;
  try {
    yield call(
      api.createActivityLog,
      payload,
    );
  } catch (ex) {
    logger.error({
      error: ex,
      context: { saga: 'createActivityLog' },
      params: { payload },
    });
  }
}
