import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  text: {
    marginBottom: theme.spacing(2),
  },
}));

const AdAccountPaymentMethodError = ({ adAccountId }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleAddPayment = () => {
    FB.ui({ // eslint-disable-line no-undef
      account_id: adAccountId,
      display: 'popup',
      method: 'ads_payment',
    });
  };

  return (
    <>
      <Typography variant="subtitle1" className={classes.title}>
        {t('ad_account_manage_explanation_header')}
      </Typography>
      <Typography variant="body1" className={classes.text}>
        {t('ad_account_manage_payment_explanation_text')}
      </Typography>
      <Button onClick={handleAddPayment} variant="text" color="secondary">
        {t('add_facebook_payment_method')}
      </Button>
    </>
  );
};

AdAccountPaymentMethodError.propTypes = {
  adAccountId: PropTypes.string.isRequired,
};

export default AdAccountPaymentMethodError;
