import _ from 'lodash';
import { call, put } from 'redux-saga/effects';

import auth from '../../services/auth';
import api from '../../services/api';
import localStorage from '../../services/localStorage';
import colors from '../../services/colors';
import history from '../../services/history';
import { setUserActive, setUserAllUsers } from '../../actions';
import { getRejectedRoute } from '../../routes';
import logger from '../../services/logger';

export default function* getActiveUserAndAllUsers() {
  try {
    const auth0Id = auth.getUserId();

    if (_.isNil(auth0Id) || _.isEmpty(auth0Id)) {
      throw new Error('User not found');
    }

    const lastUserId = localStorage.getItem(`${auth0Id}:last_viewed_user_id`);
    // sending the lastUserId to the endpoint
    // when this parameter is sent will update the lastLogin property
    const retValue = yield call(api.getAuth0Users, auth0Id, null, lastUserId);

    let users = _.get(retValue, 'users') || [];
    let cursor = _.get(retValue, 'cursor');
    while (cursor) {
      const updatedUsers = yield call(api.getAuth0Users, auth0Id, cursor);
      users = users.concat(_.get(updatedUsers, 'users'));
      cursor = _.get(updatedUsers, 'cursor');
    }

    let user = {};
    if (_.isNil(lastUserId) === false) {
      try {
        // user = yield call(api.getUser, lastUserId);
        // instead of calling the api I search users array with the lastUserId
        user = _.find(users, (u) => u?.id === lastUserId);
      } catch (e) {
        // this is intentionally empty. If something goes wrong
        // getting the user they had persisted in local storage,
        // such as the user was deleted, or something like that,
        // fall back to getting the first user in the list
        user = null;
      }
    }

    users = _.sortBy(users, [(u) => _.toUpper(_.get(u, 'businessName'))]);
    const firstUserId = _.get(users, '0.id');
    if (_.isNil(user) || _.isEmpty(user)) {
      if (_.isNil(firstUserId) === false) {
        user = _.find(users, (u) => u?.id === firstUserId);
        localStorage.setItem(`${_.get(user, 'auth0Id')}:last_viewed_user_id`, firstUserId);
      } else {
        user = {};
      }
    }

    if (_.size(users)) {
      const avatarColors = colors.getAvatarColors(users.length);
      users = _.map(users, (userWithoutBackground, index) => ({
        ...userWithoutBackground,
        backgroundColor: avatarColors[index],
      }));
    }

    yield put(setUserActive(user));
    yield put(setUserAllUsers(users));
    return user;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getActiveUserAndAllUsers' },
    });
    history.push(getRejectedRoute());
    return undefined;
  }
}
