import {
  call,
  put,
} from 'redux-saga/effects';

import { showToaster, showEditStoreNameModal } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions, takeAsyncAction } from '../helpers';
import updateStore from '../updateStore/updateStore';
import { CONFIRM_EDIT_STORE_NAME } from '../../constants';

export default function* handleEditStoreName({ payload }) {
  const {
    store,
    onCompleted,
  } = payload;
  yield put(showEditStoreNameModal({ show: true, store }));
  const { payload: newName } = yield call(takeAsyncAction, CONFIRM_EDIT_STORE_NAME);

  if (newName) {
    try {
      yield call(updateStore, {
        payload: { id: store.id, businessId: store.businessId, name: newName },
      });
      yield put(showToaster(getToasterOptions('edit_store_name_update_success', 'success', null, 5000)));
      yield put(showEditStoreNameModal({ show: false, store: null }));
      if (onCompleted) {
        onCompleted();
      }
    } catch (e) {
      logger.error({
        error: e,
        context: { saga: 'handleEditStoreName' },
        params: { store, newName },
      });
      yield put(showToaster(getToasterOptions('generic_request_error', 'error')));
    }
  } else {
    yield put(showEditStoreNameModal({ show: false, store: null }));
  }
}
