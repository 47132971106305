import { call, put } from 'redux-saga/effects';

import api from '../../services/api';
import { setFacebookCampaignLanguages } from '../../actions';

export default function* getFacebookCampaignLanguages() {
  const languages = yield call(api.getLanguagesFacebook);
  yield put(setFacebookCampaignLanguages(languages));
  return languages;
}
