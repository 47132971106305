import _ from 'lodash';
import { call, put } from 'redux-saga/effects';

import globals from '../../globals';
import api from '../../services/api';

import { showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* createBusiness(name, couponCode) {
  const businessData = { name };

  // We only set internalTraffic to true for dev and stage environments
  const { ENVIRONMENT_NAME } = globals;
  if (_.includes(['stage', 'dev', 'local'], ENVIRONMENT_NAME)) {
    _.set(businessData, 'internalTraffic', true);
  }

  if (_.isNil(couponCode) === false) {
    _.set(businessData, 'stripeCoupon', couponCode);
  }

  try {
    const { id } = yield call(api.createBusiness, businessData);
    return { id };
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'createBusiness' },
      params: { name, businessData },
    });
    yield put(showToaster(getToasterOptions('generic_request_error', 'error')));
    return undefined;
  }
}
