import {
  DATA_FACEBOOK_CAMPAIGN_LANGUAGES,
  DATA_FACEBOOK_CAMPAIGN_TARGETS,
  DATA_FACEBOOK_CONNECTED_BUSINESSES,
} from '../constants';

export default {
  defaultState: {
    facebook: {
      businesses: [],
      languages: [],
    },
  },
  reducers: {
    [DATA_FACEBOOK_CAMPAIGN_LANGUAGES]: (state, { payload: languages }) => (
      { ...state, facebook: { ...state.facebook, languages } }
    ),
    [DATA_FACEBOOK_CAMPAIGN_TARGETS]: (state, { payload: targets }) => (
      { ...state, facebook: { ...state.facebook, targets } }
    ),
    [DATA_FACEBOOK_CONNECTED_BUSINESSES]: (state, { payload: businesses }) => (
      { ...state, facebook: { ...state.facebook, businesses } }
    ),
  },
};
