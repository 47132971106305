/* eslint-disable no-restricted-syntax */
import _ from 'lodash';
import {
  call, put, all, select,
} from 'redux-saga/effects';

import api from '../../services/api';

import { setCampaignLeadForms, showToaster } from '../../actions';
import { selectBusinessUsers, selectBusiness } from '../../selectors';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* getAllLeadGenForms({ payload }) {
  const {
    campaignStores,
  } = payload;

  const currentUsers = yield select(selectBusinessUsers);
  let userIds = currentUsers?.filter((u) => (u.isConnectedToFacebook))?.map((user) => user.id);

  if (_.isEmpty(userIds)) {
    let businessId;
    try {
      const business = yield select(selectBusiness);
      const users = yield call(api.getUsers, business?.id);
      userIds = users?.users?.filter((u) => (u.isConnectedToFacebook))?.map((user) => user.id);
    } catch (e) {
      logger.error({
        error: e,
        context: { saga: 'getBusinessUsers' },
        params: {
          userIds,
          businessId,
        },
      });
    }
  }

  try {
    let finalResult = [];
    let success = false;

    for (const userId of userIds) {
      const calls = _.map(campaignStores, ({ integrations }) => {
        const facebookIntegration = _.find(integrations, ({ type }) => type === 'facebook');
        const fbPageId = _.get(facebookIntegration, 'pageId');

        if (fbPageId) {
          return call(api.getMetaAllLeadGenForms, { userId, fbPageId });
        }
        return null;
      }).filter((c) => c !== null);

      const filterCalls = _.uniqBy(calls, (item) => {
        const { userId: uId, fbPageId } = item.payload.args[0];
        return `${uId}-${fbPageId}`;
      });

      let allSuccessful;
      let responses;
      try {
        responses = yield all(filterCalls);
        allSuccessful = responses?.every((response) => response && _.isArray(response));
      } catch (e) {
        // eslint-disable-next-line
        console.log('No successful response found.');
      }

      if (allSuccessful) {
        // Populates with id if the name is missing
        _.forEach(responses, (item) => {
          _.forEach(item, (dataItem) => {
            _.defaults(dataItem, { name: dataItem.id });
          });
        });
        const allResults = _.flatMap(responses, (item) => item);
        finalResult = _.uniqBy(allResults, 'id');
        success = true;
        break;
      }
    }

    if (!_.isEmpty(userIds)) {
      if (success) {
        yield put(setCampaignLeadForms(finalResult));
      } else {
        throw new Error('No successful response found.');
      }
    } else {
      yield put(setCampaignLeadForms(finalResult));
    }
  } catch (e) {
    yield put(showToaster(getToasterOptions('Failed getting all lead gen forms. Try again in a few minutes.', 'error', null, 5000)));

    logger.error({
      error: e,
      context: { saga: 'getAllLeadGenForms' },
      params: {
        userIds,
        campaignStores,
      },
    });
    yield put(setCampaignLeadForms([]));
  }
}
