import * as constants from './constants';

function actionCreator(type) {
  return (payload) => ({
    type,
    payload,
  });
}

export const addStore = actionCreator(constants.MULTI_STORE_ADD_STORE);
export const showEditStoreNameModal = actionCreator(constants.SHOW_EDIT_STORE_NAME_MODAL);
export const editStoreName = actionCreator(constants.EDIT_STORE_NAME);
export const confirmEditStoreName = actionCreator(constants.CONFIRM_EDIT_STORE_NAME);
export const setStoreStatus = actionCreator(constants.SET_STORE_STATUS);
export const enableDisableStore = actionCreator(constants.ENABLE_DISABLE_STORE);
export const addBusiness = actionCreator(constants.MULTI_BUSINESS_ADD_BUSINESS);
export const updateBusiness = actionCreator(constants.UPDATE_BUSINESS);
export const showEditBusinessNameModal = actionCreator(constants.SHOW_EDIT_BUSINESS_NAME_MODAL);
export const editBusinessName = actionCreator(constants.EDIT_BUSINESS_NAME);
export const confirmEditBusinessName = actionCreator(constants.CONFIRM_EDIT_BUSINESS_NAME);
export const billingModalStripeExit = actionCreator(constants.BILLING_MODAL_STRIPE_EXIT);
export const billingModalStripeSubscribe = actionCreator(constants.BILLING_MODAL_STRIPE_SUBSCRIBE);
export const cancelStripeSubscription = actionCreator(constants.CANCEL_STRIPE_SUBSCRIPTION);
export const deleteStripeSubscriptionItem = actionCreator(
  constants.DELETE_STRIPE_SUBSCRIPTION_ITEM,
);
export const updateStripeSubscription = actionCreator(constants.UPDATE_STRIPE_COLLECTION);
export const changeBusiness = actionCreator(constants.CHANGE_BUSINESS);
export const clearCampaignWizard = actionCreator(constants.CLEAR_CAMPAIGN_WIZARD);
export const clearSuggestedCampaign = actionCreator(constants.CLEAR_SUGGESTED_CAMPAIGN);
export const closeCampaignWizard = actionCreator(constants.CLOSE_CAMPAIGN_WIZARD);
export const closeFeedbackDialog = actionCreator(constants.CLOSE_FEEDBACK_DIALOG);
export const closeToaster = actionCreator(constants.CLOSE_TOASTER);
export const connectInstagramAccount = actionCreator(constants.CONNECT_INSTAGRAM_ACCOUNT);
export const connectInstagramAccountToAdAccount = actionCreator(
  constants.CONNECT_INSTAGRAM_ACCOUNT_TO_AD_ACCOUNT,
);
export const connectFacebookAccount = actionCreator(constants.CONNECT_FACEBOOK_ACCOUNT);
export const connectStoreToFacebook = actionCreator(constants.CONNECT_STORE_TO_FACEBOOK);
export const createStripePaymentMethod = actionCreator(constants.CREATE_STRIPE_PAYMENT_METHOD);
export const deleteCampaign = actionCreator(constants.DELETE_CAMPAIGN);
export const deleteUserAccount = actionCreator(constants.DELETE_USER_ACCOUNT);
export const duplicateCampaign = actionCreator(constants.DUPLICATE_CAMPAIGN);
export const pauseOrUnpauseCampaign = actionCreator(constants.PAUSE_UNPAUSE_CAMPAIGN);
export const retryCampaign = actionCreator(constants.RETRY_CAMPAIGN);
export const handleAddStripePaymentMethod = actionCreator(constants.ADD_STRIPE_PAYMENT_METHOD);
export const handleFeedbackDialog = actionCreator(constants.HANDLE_FEEDBACK_DIALOG);
export const handleReactivateUser = actionCreator(constants.REACTIVATE_USER);
export const handleCancelledSubscription = actionCreator(constants.HANDLE_CANCELLED_SUBSCRIPTION);
export const handleUpdateStripePaymentMethod = actionCreator(
  constants.UPDATE_STRIPE_PAYMENT_METHOD,
);
export const initUser = actionCreator(constants.INIT_USER);
export const inviteUser = actionCreator(constants.INVITE_USER);
export const setInstagramAccount = actionCreator(constants.DATA_INSTAGRAM_ACCOUNT);
export const getCampaigns = actionCreator(constants.GET_CAMPAIGNS);
export const getImages = actionCreator(constants.GET_IMAGES);
export const removeImages = actionCreator(constants.REMOVE_IMAGES);
export const updateImageTags = actionCreator(constants.UPDATE_TAG_IMAGES);
export const getMoreImages = actionCreator(constants.GET_MORE_IMAGES);
export const getSmartFeed = actionCreator(constants.GET_SMARTFEED);
export const getStoresFacebookInfo = actionCreator(constants.GET_STORES_FB_INFO);
export const getBusinessInsights = actionCreator(constants.GET_BUSINESS_INSIGHTS);
export const openSuggestedCampaignDetail = actionCreator(constants.OPEN_SUGGESTED_CAMPAIGN_DETAIL);
export const saveSuggestedCampaign = actionCreator(constants.SAVE_SUGGESTED_CAMPAIGN);
export const showFacebookAntiScumModal = actionCreator(constants.SHOW_FACEBOOK_ANTI_SCUM_MODAL);
export const continueFacebookAntiScum = actionCreator(constants.CONTINUE_FACEBOOK_ANTI_SCUM);
export const getFacebookPages = actionCreator(constants.GET_FACEBOOK_PAGES);
export const setFacebookPages = actionCreator(constants.DATA_FACEBOOK_PAGES_ITEMS);
export const setFacebookPagesLoading = actionCreator(constants.DATA_FACEBOOK_PAGES_LOADING);
export const showFacebookPageSelectModal = actionCreator(constants.SHOW_FACEBOOK_PAGE_SELECT_MODAL);
export const facebookPageSelect = actionCreator(constants.FACEBOOK_PAGE_SELECT);
export const adAccountSelect = actionCreator(constants.AD_ACCOUNT_SELECT);
export const showAdAccountSelectModal = actionCreator(
  constants.SHOW_FACEBOOK_AD_ACCOUNT_SELECT_MODAL,
);
export const showAccountIssuesModal = actionCreator(
  constants.SHOW_ACCOUNT_ISSUES_MODAL,
);
export const showAddBusinessModal = actionCreator(constants.SHOW_ADD_BUSINESS_MODAL);
export const showAddBusinessConfirmModal = actionCreator(constants.SHOW_ADD_BUSINESS_CONFIRM_MODAL);
export const addBusinessConfirm = actionCreator(constants.ADD_BUSINESS_CONFIRM);
export const continueAddBusiness = actionCreator(constants.CONTINUE_ADD_BUSINESS);
export const showAddStoreModal = actionCreator(constants.SHOW_ADD_STORE_MODAL);
export const addStoreConfirmPayment = actionCreator(constants.ADD_STORE_CONFIRM_PAYMENT);
export const addStoreSelectPlace = actionCreator(constants.ADD_STORE_SELECT_PLACE);
export const multiStoreAdAccount = actionCreator(constants.MULTI_STORE_AD_ACCOUNT);
export const multiStoreBusiness = actionCreator(constants.MULTI_STORE_BUSINESS);
export const multiStoreExit = actionCreator(constants.MULTI_STORE_EXIT);
export const multiStoreUpdatePayment = actionCreator(constants.MULTI_STORE_UPDATE_PAYMENT);
export const reactivateStripeSubscription = actionCreator(constants.REACTIVATE_STRIPE_SUBSCRIPTION);
export const unpauseSubscriptionCollection = actionCreator(constants.UNPAUSE_STRIPE_COLLECTION);
export const searchImages = actionCreator(constants.SEARCH_IMAGES);
export const setActiveTab = actionCreator(constants.SET_ACTIVE_TAB);
export const setBusiness = actionCreator(constants.DATA_BUSINESS);
export const setBusinessInsights = actionCreator(constants.DATA_BUSINESS_INSIGHTS);
export const setBusinessInsightsLoading = actionCreator(constants.DATA_BUSINESS_INSIGHTS_LOADING);
export const setCampaign = actionCreator(constants.DATA_CAMPAIGN);
export const setSuggestedCampaign = actionCreator(constants.DATA_SUGGESTED_CAMPAIGN);
export const setCampaignAds = actionCreator(constants.DATA_CAMPAIGN_ADS);
export const setCampaignName = actionCreator(constants.DATA_CAMPAIGN_NAME);
export const setCampaignGoal = actionCreator(constants.DATA_CAMPAIGN_GOAL);
export const setCampaignObjective = actionCreator(constants.DATA_CAMPAIGN_OBJECTIVE);
export const updateCampaignAds = actionCreator(constants.DATA_CAMPAIGN_UPDATE_ADS);
export const setCampaignMetrics = actionCreator(constants.DATA_CAMPAIGN_METRICS);
export const setCampaignTrends = actionCreator(constants.DATA_CAMPAIGN_TRENDS);
export const setCampaignMetricsLoading = actionCreator(constants.DATA_CAMPAIGN_METRICS_LOADING);
export const setCampaignMetricsLoadingError = actionCreator(
  constants.DATA_CAMPAIGN_METRICS_LOADING_ERROR,
);
export const setCampaignEventId = actionCreator(constants.DATA_CAMPAIGN_EVENT_ID);
export const setCampaignLeadForms = actionCreator(constants.DATA_BUSINESS_LEAD_FORMS);
export const setBillingModalStage = actionCreator(constants.DATA_BILLING_MODAL_STAGE);
export const setCampaigns = actionCreator(constants.DATA_CAMPAIGNS);
export const setFacebookCampaignLanguages = actionCreator(
  constants.DATA_FACEBOOK_CAMPAIGN_LANGUAGES,
);
export const setFacebookCampaignTargets = actionCreator(constants.DATA_FACEBOOK_CAMPAIGN_TARGETS);
export const setFacebookConnectedBusinesses = actionCreator(
  constants.DATA_FACEBOOK_CONNECTED_BUSINESSES,
);
export const setFacebookPermissionsByStore = actionCreator(
  constants.DATA_FACEBOOK_PERMISSIONS_BY_STORES,
);
export const setFacebookTokenExpired = actionCreator(constants.DATA_FACEBOOK_TOKEN_EXPIRED);
export const setInitialImages = actionCreator(constants.DATA_INITIAL_IMAGES);
export const setInitialImagesCursor = actionCreator(constants.DATA_INITIAL_IMAGES_CURSOR);
export const setImagesMam = actionCreator(constants.DATA_IMAGES_MAM);
export const setInstagramAccounts = actionCreator(constants.DATA_INSTAGRAM_ACCOUNTS);
export const setIsSearchingMam = actionCreator(constants.DATA_IS_SEARCHING_MAM);
export const setLoadingAsync = actionCreator(constants.DATA_LOADING_ASYNC);
export const setLoadingPermissions = actionCreator(constants.DATA_LOADING_PERMISSIONS);
export const setOnboardingBusiness = actionCreator(constants.DATA_ONBOARDING_BUSINESS);
export const setShowBillingModal = actionCreator(constants.DATA_SHOW_BILLING_MODAL);
export const setSmartFeedItems = actionCreator(constants.DATA_SMARTFEED_ITEMS);
export const setSmartFeedLoading = actionCreator(constants.DATA_SMARTFEED_LOADING);
export const setStores = actionCreator(constants.DATA_STORES);
export const setStoresLoading = actionCreator(constants.DATA_STORES_LOADING);
export const setSubscription = actionCreator(constants.DATA_SUBSCRIPTION);
export const setSubscriptionLoading = actionCreator(constants.DATA_BILLING_SUBSCRIPTION_LOADING);
export const updateUserInfo = actionCreator(constants.DATA_SET_USER_INFO);
export const setUserActive = actionCreator(constants.DATA_USER_ACTIVE);
export const setUserAllUsers = actionCreator(constants.DATA_USER_ALL_USERS);
export const setStoreFacebookInfoLoading = actionCreator(constants.DATA_STORES_FB_INFO_LOADING);

export const setBusinessUsers = actionCreator(constants.DATA_BUSINESS_USERS);
export const setUsersLoading = actionCreator(constants.DATA_BUSINESS_USERS_LOADING);
export const showBackdrop = actionCreator(constants.DATA_BACKDROP);
export const showCampaignWizard = actionCreator(constants.OPEN_CAMPAIGN_WIZARD);
export const showFeedbackModal = actionCreator(constants.SHOW_FEEDBACK_DIALOG);
export const showFacebookConnectBusinessManagerModal = actionCreator(
  constants.SHOW_FACEBOOK_CONNECT_BUSINESS_MANAGER_MODAL,
);
export const showFacebookConnectAdAccountModal = actionCreator(
  constants.SHOW_FACEBOOK_CONNECT_AD_ACCOUNT_MODAL,
);
export const showFacebookPermissionsModal = actionCreator(
  constants.SHOW_FACEBOOK_PERMISSIONS_MODAL,
);
export const showInstagramSelectModal = actionCreator(constants.SHOW_INSTAGRAM_SELECT_MODAL);
export const showToaster = actionCreator(constants.SHOW_TOASTER);
export const submitCampaignForm = actionCreator(constants.SUBMIT_CAMPAIGN_FORM);
export const submitFeedback = actionCreator(constants.SUBMIT_FEEDBACK);
export const submitUserRegistration = actionCreator(constants.SUBMIT_USER_REGISTRATION);
export const saveCampaignForm = actionCreator(constants.SAVE_CAMPAIGN_FORM);

export const getBusinessUsers = actionCreator(constants.GET_BUSINESS_USERS);
export const reinviteUser = actionCreator(constants.REINVITE_USER);
export const removeUser = actionCreator(constants.REMOVE_USER);

export const setConfirmationDialog = actionCreator(constants.DATA_CONFIRMATION_DIALOG);
export const showConfirmationDialog = actionCreator(constants.SHOW_CONFIRMATION_DIALOG);
export const resultConfirmationDialog = actionCreator(constants.RESULT_CONFIRMATION_DIALOG);

export const setPublishMarketingDialog = actionCreator(constants.DATA_PUBLISH_MARKETING_DIALOG);
export const showPublishMarketingDialog = actionCreator(constants.SHOW_PUBLISH_MARKETING_DIALOG);
export const resultPublishMarketingDialog = actionCreator(
  constants.RESULT_PUBLISH_MARKETING_DIALOG,
);

export const resetApp = actionCreator(constants.RESET_APP);

export const reportsLoading = actionCreator(constants.REPORTS_LOADING);
export const setCampaignReport = actionCreator(constants.DATA_CAMPAIGN_REPORT);
export const setCampaignReports = actionCreator(constants.DATA_CAMPAIGN_REPORTS);

export const setStoresPaymentMethods = actionCreator(constants.DATA_STORES_PAYMENT_METHODS);
export const addPaymentMethodToAccount = actionCreator(constants.ADD_PAYMENT_METHOD_TO_ACCOUNT);

export const getUrlPresets = actionCreator(constants.GET_URL_PRESETS);
export const setUrlPresets = actionCreator(constants.DATA_URL_PRESETS);
export const setUrlPresetsLoading = actionCreator(constants.DATA_URL_PRESETS_LOADING);

export const setAdCardPresets = actionCreator(constants.DATA_AD_CARD_PRESETS);

export const setStoresCustomAudiences = actionCreator(constants.DATA_STORES_CUSTOM_AUDIENCES);

export const setCampaignPixelEventType = actionCreator(constants.DATA_CAMPAIGN_PIXEL_EVENT_TYPE);
export const setCampaignOptimizationGoal = actionCreator(constants.DATA_CAMPAIGN_OPTIMIZATION_GOAL);

export const setCampaignAdType = actionCreator(constants.DATA_CAMPAIGN_AD_TYPE);

export const setBusinessSuggestedCampaigns = actionCreator(
  constants.DATA_BUSINESS_SUGGESTED_CAMPAIGNS,
);

export const setBusinessSuggestedCampaignsLoading = actionCreator(
  constants.DATA_BUSINESS_SUGGESTED_CAMPAIGNS_LOADING,
);

export const getBusinessSuggestedCampaigns = actionCreator(
  constants.GET_BUSINESS_SUGGESTED_CAMPAIGNS,
);

export const getInvoiceHistory = actionCreator(constants.GET_INVOICE_HISTORY);
export const setInvoiceHistory = actionCreator(constants.DATA_INVOICE_HISTORY);
export const setInvoiceHistoryLoading = actionCreator(constants.DATA_INVOICE_HISTORY_LOADING);

export const setCampaignOverrideAutoAging = actionCreator(
  constants.DATA_CAMPAIGN_OVERRIDE_AUTO_AGING,
);

export const createActivityLog = actionCreator(constants.CREATE_ACTIVITY_LOG);

// Marketing Suggested Campaigns
export const publishMarketingAd = actionCreator(
  constants.PUBLISH_MARKETING_AD,
);

export const showMarketingAdFeedbackDialog = actionCreator(
  constants.MARKETING_AD_SHOW_FEEDBACK_DIALOG,
);

export const submitMarketingAdFeedback = actionCreator(
  constants.MARKETING_AD_SUBMIT_FEEDBACK,
);

export const sendMailChimpTestEmail = actionCreator(
  constants.SEND_MAIL_CHIMP_TEST_EMAIL,
);

export const updateMarketingSuggestedCampaign = actionCreator(
  constants.UPDATE_MARKETING_SUGGESTED_CAMPAIGN,
);

export const setAdMetrics = actionCreator(constants.DATA_GOOGLE_AD_METRICS);
export const setAdMetricsLoading = actionCreator(constants.DATA_GOOGLE_AD_METRICS_LOADING);
export const setAdMetricsLoadingError = actionCreator(
  constants.DATA_GOOGLE_AD_METRICS_LOADING_ERROR,
);

export const setGoogleSitelinks = actionCreator(
  constants.DATA_GOOGLE_SITELINKS_BY_MARKETING_CHANNEL_ID,
);
export const setGooglePromotions = actionCreator(
  constants.DATA_GOOGLE_PROMOTIONS_BY_MARKETING_CHANNEL_ID,
);
export const getGoogleAssets = actionCreator(constants.GET_GOOGLE_ASSETS);

export const createUpdateGoogleSearchAdsBulk = actionCreator(
  constants.CREATE_UPDATE_GOOGLE_SEARCH_ADS_BULK,
);

export const setBusinessCampaignsLoading = actionCreator(constants.DATA_LOADING_CAMPAIGNS);

export const setBellNotifications = actionCreator(constants.DATA_BELL_NOTIFICATIONS);

export const updateActivityLog = actionCreator(constants.UPDATE_ACTIVITY_LOG);

// SILECTIS
export const getSilectisFacebookMetrics = actionCreator(
  constants.GET_SILECTIS_FACEBOOK_METRICS,
);

export const getSilectisGoogleMetrics = actionCreator(
  constants.GET_SILECTIS_GOOGLE_METRICS,
);

export const getSilectisMailchimpMetrics = actionCreator(
  constants.GET_SILECTIS_MAILCHIMP_METRICS,
);

// Marketing Channels
export const setMarketingChannelsLoading = actionCreator(constants.MARKETING_CHANNELS_LOADING);
export const getMarketingChannels = actionCreator(constants.GET_MARKETING_CHANNELS);
export const setMarketingChannels = actionCreator(constants.DATA_MARKETING_CHANNELS);

// Google Search Ad Combinations
export const setSearchAdCombinations = actionCreator(constants.DATA_GOOGLE_SEARCH_AD_COMBINATIONS);
export const setSearchAdCombinationsLoading = actionCreator(
  constants.DATA_GOOGLE_SEARCH_AD_COMBINATIONS_LOADING,
);
export const setSearchAdCombinationsLoadingError = actionCreator(
  constants.DATA_GOOGLE_SEARCH_AD_COMBINATIONS_LOADING_ERROR,
);
export const setOnboardingMetaAction = actionCreator(constants.ONBOARDING_META_ACTION);

export const authorizeMailchimp = actionCreator(constants.AUTHORIZE_MAILCHIMP);
export const pauseMailchimpCampaign = actionCreator(constants.PAUSE_MAILCHIMP_CAMPAIGN);
export const authorizeGoogle = actionCreator(constants.AUTHORIZE_GOOGLE);

export const adRequest = actionCreator(constants.AD_REQUEST);

// LOCATIONS
export const setCoordinates = actionCreator(constants.SET_COORDINATES);
export const setLoadingCoordinates = actionCreator(constants.DATA_LOADING_COORDINATES);
export const translateAddressToCoordinates = actionCreator(constants.DATA_COORDINATES);

// COMMENTS
export const setComments = actionCreator(constants.SET_COMMENTS);
export const setLoadingComments = actionCreator(constants.DATA_LOADING_COMMENTS);
export const getComments = actionCreator(constants.DATA_COMMENTS);
export const postComment = actionCreator(constants.POST_COMMMENT);

// ALL GEN FORMS
export const getAllLeadGenForms = actionCreator(constants.GET_ALL_LEAD_GEN_FORMS);

// ACTIVITY LOG NOTE ACTIONS
export const setActivityLogNoteDialog = actionCreator(constants.DATA_ACTIVITY_LOG_DIALOG);
export const showActivityLogNoteDialog = actionCreator(constants.SHOW_ACTIVITY_LOG_DIALOG);
export const resultActivityLogNoteDialog = actionCreator(constants.RESULT_ACTIVITY_LOG_DIALOG);

export const subscriptionCancellationRequest = actionCreator(
  constants.SUBSCRIPTION_CANCELLATION_REQUEST,
);

export const getVideoThumbnail = actionCreator(constants.GET_VIDEO_THUMBNAIL);
