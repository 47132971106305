import React, { useEffect, useState, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import useToggle from 'react-use/lib/useToggle';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { makeStyles } from '@material-ui/core/styles';
import AsyncButton from '@targetable/targetable-web-framework/lib/react/components/AsyncButton/AsyncButton';

import { selectActivityNoteDialogInformation } from '../../selectors';
import { resultActivityLogNoteDialog } from '../../actions';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(2, 0),
  },
  customTextarea: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

const ActivityLogNote = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [confirmDisabled, toggleConfirmDisabled] = useToggle(false);
  const [note, setNote] = useState('');
  const information = useSelector(selectActivityNoteDialogInformation);
  const {
    isOpen,
    cancelText,
    cancelDataCy,
    confirmText,
    confirmDataCy,
    moreInfoText,
    moreInfoDataCy,
    text,
    title,
    textParams,
    titleParams,
    resultOnClose,
  } = information;
  const dispatch = useDispatch();

  // aca o se cancela o manda todo al activity log
  const onClick = useCallback(
    (result) => dispatch(resultActivityLogNoteDialog(result)),
    [dispatch],
  );

  useEffect(() => {
    if (!isOpen && confirmDisabled) {
      toggleConfirmDisabled(false);
    }
  }, [confirmDisabled, isOpen, toggleConfirmDisabled]);

  const handleConfirm = useCallback(() => {
    toggleConfirmDisabled(true);
    onClick(note);
  }, [toggleConfirmDisabled, onClick, note]);

  const handleBackdropClick = () => {
    if (resultOnClose) {
      return onClick(resultOnClose);
    }
    return onClick(false);
  };

  const cancelButton = (color = 'primary') => (
    <AsyncButton onClick={() => onClick(false)} color={color} data-cy={cancelDataCy}>
      {t(cancelText)}
    </AsyncButton>
  );

  const showConfirmationFooter = () => (
    <>
      <AsyncButton onClick={handleConfirm} disabled={confirmDisabled} color="primary" data-cy={confirmDataCy}>
        {t(confirmText)}
      </AsyncButton>
      {cancelButton()}
    </>
  );

  const showMoreInfoFooter = () => (
    <>
      {cancelButton('secondary')}
      <AsyncButton onClick={handleConfirm} disabled={confirmDisabled} color="secondary" data-cy={moreInfoDataCy}>
        {t(moreInfoText)}
      </AsyncButton>
    </>
  );

  return (
    <Dialog
      onClose={handleBackdropClick}
      maxWidth="sm"
      open={isOpen}
    >
      <DialogContent>
        {title && <Typography variant="h4" className={classes.title}>{t(title, titleParams)}</Typography>}
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: t(text, {
              ...(textParams || {}),
              interpolation: { escapeValue: false },
            }),
          }}
        />
        <TextareaAutosize
          minRows={3}
          onChange={(event) => setNote(event.target.value)}
          className={classes.customTextarea}
        />
      </DialogContent>
      <DialogActions>
        {moreInfoText ? showMoreInfoFooter() : showConfirmationFooter()}
      </DialogActions>
    </Dialog>
  );
};

export default ActivityLogNote;
