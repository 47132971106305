import _ from 'lodash';
import {
  call, put, all, select,
} from 'redux-saga/effects';
import { getUserFacebookIntegration } from '@targetable/targetable-web-framework/lib/services/user';

import api from '../../services/api';
import history from '../../services/history';
import { getRejectedRoute } from '../../routes';
import { setFacebookPermissionsByStore } from '../../actions';
import { selectFacebookPermissions, selectUser } from '../../selectors';
import logger from '../../services/logger';
import createActivityLog from '../createActivityLog/createActivityLog';
import { supressPermissionsCalls } from '../../constants';

export default function* getStoreFacebookPermissions(store) {
  const user = yield select(selectUser);

  const storeFacebookIntegration = _.find(_.get(store, 'integrations'), ({ type }) => type === 'facebook') || {};

  if (_.get(storeFacebookIntegration, 'pageId')) {
    const { id: storeId } = store;
    const { businessId, adAccountId, pageId } = storeFacebookIntegration;
    const { accessToken, userId } = getUserFacebookIntegration(user);

    let adAccount;
    let page;
    let business;

    try {
      if (!supressPermissionsCalls) {
        const res = yield all([
          call(
            api.getAdAccountPermissions, storeId, businessId, adAccountId, userId, accessToken,
          ),
          call(
            api.getPagePermissions, storeId, businessId, pageId, userId, accessToken,
          ),
          call(
            api.getBusinessPermissions, storeId, businessId, userId, accessToken,
          ),
        ]);

        [adAccount, page, business] = res;

        const { stores } = yield select(selectFacebookPermissions);

        yield put(setFacebookPermissionsByStore({
          ...stores,
          [storeId]: {
            adAccount: {
              permission: _.includes(adAccount.permissions, 'MANAGE'), name: adAccount.name, permissions: adAccount.permissions, adAccountId: adAccount?.adAccountId,
            },
            page: {
              permission: _.includes(page.permissions, 'MANAGE'), name: page.name, permissions: page.permissions, pageId: page?.pageId,
            },
            business: {
              permission: _.includes(business.permissions, 'ADMIN'), name: business.name, permissions: business.permissions, fbBusinessId: business?.fbBusinessId,
            },
          },
        }));
      } else {
        adAccount = {
          name: 'It is unknown because permissions validation was not performed during the Page Integration',
          permissions: ['NO VALIDATED PERMISSIONS'],
          id: adAccountId,
        };
        page = {
          name: 'It is unknown because permissions validation was not performed during the Page Integration',
          permissions: ['NO VALIDATED PERMISSIONS'],
          id: pageId,
        };
        business = {
          name: 'It is unknown because permissions validation was not performed during the Page Integration',
          permissions: ['NO VALIDATED PERMISSIONS'],
          businessId,
        };
      }
        // Create Activity Log on missing permissions for connecting
        const { locations, ...basicStore } = store;
        yield call(createActivityLog, {
          businessId: store?.businessId,
          userId: user?.id,
          type: 'store_connection_permissions',
          ui: {
            title: 'Facebook Page Connected to Store Record by User',
          },
          meta: {
            entity: basicStore,
            supressPermissionsCalls,
            ...(!_.isEmpty(adAccount) ? {
              adAccount: {
                  ...adAccount,
                  adAccountId,
              },
            } : {}),
            ...(!_.isEmpty(page) ? {
              page: {
                  ...page,
                  pageId,
              },
            } : {}),
            ...(!_.isEmpty(business) ? {
              business: {
                  ...business,
                  businessId,
              },
            } : {}),
          },
        });
    } catch (e) {
      logger.error({
        error: e,
        context: { saga: 'getStoreFacebookPermissions' },
        params: { user, store },
      });
      history.push(getRejectedRoute());
    }
  }
}
