import getLoggerConstructor from '@targetable/targetable-web-framework/lib/services/logger';

import env from '../globals';

export default getLoggerConstructor({
  env: env.ENVIRONMENT_NAME,
  consoleLogging: env.ENVIRONMENT_NAME !== 'prod',
  airbrakeLogging: env.ENVIRONMENT_NAME !== 'local',
  airbrakeId: env.AIRBRAKE_ID,
  airbrakeKey: env.AIRBRAKE_KEY,
});

// logger.error({ error: 'test logging', context: { testing: true, severity: 'info' } });
