import { take, call, fork } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';

import auth from '../../services/auth';
import logger from '../../services/logger';
import env from '../../globals';

import handleIncomingDataStream from '../handleIncomingDataStream/handleIncomingDataStream';

function initChannel() {
  return eventChannel((emitter) => {
    let webSocket;
    let interval;

    let envName = '';
    if (['local', 'dev'].includes(env.ENVIRONMENT_NAME)) {
      envName = 'dev.';
    } else if (env.ENVIRONMENT_NAME === 'stage') {
      envName = 'stage.';
    }

    const messageHandler = (event) => {
      emitter(event?.data);
    };

    try {
      // https://developer.mozilla.org/en-US/docs/Web/API/WebSockets_API/Writing_WebSocket_client_applications
      webSocket = new WebSocket(`wss://websockets-ws.${envName}targetable.io?Auth=${auth.apiAccessToken}`);

      // https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/message_event
      webSocket.addEventListener('message', messageHandler);

      interval = setInterval(() => {
        if (webSocket?.readyState === webSocket?.OPEN) {
          webSocket.send('ping');
        } else if (webSocket?.readyState === webSocket?.CLOSED) {
          webSocket?.removeEventListener('message', messageHandler);
          webSocket = new WebSocket(`wss://websockets-ws.${envName}targetable.io?Auth=${auth.apiAccessToken}`);
          webSocket?.addEventListener('message', messageHandler);
        }
      }, 60000);
    } catch (e) {
      logger.error({
        error: e,
        context: { saga: 'initSocket - connect' },
        params: { url: `wss://websockets-ws.${envName}targetable.io?Auth=xxxxx` },
      });
    }

    return () => {
      if (interval) { clearInterval(interval); }
      webSocket?.close();
    };
  });
}

export default function* initSocket() {
  const channel = yield call(initChannel);
  try {
    while (true) {
      const eventData = yield take(channel);
      const eventDataParsed = JSON.parse(eventData);
      yield fork(handleIncomingDataStream, eventDataParsed);
    }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'initSocket' },
    });
  }
}
