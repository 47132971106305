import { call, put } from 'redux-saga/effects';

import _ from 'lodash';
import api from '../../services/api';

import { showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import i18n from '../../services/i18n';
import globals from '../../globals';

export default function* createMultiStore(businessId, name) {
  try {
    const storeData = {
      businessId,
      name,
    };

    // We only set internalTraffic to true for dev and stage environments
    const { ENVIRONMENT_NAME } = globals;
    if (_.includes(['stage', 'dev', 'local'], ENVIRONMENT_NAME)) {
      _.set(storeData, 'internalTraffic', true);
    }

    _.set(storeData, 'defaultRadius', 5);
    _.set(storeData, 'defaultRadiusEmployment', 15);
    _.set(storeData, 'defaultRadiusHousing', 15);

    return yield call(api.createStore, storeData);
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'createMultiStore' },
      params: { businessId, name },
    });
    yield put(showToaster(getToasterOptions(i18n.t('create_store_error'), 'error')));
    return undefined;
  }
}
