import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Dinero from 'dinero.js';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import AsyncButton from '@targetable/targetable-web-framework/lib/react/components/AsyncButton/AsyncButton';
import FullScreenDialog from '@targetable/targetable-web-framework/lib/react/containers/FullScreenDialog/FullScreenDialog';

import { getPaidAdProductInfo } from '@targetable/targetable-web-framework/lib/services/paywallUtils';
import { selectSubscription } from '../../selectors';
import footerButtonsStyles from '../../styles/footerButtons';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '192px',
  },
  container: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '0 16px',
    textAlign: 'center',
  },
  header: {
    margin: theme.spacing(5, 0, 4),
  },
  text: {
    margin: theme.spacing(0, 0, 4),
  },
  subText: {
    margin: theme.spacing(4, 0),
  },
  ...footerButtonsStyles(theme),
}));

const AddStorePaymentModal = ({
  title,
  onConfirm,
  onCancel,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const subscription = useSelector(selectSubscription);
  const paidAdProductInfo = getPaidAdProductInfo(subscription);
  const periodAmount = paidAdProductInfo?.periodAmount;
  const periodAmountDifference = _.get(subscription, 'periodAmountDifference');

  const currentAmount = Dinero({ amount: periodAmount, precision: 2 }).toFormat();
  const newAmount = Dinero(
    { amount: periodAmount + periodAmountDifference, precision: 2 },
  ).toFormat();

  return (
    <FullScreenDialog
      open
      onClose={() => onCancel()}
      title={title}
    >
      <div className={classes.container}>
        <Typography variant="h2" className={classes.header}>
          {t('add_store_payment_header')}
        </Typography>
        <Typography variant="subtitle1" className={classes.text}>
          {t('add_store_payment_text', { currentAmount, newAmount })}
        </Typography>
        <Grid container>
          <Grid item xs={6} className={classes.alignLeft}>
            <AsyncButton
              className={classes.leftButton}
              variant="outlined"
              onClick={onCancel}
              data-cy="cta-multistore_confirm_stripe_cancel"
            >
              {t('cancel')}
            </AsyncButton>
          </Grid>
          <Grid item xs={6} className={classes.alignRight}>
            <AsyncButton
              className={classes.rightButton}
              color="secondary"
              variant="contained"
              onClick={onConfirm}
              data-cy="cta-multistore_confirm_stripe"
            >
              {t('confirm')}
            </AsyncButton>
          </Grid>
        </Grid>
      </div>
    </FullScreenDialog>
  );
};

AddStorePaymentModal.propTypes = {
  title: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default AddStorePaymentModal;
