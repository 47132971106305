import { call, fork, select } from 'redux-saga/effects';

import _ from 'lodash';
import { hasActiveSubscriptions, getPaidAdProductInfo } from '@targetable/targetable-web-framework/lib/services/paywallUtils';
import api from '../../services/api';
import {
  selectBusiness,
  selectSubscription,
  selectUser,
  selectStores,
} from '../../selectors';
import logger from '../../services/logger';
import updateBusiness from '../updateBusiness/updateBusiness';
import postSubscriptionActivation from '../postSubscriptionActivation/postSubscriptionActivation';
import getStripeSubscription from '../getStripeSubscription/getStripeSubscription';

export default function* createStripeSubscription(coupon, onError) {
  const business = yield select(selectBusiness);
  const user = yield select(selectUser);
  const stripeCustomerId = _.get(business, 'stripeCustomerId');

  const subscription = yield select(selectSubscription);
  const stores = yield select(selectStores);
  const hasActiveSubscription = hasActiveSubscriptions(subscription);
  const paidAdProductInfo = getPaidAdProductInfo(subscription);
  const subscriptionId = paidAdProductInfo?.subscriptionId;

  // if the subscription is missing, create new one
  // if the subscription is not missing, add coupon to it
  try {
    if (_.isNil(subscriptionId) || !hasActiveSubscription) {
      const quantity = paidAdProductInfo?.quantity > 1
        ? paidAdProductInfo.quantity : stores?.length || 1;

      const success = yield call(
        api.createStripeSubscription,
        stripeCustomerId,
        {
          businessId: business.id,
          userId: user.id,
          ...(coupon ? { coupon } : {}),
          quantity,
        },
      );

      if (!success) {
        if (onError) {
          onError();
        }
        return false;
      }

      // for subscription that's been cancelled by stripe and then recreated
      // we must check the quantity of stores and update the strip sub.
      // UPDATE 28/May/2021: The above `createStripeSubscription` now sets the right quantity
      // from the get-go, but this case is still relevant since `updateStripeSubscription` signals
      // other services (Eg. HubSpot) that the business subscription has been updated.
      if (quantity > 1) {
        // Obtain the subscription just created
        const createdSub = yield call(getStripeSubscription);
        const newPaidAdProd = getPaidAdProductInfo(createdSub);
        yield call(
          api.updateStripeSubscription,
          _.get(business, 'stripeCustomerId'),
          newPaidAdProd?.subscriptionId,
          { quantity: newPaidAdProd?.quantity, businessId: business.id },
        );
        yield call(getStripeSubscription);
      }
    } else if (coupon) {
      yield call(api.updateStripeSubscription, stripeCustomerId, subscriptionId, {
        coupon, businessId: business.id,
      });
    }
  } catch (e) {
    // TODO: check for specific error of invalid coupon
    logger.error({
      error: e,
      context: { saga: 'createStripeSubscription', severity: 'info' },
      params: {
        business, stripeCustomerId, subscription, subscriptionId,
      },
    });

    if (e.response && e.response.status === 400) {
      if (onError) {
        onError();
      }
    }

    return false;
  }

  // after using it, clear it from business
  if (coupon && _.get(business, 'stripeCoupon') === coupon) {
    yield call(updateBusiness, { payload: { id: _.get(business, 'id'), stripeCoupon: null } });
  }

  yield call(getStripeSubscription);
  yield fork(postSubscriptionActivation);

  return true;
}
