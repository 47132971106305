import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import InfoStepperModal from '../InfoStepperModal/components/InfoStepperModal/InfoStepperModal';

import { connectInstagramAccount, showFacebookConnectBusinessManagerModal } from '../../actions';
import { selectShowFacebookConnectBusinessManagerModal } from '../../selectors';

import stepImage2 from '../../static/images/infoModal/instagram_connection_bm_instagram.png';
import stepImage3 from '../../static/images/infoModal/instagram_connection_bm_add_ig.png';
import stepImage4 from '../../static/images/infoModal/instagram_connection_bm_ig_credentials.png';
import stepImage5 from '../../static/images/infoModal/instagram_connection_ad_account.png';

const InfoStepperConnectBM = ({
  connectInstagramAccountAction,
  showInfo,
  showInfoModalConnectBMAction,
}) => {
  const { t } = useTranslation();

  const store = _.get(showInfo, 'store');
  const integrations = _.get(store, 'integrations', []);
  const facebookIntegration = _.find(integrations, (int) => int.type === 'facebook' || int.connection === 'facebook');
  // NOTE: Leaving this here in case the externalLink url changes in the future
  // and any facebookIntegration info is required.
  // eslint-disable-next-line no-unused-vars
  const businessId = _.get(facebookIntegration, 'businessId');

  return (
    <InfoStepperModal
      onClose={() => showInfoModalConnectBMAction({ show: false })}
      open={_.get(showInfo, 'show')}
      title={t('instagram_connect_modal_title')}
      steps={[
        {
          label: t('instagram_connect_modal_step1_label'),
          line1: t('instagram_connect_modal_step1_line1'),
          line2: t('instagram_connect_modal_step1_line2'),
          line3: t('instagram_connect_modal_step1_line3'),
          externalLink: 'https://help.instagram.com/502981923235522',
          linkText: t('instagram_connect_modal_help_link_text'),
          nextEnabled: true,
        },
        {
          label: t('instagram_connect_modal_step2_label'),
          line1: t('instagram_connect_modal_step2_line1'),
          externalLink: t('instagram_connect_modal_step2_link'),
          linkText: t('instagram_connect_modal_bm_link_text'),
          imageDefinition: {
            xs: stepImage2,
            sm: stepImage2,
            md: stepImage2,
            lg: stepImage2,
          },
          backEnabled: true,
          nextEnabled: true,
        },
        {
          label: t('instagram_connect_modal_step3_label'),
          line1: t('instagram_connect_modal_step3_line1'),
          imageDefinition: {
            xs: stepImage3,
            sm: stepImage3,
            md: stepImage3,
            lg: stepImage3,
          },
          backEnabled: true,
          nextEnabled: true,
        },
        {
          label: t('instagram_connect_modal_step4_label'),
          line1: t('instagram_connect_modal_step4_line1'),
          imageDefinition: {
            xs: stepImage4,
            sm: stepImage4,
            md: stepImage4,
            lg: stepImage4,
          },
          backEnabled: true,
          nextEnabled: true,
        },
        {
          label: t('instagram_connect_modal_step5_label'),
          line1: t('instagram_connect_modal_step5_line1'),
          imageDefinition: {
            xs: stepImage5,
            sm: stepImage5,
            md: stepImage5,
            lg: stepImage5,
          },
          backEnabled: true,
          nextEnabled: true,
          nextOverride: (activeStep, setActiveStep) => {
            const onDone = () => {
              setActiveStep(activeStep + 1);
            };

            connectInstagramAccountAction({ store, onDone });
          },
        },
        {
          label: t('instagram_connect_modal_issues_label'),
          line1: t('instagram_connect_modal_issues_line'),
          backEnabled: true,
          nextEnabled: false,
        },
      ]}
    />
  );
};

InfoStepperConnectBM.propTypes = {
  connectInstagramAccountAction: PropTypes.func.isRequired,
  showInfo: PropTypes.shape({
    show: PropTypes.bool,
    store: PropTypes.shape({}),
  }).isRequired,
  showInfoModalConnectBMAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  showInfo: selectShowFacebookConnectBusinessManagerModal(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  connectInstagramAccountAction: connectInstagramAccount,
  showInfoModalConnectBMAction: showFacebookConnectBusinessManagerModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InfoStepperConnectBM);
