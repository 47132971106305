import { put, select } from 'redux-saga/effects';

import { selectCampaign } from '../../selectors';
import { getToasterOptions } from '../helpers';
import { setCampaign, showToaster as showToasterAction } from '../../actions';
import history from '../../services/history';
import { getCampaignWizardDetailRoute } from '../../routes';
import submitCampaign from '../submitCampaign/submitCampaign';
import loadingAsync from '../loadingAsync/loadingAsync';

export default function* submitCampaignForm({ payload }) {
  const { onDone, onError } = payload;
  const campaign = yield select(selectCampaign);

  const { submittedCampaign, error } = yield loadingAsync(
    submitCampaign,
    campaign,
    onError,
  );

  if (error) {
    if (['canceled'].includes(error)) {
      if (onDone) {
        onDone();
      }
      return;
    }

    if (!['invalid_preset', 'invalid_url'].includes(error)) {
      yield put(showToasterAction(getToasterOptions('error_publish_campaign', 'error')));
    }

    if (onError) {
      onError(['invalid_preset', 'invalid_url'].includes(error) ? 'url' : null);
    }

    if (onError) {
      onError(['display_url'].includes(error) ? 'display_url' : null);
    }

    return;
  }

  yield put(setCampaign(submittedCampaign));
  history.push(getCampaignWizardDetailRoute(submittedCampaign.businessId, submittedCampaign.id));

  if (onDone) {
    onDone();
  }
}
