import { put, select, call } from 'redux-saga/effects';
import { getUserFacebookIntegration } from '@targetable/targetable-web-framework/lib/services/user';

import api from '../../services/api';
import history from '../../services/history';
import { getRejectedRoute } from '../../routes';
import { setFacebookPermissionsByStore } from '../../actions';
import { selectUser, selectBusiness } from '../../selectors';
import logger from '../../services/logger';
import { supressPermissionsCalls } from '../../constants';

export default function* getStoresFacebookPermissions() {
  const user = yield select(selectUser);
  const business = yield select(selectBusiness);

  let permissions = {};

  try {
    const userFbIntegration = getUserFacebookIntegration(user);

    if (!supressPermissionsCalls) {
      if (userFbIntegration && business?.id) {
        const { accessToken, userId } = userFbIntegration;
        permissions = yield call(
          api.getBusinessStoresPermissions, business?.id, userId, accessToken,
        );
      }
    }

    yield put(setFacebookPermissionsByStore(permissions));
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getStoresFacebookPermissions' },
      params: { user, business },
    });
    history.push(getRejectedRoute());
  }
}
