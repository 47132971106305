import { call, put } from 'redux-saga/effects';
import sanitizeString from '@targetable/targetable-web-framework/lib/utils/sanitizeString';

import api from '../../services/api';
import {
  setInitialImages, setInitialImagesCursor, showToaster,
} from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* getMoreImages({ payload }) {
  const {
    businessId,
    term,
    cursor: currentCursor,
    onDone,
    onError,
  } = payload;

  try {
    const { images, cursor } = yield call(
      api.getImages,
      businessId,
      sanitizeString(term),
      currentCursor,
    );
    onDone(images, cursor);
  } catch (e) {
    yield put(setInitialImagesCursor(null));
    yield put(setInitialImages([]));
    yield put(showToaster(getToasterOptions('error_whoops')));
    onError(true);
    logger.error({
      error: e,
      context: { saga: 'getMoreImages' },
      params: { payload },
    });
  }
}
