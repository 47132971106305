import {
  call,
  put,
} from 'redux-saga/effects';

import api from '../../services/api';
import logger from '../../services/logger';

export default function* fetchGoogleAssets(marketingChannelId, assetType, assetSetter) {
  // Try to get the assets for the passed marketingChannelId
  const assetTypeStateName = `${assetType.toLowerCase()}s`;
  try {
    const assets = yield call(
      api.getGoogleSearchCampaignAssets, marketingChannelId, assetType,
    );

    const data = {
      marketingChannelId,
      [assetTypeStateName]: assets,
      loading: false,
    };

    yield put(assetSetter(data));
  } catch (error) {
    logger.error({
      error,
      context: { saga: 'fetchGoogleAssets' },
      params: {
        marketingChannelId,
        assetType,
      },
    });

    const data = {
      marketingChannelId,
      [assetTypeStateName]: {},
      loading: false,
    };

    yield put(assetSetter(data));
  }
}
