import _ from 'lodash';
import { call } from 'redux-saga/effects';

import { getPaidAdProductInfo } from '@targetable/targetable-web-framework/lib/services/paywallUtils';
import dataLayerService from '../../services/dataLayerService';

function getSubscriptionState(activeStatus, hasSubscription) {
  return (activeStatus === false || hasSubscription === false) ? 'inactive' : 'active';
}

function getUserState(activeStatus, trialingStatus, scheduledForCancel, hasPaymentMethod) {
  if (trialingStatus) {
    return 'trialActive';
  }

  if (scheduledForCancel) {
    return 'cancelling';
  }

  if (activeStatus && hasPaymentMethod) {
    return 'paying';
  }

  return 'cancelled';
}

export default function* handleEntryAnalytics(business, user, subscription,
  ignoreSubscription = false) {
  yield call(dataLayerService.setVisitorType, user, business);
  yield call(dataLayerService.setValue, 'targetable_business_id', _.get(business, 'id'));
  yield call(dataLayerService.setValue, 'targetable_user_id', _.get(user, 'id'));

  // new users / businesses are in the process of getting their new subscription, so don't set these
  if (ignoreSubscription) {
    return;
  }

  const paidAdProductInfo = getPaidAdProductInfo(subscription);
  const subscriptionStatus = paidAdProductInfo?.status;
  const scheduledForCancel = paidAdProductInfo?.scheduledForCancel === true;
  const hasPaymentMethod = _.isNil(_.get(subscription, 'defaultSource')) === false;
  const hasSubscription = _.isNil(paidAdProductInfo?.subscriptionId) === false;

  const activeStatus = subscriptionStatus === 'active';
  const trialingStatus = subscriptionStatus === 'trialing';

  yield call(dataLayerService.setValue, 'subscriptionState', getSubscriptionState(activeStatus, hasSubscription));
  yield call(dataLayerService.setValue, 'userState', getUserState(activeStatus, trialingStatus, scheduledForCancel, hasPaymentMethod));
}
