import {
  DATA_GOOGLE_SEARCH_AD_COMBINATIONS,
  DATA_GOOGLE_SEARCH_AD_COMBINATIONS_LOADING,
  DATA_GOOGLE_SEARCH_AD_COMBINATIONS_LOADING_ERROR,
} from '../constants';

export default {
  defaultState: {
    adCombinations: [],
    adCombinationsLoading: false,
    adCombinationsLoadingError: false,
  },
  reducers: {
    [DATA_GOOGLE_SEARCH_AD_COMBINATIONS]: (state, { payload }) => ({
      ...state,
      adCombinations: payload,
    }),
    [DATA_GOOGLE_SEARCH_AD_COMBINATIONS_LOADING]: (state, { payload }) => ({
      ...state, adCombinationsLoading: payload,
    }),
    [DATA_GOOGLE_SEARCH_AD_COMBINATIONS_LOADING_ERROR]: (state, { payload }) => ({
      ...state, adCombinationsLoadingError: payload,
    }),
  },
};
