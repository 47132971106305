import algoliaSearch from 'algoliasearch/lite';
import { all, call } from 'redux-saga/effects';
import moment from 'moment';
import { SUGGEST_CAMPAIGN_STATUS_DRAFT } from '@targetable/targetable-web-framework/lib/react/constants';
import globals from '../../globals';

import api from '../../services/api';
import dateService from '../../services/date';
import logger from '../../services/logger';

const client = algoliaSearch(globals.ALGOLIA_APP_ID, globals.ALGOLIA_API_SEARCH_KEY);
const index = client.initIndex(`${globals.ENVIRONMENT_NAME}_SuggestedCampaignTemplates`);

export default function* createDefaultSuggestedCampaigns({ businessId, storeId }) {
  try {
    // start all suggested campaigns in 3 days
    // to give the user time to act on the campaign
    const momentStartDate = moment().add(3, 'days').startOf('day');
    const startDate = {
      day: momentStartDate.format('DD'),
      month: momentStartDate.format('MM'),
      year: momentStartDate.format('YYYY'),
    };

    const momentEndDate = moment(momentStartDate).add(13, 'days').endOf('day');
    const endDate = {
      day: momentEndDate.format('DD'),
      month: momentEndDate.format('MM'),
      year: momentEndDate.format('YYYY'),
    };

    const res = yield index.search('', {
      getRankingInfo: false,
      analytics: false,
      enableABTest: false,
      hitsPerPage: 100,
      attributesToRetrieve: ['suggestedCampaign'],
      responseFields: ['hits'],
      maxValuesPerFacet: 100,
      facets: ['*', 'facets'],
      facetFilters: [
        ['facets:Trigger~Onboarding'],
        ['facets:Vertical~All Businesses'],
        ['facets:status~active'],
      ],
    });

    yield all(res.hits.map(({ suggestedCampaign }) => call(api.createSuggestedCampaign, {
      ...suggestedCampaign,
      businessId,
      created: moment.utc().valueOf(),
      displayDate: dateService.startOfToday(),
      displayTime: suggestedCampaign.displayTime || { hour: 0, minute: 0 },
      status: SUGGEST_CAMPAIGN_STATUS_DRAFT,
      ads: suggestedCampaign.ads.map((ad) => {
        let { schedule } = ad;
        if (!schedule.type || schedule.type === 'range') {
          schedule = {
            ...schedule,
            startDate,
            endDate,
          };
        }

        return ({
          storeId,
          ...ad,
          schedule,
        });
      }),
    })));
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'createDefaultSuggestedCampaigns' },
      params: { businessId, storeId },
    });
  }
}
