import { call, put, select } from 'redux-saga/effects';

import api from '../../services/api';

import { setComments, setLoadingComments } from '../../actions';
import { selectBusiness } from '../../selectors';
import logger from '../../services/logger';

export default function* getComments({ payload: contentId }) {
  try {
    yield put(setLoadingComments(true));
    const business = yield select(selectBusiness);
    const comments = yield call(api.getCommentsByContentId, business.id, contentId);

    yield put(setComments(comments));
    yield put(setLoadingComments(false));
    return comments;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getComments' },
      params: { contentId },
    });
    return undefined;
  }
}
