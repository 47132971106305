import {
  SET_COMMENTS,
  DATA_LOADING_COMMENTS,
} from '../constants';

export default {
  defaultState: {
    loadingComments: false,
    comments: [],
  },
  reducers: {
    [DATA_LOADING_COMMENTS]: (state, { payload }) => ({ ...state, loadingComments: payload }),
    [SET_COMMENTS]: (state, { payload }) => ({ ...state, comments: payload }),
  },
};
