import {
  DATA_LOADING_COORDINATES,
  SET_COORDINATES,
} from '../constants';

export default {
  defaultState: {
    loadingCoordinates: false,
    coordinates: [{
      coordinates: {
        lat: 40.7128,
        lng: 74.006,
      },
    }],
  },
  reducers: {
    [DATA_LOADING_COORDINATES]: (state, { payload }) => ({ ...state, loadingCoordinates: payload }),
    [SET_COORDINATES]: (state, { payload }) => ({ ...state, coordinates: payload }),
  },
};
