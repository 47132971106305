import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import { isCallNowAndWithoutPhone } from '@targetable/targetable-web-framework/lib/react/campaignWizard/services/campaign/campaign';

import history from '../../services/history';
import { showToaster, showBackdrop } from '../../actions';
import { getCampaignsRoute } from '../../routes';
import i18n from '../../services/i18n';
import { getToasterOptions } from '../helpers';
import saveCampaign from '../saveCampaign/saveCampaign';
import api from '../../services/api';
import { selectStores } from '../../selectors';

export default function* duplicateCampaignSaga({ payload }) {
  const { campaign, onDone } = payload;
  let campaignToClone = campaign;
  yield put(showToaster(getToasterOptions('Creating a copy of the Campaign...',
    'info', null, 10000, true, null, 'bottom', 'right')));

  const stores = yield select(selectStores);

  // Algolia's object returns the 'isOptimized' property
  if (campaign?.isOptimized) {
    /*
    * We need to fetch the actual suggested campaign from DDB, becuse
    * Algolia's object is a partial one
    */
    campaignToClone = yield call(
      api.getCampaign, campaign.id, stores,
    );
  }
  const name = `${campaignToClone.name} - Copy`;
  const goal = _.get(campaignToClone, 'goal');
  const regulatedCategories = (goal === 'hiring') ? 'EMPLOYMENT' : 'NONE';

  yield put(showBackdrop(true));
  const specificTargets = _.get(campaignToClone, ['ads', '0', 'targeting', 'specificTargets']);
  const response = yield call(api.validateTargets, specificTargets, regulatedCategories);

  const originalAds = campaignToClone?.ads;

  if (originalAds) {
    // Just copy active stores, and if cta is CALL_NOW copy the ones with a phone number
    campaignToClone.ads = _.filter(originalAds, (ad) => {
      const { storeId } = ad;
      const store = _.find(stores, { id: storeId });
      if (isCallNowAndWithoutPhone(store, _.get(ad, 'ad.callToAction'))) return false;
      return !store || !store.disabled;
    });

    // When all the stores were disabled
    if (campaignToClone.ads?.length === 0) {
      campaignToClone.ads = _.map(originalAds, (adItem) => ({
        ...adItem,
        targeting: {
          ...adItem.targeting,
          cities: [],
          countries: [],
          zips: [],
          regions: [],
          geoMarkets: [],
          radius: 5,
        },
        storeId: null,
      }));
    }
 }

  const duplicateCampaign = {
    ..._.omit(campaignToClone, ['id', 'userId', 'status', 'created', 'updated', 'integrations', 'requestInfo']),
    name,
    status: 'draft',
    ads: _.map(campaignToClone.ads, (adItem) => ({
      ..._.omit(adItem, ['integrations']),
      targeting: {
        ...adItem.targeting,
        specificTargets: response,
      },
    })),
  };

  const savedCampaign = yield call(saveCampaign, duplicateCampaign);

  yield put(showBackdrop(false));
  if (savedCampaign) {
    history.push(getCampaignsRoute());

    if (onDone) {
      onDone();
    }

    yield put(showToaster(getToasterOptions(i18n.t('campaigns_duplicate_created', { name }), 'info', null, 5000)));
  } else {
    yield put(showToaster(getToasterOptions('error_update_campaign')));
  }
}
