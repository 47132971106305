import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getStaticVideo } from '@targetable/targetable-web-framework/lib/services/static';
import { useSelectors } from '@targetable/targetable-web-framework/lib/hooks/store';

import globals from '../../globals';
import { showFacebookPermissionsModal } from '../../actions';
import { selectShowFacebookPermissionsModal, selectStores } from '../../selectors';
import InfoStepperModal from '../InfoStepperModal/components/InfoStepperModal/InfoStepperModal';

const FacebookPermissionsModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [show, stores] = useSelectors(selectShowFacebookPermissionsModal, selectStores);

  const handleClose = () => {
    dispatch(showFacebookPermissionsModal(false));
  };

  return (
    <InfoStepperModal
      onClose={handleClose}
      open={show}
      title={t('understand_fb_permissions_modal_title')}
      steps={[
        {
          label: t('understand_fb_permissions_modal_step1_label'),
          line1: t('understand_fb_permissions_modal_step1_line1'),
          externalLinks: _.compact(stores.map((store) => {
            const integration = _.find(_.get(store, 'integrations', []), { type: 'facebook' });

            if (!integration) return false;

            return {
              externalLink: `https://business.facebook.com/settings/people/?business_id=${integration.businessId}`,
              linkText: store.name,
            };
          })),
          video: {
            autoplay: false,
            controls: true,
            sources: [
              {
                src: getStaticVideo(globals.STATIC_BASE_URL, 'MultiUserv1_1.mp4'),
                type: 'video/mp4',
              },
              {
                src: getStaticVideo(globals.STATIC_BASE_URL, 'MultiUserv1_1.m3u8'),
                type: 'application/x-mpegURL',
              },
            ],
          },
          nextEnabled: true,
        },
        {
          label: t('understand_fb_permissions_modal_step2_label'),
          line1: t('understand_fb_permissions_modal_step2_line1'),
          externalLink: 'https://www.targetable.com/knowledge/facebook-permissions-for-invited-users',
          linkText: t('understand_fb_permissions_modal_knowledge_base'),
          backEnabled: true,
        },
      ]}
      dataCy="component_FacebookPermissionsModal"
    />
  );
};

export default FacebookPermissionsModal;
