import PropTypes from 'prop-types';

const infoStepShape = {
  backEnabled: PropTypes.bool,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  imageDefinition: PropTypes.shape({
    xs: PropTypes.string,
    sm: PropTypes.string,
    md: PropTypes.string,
    lg: PropTypes.string,
  }),
  label: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  text: PropTypes.string,
};

export default infoStepShape;
