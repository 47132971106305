import { call, put, select } from 'redux-saga/effects';
import api from '../../services/api';
import { showToaster } from '../../actions';
import i18n from '../../services/i18n';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import { selectBusiness } from '../../selectors';

export default function* removeImages({ payload }) {
  const { publicIds, onDone } = payload;
  const currentBusiness = yield select(selectBusiness);
  try {
    yield call(api.deleteImages, currentBusiness.id, publicIds);
    yield put(showToaster(getToasterOptions(i18n.t('images_removed_successfully'), 'success', null, 5000)));

    if (onDone) { onDone(); }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'removeImages' },
      params: {
        businessId: currentBusiness.id,
        publicIds,
      },
    });
    yield put(showToaster(getToasterOptions(i18n.t('error_remove_images'))));
  }
}
