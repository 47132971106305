import {
  call,
  put,
  select,
  take,
} from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment';

import {
  getPaidAdProductInfo,
  getAATypeProducts,
  getAASubscriptions,
} from '@targetable/targetable-web-framework/lib/services/paywallUtils';
// eslint-disable-next-line no-unused-vars
import { TargetableBusiness } from '@targetable/targetable-types/dist/types/business';
import { setLoadingAsync, showConfirmationDialog, showToaster } from '../../actions';
import logger from '../../services/logger';
import {
  HANDLE_CANCELLED_SUBSCRIPTION,
  MULTI_STORE_ADD_STORE,
  RESULT_CONFIRMATION_DIALOG,
  SMARTFEED_TYPE_INSIGHT,
  MULTI_BUSINESS_ADD_BUSINESS,
  SUBSCRIPTION_CANCELLATION,
} from '../../constants';
import { getToasterOptions } from '../helpers';
import { selectSubscription, selectBusiness } from '../../selectors';
import reactivateStripeSubscription from '../reactivateStripeSubscription/reactivateStripeSubscription';
import handleAddStripePaymentMethod from '../handleAddStripePaymentMethod/handleAddStripePaymentMethod';
import unpauseStripeSubscriptionCollection from '../unpauseStripeSubscriptionCollection/unpauseStripeSubscriptionCollection';
import loadingAsync from '../loadingAsync/loadingAsync';

export default function* ensureSubscription(...args) {
  const action = _.last(args || []);
  const actionType = _.get(action, 'type');
  const onDone = _.get(action, 'payload.onDone');
  const onError = _.get(action, 'payload.onError');
  const isInsightFeedback = _.get(action, ['payload', 'type']) === SMARTFEED_TYPE_INSIGHT;
  const isCancelationFeedback = _.get(action, ['payload', 'type']) === SUBSCRIPTION_CANCELLATION;

  const isAddingStoreOrBusiness = [
    MULTI_STORE_ADD_STORE,
    MULTI_BUSINESS_ADD_BUSINESS,
  ].includes(actionType);

  try {
    // TGT-5029 - Make sure async is off otherwise all confirmation buttons would be disabled
    // Async loading set at the rootSaga level and introduced by TGT-4996.
    yield put(setLoadingAsync(false));
    /** @type {TargetableBusiness} */
    const business = yield select(selectBusiness);
    const subscription = yield select(selectSubscription);
    const defaultSource = _.get(subscription, 'defaultSource');
    const hasSource = _.isNil(defaultSource) === false && _.isEmpty(defaultSource) === false;

    let status;
    let isPauseCollection;
    let paidAdProductInfo;
    // Find out the specific A&A products we care about. ex: (paid_ad) (paid_ad_plus)
    const paidAdProductsInfo = getAASubscriptions(subscription);

    if (paidAdProductsInfo) {
      // iterates through the A&A products to see if any of them still valid for publishing
      for (let i = 0; i < paidAdProductsInfo.length; i += 1) {
        paidAdProductInfo = paidAdProductsInfo[i];
        status = _.get(paidAdProductInfo, 'status');
        const periodEnd = _.get(paidAdProductInfo, 'periodEnd');
        const pauseCollection = _.get(paidAdProductInfo, 'pauseCollection');
        isPauseCollection = status === 'active' && _.isEmpty(pauseCollection) === false;

        if (paidAdProductInfo && !isPauseCollection) {
          const ignoreActiveLogic = [
            HANDLE_CANCELLED_SUBSCRIPTION,
            MULTI_STORE_ADD_STORE,
          ].includes(actionType);

          if (status === 'active'
            || (!ignoreActiveLogic
              && status === 'canceled'
              && hasSource && (_.toInteger(moment.utc().valueOf() / 1000) < periodEnd))
          ) {
            if (args.length && typeof args[0] === 'function') {
              yield call(...args);
            }
            return;
          }
        } else if (isPauseCollection) {
            // As subscription is paused, we need to ask for re activation
            break;
          }
      }
    }

    /**
     * If subscription/Item is going to be canceled, we don't need to ensure the subscription.
     * TODO: The logic for ensure a subscription probably will have to change
     * to ensuring A&A Product only for Facebook/IG related processes like publishing
     * And the validation for creating new Stores/Businesses
     */
    if (isCancelationFeedback && args.length && typeof args[0] === 'function') {
      yield call(...args);
      return;
    }

    // if isInsightFeedback then no subscription validation is needed
    if (isInsightFeedback && args.length && typeof args[0] === 'function') {
      yield call(...args);
      return;
    }

    // if isAddingStoreOrBusiness then no subscription validation
    // is needed unless it already has a valid subscription
    if (isAddingStoreOrBusiness && (_.isNil(status) || status === 'canceled' || isPauseCollection)) {
      if (args.length && typeof args[0] === 'function') {
        yield call(...args);
        return;
      }
    }

    // Check if the business is allowed to bypass the subscription check (TGT-6525)
    const aaProducts = getAATypeProducts();
    // if ANY of the AA products are in the bypass list, then, don't show dialog
    const hasAnyAAProduct = aaProducts.some((pId) => business?.paywallOverrides?.includes(pId));
    if (hasAnyAAProduct) {
      yield call(...args);
      return;
    }

    // assume if there is no source (so no credit card)
    // they never signed up in the first place
    // so show the activate, not reactivate text
    const isCancelled = status === 'canceled';
    // Even if has a source, it should only show reactivate if user has the paidAd product
    const showReactivate = isCancelled && hasSource && paidAdProductInfo;
    let text = showReactivate ? 'reactivate_stripe_confirmation' : 'activate_stripe_confirmation';
    const confirmDataCy = showReactivate ? 'cta-reactivate_subscription_confirm' : 'cta-activate_subscription_confirm';
    const cancelDataCy = showReactivate ? 'cta-reactivate_subscription_cancel' : 'cta-activate_subscription_cancel';
    if (isPauseCollection) {
      text = 'unpause_stripe_confirmation';
    }
    yield put(showConfirmationDialog({
      text,
      confirmDataCy,
      cancelDataCy,
      onClick: _.identity,
    }));

    const { payload: confirm } = yield take(RESULT_CONFIRMATION_DIALOG);

    if (confirm) {
      try {
        let method = showReactivate ? reactivateStripeSubscription : handleAddStripePaymentMethod;
        if (isPauseCollection) {
          method = unpauseStripeSubscriptionCollection;
        }
        yield call(loadingAsync, method);
      } catch (ex) {
        logger.error({
          error: ex,
          context: { saga: 'ensureSubscription' },
          params: { action },
        });
        let errorTxt = showReactivate ? 'error_reactivate_subscription' : 'error_payment_method_error';
        if (isPauseCollection) {
          errorTxt = 'error_unpause_subscription_collection';
        }
        yield put(showToaster(getToasterOptions(errorTxt)));
        if (onError) onError();
        return;
      }

      const newSubscription = yield select(selectSubscription);
      const updatedPaidAdProductInfo = getPaidAdProductInfo(newSubscription);
      const updatedStatus = _.get(updatedPaidAdProductInfo, 'status');
      if (updatedStatus === 'active') {
        if (args.length && typeof args[0] === 'function') {
          yield call(...args);
          return;
        }
      }
    }

    if (onDone) onDone();
  } catch (ex) {
    logger.error({
      error: ex,
      context: { saga: 'ensureSubscription' },
      params: { action },
    });
    yield put(showToaster(getToasterOptions('generic_request_error')));
    if (onError) onError();
  }
}
