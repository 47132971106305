import _ from 'lodash';
import {
  call,
  put,
} from 'redux-saga/effects';

import api from '../../services/api';
import { setStores, setStoresLoading } from '../../actions';
import logger from '../../services/logger';

export default function* getStoresByBusiness(businessId) {
  yield put(setStoresLoading(true));

  try {
    let stores = yield call(api.getStores, businessId);
    stores = _.sortBy(_.filter(stores, (s) => !_.has(s, 'deletedOn')), 'name');
    yield put(setStores(stores));

    // logger.error({
    //   error: 'Token expired?',
    //   context: { saga: 'getStoresByBusiness', severity: 'debug' },
    //   params: { businessId, tokenExpired },
    // });

    yield put(setStoresLoading(false));
    return stores;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getStoresByBusiness' },
      params: { businessId },
    });
    yield put(setStoresLoading(false));
    return undefined;
  }
}
