import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import StoreCreatorModal from '@targetable/targetable-web-framework/lib/react/components/StoreCreatorModal/StoreCreatorModal';

import { selectShowAddBusinessModal } from '../../selectors';
import { addBusinessConfirm } from '../../actions';

const AddBusinessModal = () => {
  const { show } = useSelector(selectShowAddBusinessModal);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handlePlaceSelect = useCallback(({ name }) => {
    dispatch(addBusinessConfirm({ name }));
  }, [dispatch]);

  const handleCancel = useCallback(() => {
    dispatch(addBusinessConfirm(false));
  }, [dispatch]);

  if (!show) {
    return null;
  }

  return (
    <div data-cy="AddBusinessModal_component">
      <StoreCreatorModal
        confirmLabel={t('confirm')}
        onCancel={handleCancel}
        onSelect={handlePlaceSelect}
        open
        text={t('business_place_selector_title')}
        subtext={t('business_place_selector_subtitle')}
        textLabel={t('business_name')}
        title={t('add_business')}
      />
    </div>
  );
};

export default AddBusinessModal;
