import _ from 'lodash';
import { call, fork } from 'redux-saga/effects';

import { SUBMIT_USER_REGISTRATION } from '../../constants';
import createUser from '../createUser/createUser';
import updateUser from '../updateUser/updateUser';
import { takeAsyncAction } from '../helpers';
import auth from '../../services/auth';
import history from '../../services/history';
import { getSignUpSuccessRoute, getOnboardingRegistrationRoute } from '../../routes';
import api from '../../services/api';
import updateUserHubspotRecord from '../updateUserHubspotRecord/updateUserHubspotRecord';
import getActiveUserAndAllUsers from '../getActiveUserAndAllUsers/getActiveUserAndAllUsers';
import logger from '../../services/logger';

export default function* handleUserRegistration(user, hubSpotEmail, isInvite) {
  let userId = _.get(user, 'id');

  // handle new user registration
  if (_.isNil(userId)) {
    const authUser = auth.getUser();
    const email = hubSpotEmail || _.get(user, 'email') || _.get(authUser, 'email', '');
    const createdUser = yield call(createUser, {
      auth0Id: _.get(authUser, 'sub'),
      givenName: _.get(user, 'givenName') || _.get(authUser, 'given_name', ''),
      familyName: _.get(user, 'familyName') || _.get(authUser, 'family_name', ''),
      ...(!_.isEmpty(email) && { email }),
      phoneNumber: _.get(user, 'phoneNumber', ''),
    });

    // if a valid user is not returned, throw a terminal error as
    // this would infinitely loop if we re-ran this saga
    if (_.isNil(createdUser) || _.isEmpty(createdUser)) {
      throw new Error('User failed to be created');
    }

    userId = _.get(createdUser, 'id');
    try {
      yield call(api.createUserHubspotRecord, userId);
    } catch (e) {
      logger.error({
        error: `Failed to create user hubspot record: ${e?.message || ''}`,
        context: { saga: 'handleUserRegistration', severity: 'debug' },
        params: {
          user, hubSpotEmail,
        },
      });
    }
  }

  // Load the user's data in the state to be available in the onboarding form
  localStorage.setItem(`${user?.auth0Id}:last_viewed_user_id`, user?.id);
  yield call(getActiveUserAndAllUsers);

  if (isInvite) {
    history.push(getOnboardingRegistrationRoute());
  } else {
    history.push(getSignUpSuccessRoute());
  }

  yield fork(updateUserHubspotRecord, userId, { userInfoCollectionStarted: true });

  while (true) {
    const { payload: formData } = yield call(takeAsyncAction, SUBMIT_USER_REGISTRATION);
    const updatedUser = yield call(updateUser, { id: userId, ...formData });

    if (updatedUser) {
      yield fork(updateUserHubspotRecord, userId, { userInfoCollectionCompleted: true });
      break;
    }
  }
}
