import {
  call, put, select, all,
} from 'redux-saga/effects';
import _ from 'lodash';
import api from '../../services/api';
import { selectBusiness, selectSubscription } from '../../selectors';
import { showToaster } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import getStripeSubscription from '../getStripeSubscription/getStripeSubscription';
import createActivityLog from '../createActivityLog/createActivityLog';
import auth from '../../services/auth';

/**
 * Resumes a subscriptions collection that was paused.
 * @param {Function} onCompleted a function to invoke once the saga has completed successfully.
 * @param {Function} onError a function to invoke if the saga has not completed successfully.
 */
export default function* unpauseStripeSubscriptionCollectionSaga(payload = null) {
  let onCompleted = null;
  let onError = null;
  if (payload) {
    onCompleted = payload.onCompleted;
    onError = payload.onError;
  }

  yield put(showToaster(getToasterOptions(
    'Resuming the Payment Collection...',
    'info', null, 7000, true, null, 'bottom', 'right',
  )));

  const user = auth.getUser();
  const business = yield select(selectBusiness);
  const subscription = yield select(selectSubscription);
  const activeSubscriptions = _.uniqBy(
    // filter out canceled subscriptions because they can not be resumed
    _.filter(subscription?.stripeProducts, (item) => item.subscriptionId && item.status === 'active'),
    'subscriptionId',
  );

  try {
    const calls = [];
    _.forEach(activeSubscriptions, ({ subscriptionId }) => {
      calls.push(call(
        api.updateStripeSubscription,
        business?.stripeCustomerId,
        subscriptionId,
        {
          pause_collection: '',
          businessId: _.get(business, 'id'),
        },
      ));
    });
    yield all(calls);

    yield call(createActivityLog, {
      businessId: _.get(business, 'id'),
      userId: user?.sub,
      ui: {
        title: `Payment Collection Resumed for ${_.get(business, 'name')}`,
        description: `The Payment Collection for '${_.get(business, 'name')}' has been Resumed in User Portal by ${user?.email}.`,
      },
      type: 'resumed_payment_collection',
      meta: {
        user: user.email,
        entity: {
          entityId: business?.stripeCustomerId,
          action: 'UPDATE',
        },
      },
    });

    // reload subscriptions info
    yield call(getStripeSubscription, { payload: business?.stripeCustomerId });
    yield put(showToaster(getToasterOptions('The Payment Collection has been resumed.', 'success', null, 3000)));
    if (onCompleted) { onCompleted(); }
  } catch (ex) {
    logger.error({
      error: ex,
      context: { saga: 'unpauseStripeSubscriptionCollectionSaga' },
      params: { business, subscription },
    });
    yield put(showToaster(getToasterOptions('error_unpause_subscription_collection')));
    if (onError) { onError(); }
  }
}
