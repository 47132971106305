import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dinero from 'dinero.js';
import {
 CardElement, AddressElement, Elements, useStripe, useElements,
} from '@stripe/react-stripe-js';
import { makeStyles } from '@material-ui/core/styles';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import AsyncButton from '@targetable/targetable-web-framework/lib/react/components/AsyncButton/AsyncButton';
import TextField from '@targetable/targetable-web-framework/lib/react/components/TextField/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { STRIPE_DEFAULT_PRICE } from '../../../../constants';
import globals from '../../../../globals';
import api from '../../../../services/api';
import i18n from '../../../../services/i18n';
import logger from '../../../../services/logger';
import { createStripePaymentMethod } from '../../../../actions';
import {
  selectBusiness,
  selectIncludeCouponBillingModal,
  selectIsUpdateBillingModal,
  selectSubscription,
  selectStores,
} from '../../../../selectors';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 3),
    height: 'calc(100vh - 180px)',
    overflow: 'auto',
  },
  gridContainer: {
    margin: `${theme.spacing(4)}px auto 0`,
  },
  header: {
    marginBottom: theme.spacing(2),
    textAlign: 'left',
  },
  formBoxFocused: {
    padding: '18px 13.5px !important',
    border: `2px solid ${theme.palette.secondary.main} !important`,
    '&:hover': {
      border: `2px solid ${theme.palette.secondary.main} !important`,
    },
  },
  formBoxCard: {
    fontWeight: 300,
    padding: '18.5px 14px',
    color: theme.palette.text.primary,
    height: 56,
    lineHeight: '1.1876em',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    '&:hover': {
      border: `1px solid ${theme.palette.secondary.main}`,
    },
  },
  formBoxCardContainer: {
    // margin: theme.spacing(1, 0),
  },
  formBoxError: {
    color: '#9e2146',
  },
  selectIcon: {
    color: 'rgba(0,0,0,.54)',
  },
  termsText: {
    textAlign: 'left',
    margin: theme.spacing(1.25, 0, 0, 2),
  },
  addressElementWrapper: {
    paddingTop: '0 !important',
  },
}));

const StripeForm = ({
  businessId,
  createStripePaymentMethodAction,
  stripeCoupon,
}) => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const includeCoupon = useSelector(selectIncludeCouponBillingModal);
  const isUpdate = useSelector(selectIsUpdateBillingModal);
  const subscription = useSelector(selectSubscription);
  const stores = useSelector(selectStores) || [];

  const [coupon, setCoupon] = useState(includeCoupon ? stripeCoupon : '');
  const [percentOff, setPercentOff] = useState(null);
  const [name, setName] = useState('');
  const [country, setCountry] = useState('');
  const [street, setStreet] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [terms, setTerms] = useState(false);

  const [couponError, setCouponError] = useState(null);
  const [stripeError, setStripeError] = useState(true);

  const [formValid, setFormValid] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [digitalDiscoveryAmount, setDigitalDiscoveryAmount] = useState(0);

  useEffect(() => {
    const setDigitalDiscoveryPrice = async () => {
      try {
        const res = await api.getStripePrice(
          globals.STRIPE_PRICE_DIGITAL_DISCOVERY, { quantity: 1, businessId },
        );
        if (res.periodAmount) {
          setDigitalDiscoveryAmount(
            Dinero({ amount: Math.round(res.periodAmount), precision: 2 }).toFormat(),
          );
        } else {
          setDigitalDiscoveryAmount('$299.00');
        }
      } catch (err) {
        logger.error({
          error: err,
          context: { component: 'StripeForm' },
          params: {
            businessId, priceId: globals.STRIPE_PRICE_DIGITAL_DISCOVERY,
          },
        });
      }
    };

    if (!digitalDiscoveryAmount) {
      setDigitalDiscoveryPrice();
    }
  }, [digitalDiscoveryAmount, businessId]);

  let periodAmount = _.get(subscription, 'periodAmount', STRIPE_DEFAULT_PRICE);
  if (percentOff) {
    periodAmount -= _.get(subscription, 'periodAmount', STRIPE_DEFAULT_PRICE) * percentOff;
  }

  // Either the current subscription amount or the base fee x number of stores.
  const actualAmount = subscription?.periodAmount
    ? periodAmount : periodAmount * (stores.length || 1);

  // todo use effect for prop stripeCoupon
  const validateForm = useCallback(() => {
    if (stripeError) {
      setFormValid(false);
      return false;
    }
    if (couponError) {
      setFormValid(false);
      return false;
    }
    if (!name || !name.trim()) {
      setFormValid(false);
      return false;
    }
    if (!country || !country.trim()) {
      setFormValid(false);
      return false;
    }
    if (!street || !street.trim()) {
      setFormValid(false);
      return false;
    }
    if (!city || !city.trim()) {
      setFormValid(false);
      return false;
    }
    if (!state) {
      setFormValid(false);
      return false;
    }
    if (!zip || !zip.trim()) {
      setFormValid(false);
      return false;
    }

    setFormValid(terms);
    return !!terms;
  }, [city, name, state, stripeError, country, street, terms, zip, couponError]);

  useEffect(() => {
    validateForm();
  }, [validateForm, city, name, state, stripeError, country, street, terms, zip, couponError]);

  const handleTermsChange = (valSetter) => (e) => {
    valSetter(e.target.checked);
  };

  const handleCouponBlur = async (e) => {
    const couponText = e.target.value.trim();
    setCouponError(false);

    if (couponText) {
      setSubmitDisabled(true);

      try {
        const res = await api.getStripeCoupon(couponText, { businessId });
        if (res.valid) {
          setPercentOff(res.percent_off / 100);
        } else {
          setCouponError(true);
        }
      } catch (err) {
        logger.error({
          error: err,
          context: { component: 'StripeForm' },
          params: {
            businessId, coupon: couponText,
          },
        });
      }

      setSubmitDisabled(false);
    } else {
      setPercentOff(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    const cardElement = elements.getElement(CardElement);

    stripe.createToken(cardElement, {
      type: 'card',
      name,
      address_line1: street,
      address_line2: addressLine2,
      address_city: city,
      address_state: state,
      address_zip: zip,
      address_country: country,
    }).then((data) => {
      if (data.token) {
        createStripePaymentMethodAction({
          token: data.token,
          coupon,
          onError: () => {},
        });
      }
    });
  };

  const createOptions = (className) => ({
    classes: {
      base: className,
      focus: classes.formBoxFocused,
      invalid: classes.formBoxError,
    },
    style: {
      base: {
        fontFamily: 'Montserrat,sans-serif',
        fontSize: '1rem',
      },
    },
    hidePostalCode: true,
  });

  const addressOptions = {
    appearance: {
      rules: {
        '.Input': {
          padding: '17.5px 14px',
        },
        '.Input:focus': {
          border: '2px solid #007BFF',
          boxShadow: 'none',
        },
      },
    },
  };

  const strHeader = isUpdate ? 'stripe_subscription_header_update' : 'stripe_subscription_header';
  const strSubtext = isUpdate ? 'stripe_subscription_subtext_update' : 'stripe_subscription_subtext';

  return (
    <form className={classes.container} onSubmit={handleSubmit} noValidate autoComplete="off">
      <Typography variant="h2" className={classes.header}>
        {i18n.t(strHeader)}
      </Typography>
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{
        __html: i18n.t(strSubtext, {
          amount: digitalDiscoveryAmount,
          interpolation: { escapeValue: false },
        }),
      }}
      />
      {!isUpdate && (
        <>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              /* Removed the 'stripe_subscription_cost' lokalise key as per TGT-6009
               * but eave the fields just in case are required later */
              __html: i18n.t('', {
                cost: Dinero({ amount: Math.round(actualAmount), precision: 2 }).toFormat(),
                interpolation: { escapeValue: false },
              }),
            }}
          />
          <Typography variant="body1">
            <Link
              color="secondary"
              href="https://meetings.hubspot.com/targetable-support/targetable-troubleshooting"
              target="__blank"
            >
              { /* Removed the 'stripe_subscription_contact_us' lokalise key as per TGT-6009
                 * but eave the fields just in case are required later */ }
              {i18n.t('')}
            </Link>
          </Typography>
        </>
      )}
      <Grid container>
        <Grid item xs={12} className={classes.gridContainer}>
          <Grid container spacing={3}>
            <Grid xs={12} item className={classes.formBoxCardContainer}>
              <div>
                <CardElement
                  options={createOptions(classes.formBoxCard)}
                  onChange={(e) => {
                    setStripeError(!!e.error || !e.complete);
                  }}
                />
              </div>
            </Grid>
            {includeCoupon && (
              <Grid xs={12} item>
                <TextField
                  variant="outlined"
                  label={i18n.t('stripe_subscription_coupon_label')}
                  name="coupon"
                  InputLabelProps={{ required: false, shrink: true }}
                  InputProps={{ required: false }}
                  value={coupon}
                  error={couponError}
                  helperText={couponError ? i18n.t('coupon_code_invalid') : ''}
                  onChange={(e) => setCoupon(e.target.value)}
                  onBlur={handleCouponBlur}
                  fullWidth
                  autoComplete="off"
                  data-cy="stripe_subscription_coupon_label"
                />
              </Grid>
            )}
            <Grid xs={12} item className={classes.addressElementWrapper}>
              <Elements stripe={stripe} options={addressOptions}>
                <AddressElement
                  options={{
                    mode: 'billing',
                  }}
                  onChange={(e) => {
                    const {
                      country: addressCountry,
                      line1,
                      line2,
                      city: addressCity,
                      state: addressState,
                      postal_code: postalCode,
                    } = e.value.address;
                    setName(e.value.name);
                    setCountry(addressCountry);
                    setStreet(line1);
                    setAddressLine2(_.isNil(line2) ? '' : line2);
                    setCity(addressCity);
                    setState(addressState);
                    setZip(postalCode);
                    setStripeError(!!e.error || !e.complete);
                  }}
                />
              </Elements>
            </Grid>
            <Grid xs={12} item className={classes.termsTextContainer}>
              <Grid container align="center" justifyContent="center">
                <Grid item xs={1}>
                  <Checkbox
                    type="checkbox"
                    id="terms"
                    value={terms}
                    onChange={handleTermsChange(setTerms)}
                    required
                    data-cy="stripe_subscription_accept_terms"
                  />
                </Grid>
                <Grid item className={classes.termsText}>
                  <Typography variant="body2">
                    {`${i18n.t('stripe_terms_and_conditions')} `}
                    <Link
                      color="secondary"
                      href="https://www.targetable.com/terms-and-conditions"
                      target="__blank"
                    >
                      {i18n.t('terms_and_conditions')}
                    </Link>
                  </Typography>
                  <Typography variant="caption">
                    {i18n.t('stripe_subscription_caption')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item />
          </Grid>
        </Grid>
      </Grid>
      <Typography align="center">
        <AsyncButton
          className={classes.button}
          variant="contained"
          color="secondary"
          type="submit"
          disabled={!formValid || submitDisabled}
        >
          {i18n.t('submit')}
        </AsyncButton>
      </Typography>
    </form>
  );
};

StripeForm.propTypes = {
  businessId: PropTypes.string.isRequired,
  createStripePaymentMethodAction: PropTypes.func.isRequired,
  stripeCoupon: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const business = selectBusiness(state);
  return {
    businessId: _.get(business, 'id', ''),
    stripeCoupon: _.get(business, 'stripeCoupon', ''),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createStripePaymentMethodAction: createStripePaymentMethod,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StripeForm);
