import { DATA_SMARTFEED_ITEMS, DATA_SMARTFEED_LOADING } from '../constants';

export default {
  defaultState: {
    items: null,
    loading: false,
  },
  reducers: {
    [DATA_SMARTFEED_ITEMS]: (state, { payload }) => ({ ...state, items: payload }),
    [DATA_SMARTFEED_LOADING]: (state, { payload }) => ({ ...state, loading: payload }),
  },
};
