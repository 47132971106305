import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';

import { getPaidAdProductInfo, getPaidAAPlusProductInfo } from '@targetable/targetable-web-framework/lib/services/paywallUtils';
import { selectBusiness, selectSubscription } from '../../selectors';
import { setSubscription } from '../../actions';
import api from '../../services/api';

export default function* verifyAddStore() {
  const business = yield select(selectBusiness);
  const subscription = yield select(selectSubscription);
  let paidAdProductInfo = getPaidAdProductInfo(subscription);
  if (!paidAdProductInfo) {
    // Check then if user has AA PLUS product on Stripe
    paidAdProductInfo = getPaidAAPlusProductInfo(subscription);
  }

  const stripeCustomerId = _.get(business, 'stripeCustomerId');
  const subscriptionId = paidAdProductInfo?.subscriptionId;
  const quantity = _.get(paidAdProductInfo, 'quantity');

  const subscriptionPreview = yield call(
    api.getStripeUpdateSubscriptionPreview,
    stripeCustomerId,
    subscriptionId,
    { quantity: quantity + 1 },
  );
  yield put(setSubscription({
    periodAmountDifference: _.get(subscriptionPreview, 'periodAmountDifference'),
    prorationAmount: _.get(subscriptionPreview, 'prorationAmount'),
  }));
}
