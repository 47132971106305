import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import AsyncButton from '@targetable/targetable-web-framework/lib/react/components/AsyncButton/AsyncButton';

import { selectBusiness, selectShowAddBusinessConfirmModal } from '../../selectors';
import { continueAddBusiness } from '../../actions';

const useStyles = makeStyles((theme) => ({
  ol: {
    margin: 0,
    padding: `${theme.spacing(1)}px 0 0 ${theme.spacing(2)}px`,
  },
  li: {
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: '1rem',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  bulletTitle: {
    marginTop: theme.spacing(2),
  },
}));

const AddBusinessConfirmModal = () => {
  const show = useSelector(selectShowAddBusinessConfirmModal);
  const business = useSelector(selectBusiness);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const businessName = _.get(business, 'name', '');

  const handleContinue = useCallback(() => {
    dispatch(continueAddBusiness(true));
  }, [dispatch]);

  const handleCancel = useCallback(() => {
    dispatch(continueAddBusiness(false));
  }, [dispatch]);

  return (
    <Dialog
      onClose={handleCancel}
      maxWidth="sm"
      open={show}
      data-cy="component_AddBusinessConfirmModal"
    >
      <DialogContent>
        <Typography variant="subtitle1" className={classes.title}>
          {t('add_business_confirm_title')}
        </Typography>
        <Typography variant="body1">
          {t('add_business_confirm_text', { businessName })}
        </Typography>
        <Typography variant="subtitle1" className={classes.bulletTitle}>
          {t('add_business_confirm_bullet_title')}
        </Typography>
        <ol className={classes.ol}>
          <li className={classes.li}>
            <Typography component="span" variant="body1">
              {t('add_business_confirm_bullet_one')}
            </Typography>
          </li>
          <li className={classes.li}>
            <Typography component="span" variant="body1">
              {t('add_business_confirm_bullet_two')}
            </Typography>
          </li>
          <li className={classes.li}>
            <Typography component="span" variant="body1">
              {t('add_business_confirm_bullet_three')}
            </Typography>
          </li>
        </ol>
      </DialogContent>
      <DialogActions>
        <AsyncButton
          className={classes.button}
          variant="text"
          color="secondary"
          onClick={handleCancel}
          data-cy="cta_AddBusinessConfirmModal_cancel"
        >
          {t('cancel')}
        </AsyncButton>
        <AsyncButton
          className={classes.button}
          color="secondary"
          variant="text"
          onClick={handleContinue}
          data-cy="cta_AddBusinessConfirmModal_continue"
        >
          {t('continue')}
        </AsyncButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddBusinessConfirmModal;
