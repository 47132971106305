import _ from 'lodash';
import { call, put, select } from 'redux-saga/effects';

import { getUserFacebookIntegration } from '@targetable/targetable-web-framework/lib/services/user';

import api from '../../services/api';
import { showToaster, setFacebookTokenExpired } from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import { selectUser } from '../../selectors';

export default function* validateFacebookConnection(onDone) {
  const user = yield select(selectUser);
  const userId = _.get(user, 'id');
  const facebookIntegration = getUserFacebookIntegration(user);
  const accessToken = _.get(facebookIntegration, 'accessToken');
  let expired = null;
  let valid = null;

  if (accessToken) {
    try {
      valid = yield call(api.validateFacebookAccessToken, userId, accessToken);
      expired = !valid;
    } catch (e) {
      logger.error({
        error: e,
        context: { saga: 'validateFacebookConnection' },
        params: { userId },
      });
      yield put(showToaster(getToasterOptions('generic_request_error', 'error')));
    }
  }

  yield put(setFacebookTokenExpired(expired));

  if (onDone) {
    onDone(valid);
  }

  return valid;
}
