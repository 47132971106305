import {
  call, fork, put, select,
} from 'redux-saga/effects';
import _ from 'lodash';
import {
  getCampaign,
  getCampaignAudienceOptions,
} from '@targetable/targetable-web-framework/lib/react/campaignWizard/services/campaign/campaign';

import { MarketingChannelTypes } from '@targetable/targetable-types/dist/types/marketing-channel/types';
import { AdStatus } from '@targetable/targetable-types/dist/types/ad-service/types';

import {
  CAMPAIGN_SUBMITTED,
  CAMPAIGN_STATUS_LIVE,
  CAMPAIGN_STATUS_PAUSED,
} from '../../constants';
import {
  getCampaignWizardDetailRoute,
  getCampaignEditRoute,
  getCampaignCreateRoute,
  getGoogleCampaignDetailRoute,
  getMEMCampaignDetailRoute,
} from '../../routes';
import history from '../../services/history';
import {
  setCampaign, setStoresCustomAudiences, showToaster, closeToaster,
} from '../../actions';
import { selectBusiness, selectStores } from '../../selectors';
import localStorage from '../../services/localStorage';
import getCampaignAdCardPresets from '../getCampaignAdCardPresets/getCampaignAdCardPresets';
import getCampaignMetrics from '../getCampaignMetrics/getCampaignMetrics';
import getCampaignUrlPresets from '../getCampaignUrlPresets/getCampaignUrlPresets';
import getCustomAudiences from '../getCustomAudiences/getCustomAudiences';
import getAdMetrics from '../getAdMetrics/getAdMetrics';
import getSearchAdCombinations from '../getSearchAdCombinations/getSearchAdCombinations';

import { getToasterOptions } from '../helpers';
import api from '../../services/api';

export default function* openCampaignWizard({ payload: campaign }) {
  let selectedCampaign = campaign ? _.omit(campaign, 'businessName') : undefined;
  const currentUrl = `${window.location.pathname}${window.location.search || ''}`;
  localStorage.setItem('CREATE_CAMPAIGN_BACK_URL_KEY', currentUrl);

  // clear any previous data
  yield put(setCampaign(null));

  const business = yield select(selectBusiness);
  const stores = yield select(selectStores);

  // If campaign'prop isOptimized is present, it's because it came from Algolia
  // so we need to query the actual DDB record
  if (selectedCampaign?.isOptimized) {
    selectedCampaign = yield call(api.getCampaign, selectedCampaign?.id, stores);
    if (_.isEmpty(selectedCampaign)) {
      selectedCampaign = yield call(api.getAdById, selectedCampaign?.id);
    }
  }

  const updatedCampaign = getCampaign(business.id, stores, selectedCampaign);
  yield put(setCampaign(updatedCampaign));

  if (selectedCampaign?.type === MarketingChannelTypes.Google) {
    yield fork(getAdMetrics, selectedCampaign);
    yield fork(getSearchAdCombinations, selectedCampaign);
    if ([
      AdStatus.Submitted,
      AdStatus.Failed,
      AdStatus.SmartFeed,
      AdStatus.Live,
    ].includes(selectedCampaign?.status)) {
      history.push(getGoogleCampaignDetailRoute(business.id, selectedCampaign.id));
    } else {
      history.push(getCampaignWizardDetailRoute(business.id, selectedCampaign.id));
    }
    return;
  }

  if (selectedCampaign?.type === MarketingChannelTypes.MailChimp) {
    history.push(getMEMCampaignDetailRoute(business.id, selectedCampaign.id));
    return;
  }

  // test if the campaign is new
  if (!selectedCampaign) {
    yield put(showToaster(getToasterOptions('preparing_new_campaign', 'info', null, 10000)));
    yield call(getCampaignUrlPresets, {
      payload: {
        businessId: updatedCampaign.businessId,
        filter: true,
        backfill: true,
        reset: true,
      },
    });
    yield call(getCampaignAdCardPresets, updatedCampaign, true, true);
    yield call(getCustomAudiences, updatedCampaign.businessId, true);
    yield put(closeToaster());
    history.push(getCampaignCreateRoute());
    return;
  }

  yield put(showToaster(getToasterOptions('opening_existing_campaign', 'info', null, 10000)));

  /* Campaigns with statuses: CAMPAIGN_SUBMITTED_WITH_ISSUES and CAMPAIGN_SUBMITTED_FAILED
  * will be opened as editable.
  */
  if (selectedCampaign.status === CAMPAIGN_SUBMITTED
      || selectedCampaign.status === CAMPAIGN_STATUS_LIVE
      || selectedCampaign.status === CAMPAIGN_STATUS_PAUSED) {
    yield fork(getCampaignMetrics, selectedCampaign);

    yield call(getCampaignUrlPresets, {
      payload: {
        businessId: selectedCampaign.businessId,
        reset: true,
      },
    });
    yield call(getCampaignAdCardPresets, updatedCampaign, false, true);

    // get custom audience options from what was used in the campaign
    // We don't actually need to fetch custom audiences for read only
    yield put(setStoresCustomAudiences(getCampaignAudienceOptions(updatedCampaign)));

    history.push(getCampaignWizardDetailRoute(business.id, selectedCampaign.id));
    yield put(closeToaster());
    return;
  }

  yield call(getCampaignUrlPresets, {
    payload: {
      businessId: updatedCampaign.businessId,
      filter: true,
      backfill: true,
      reset: true,
    },
  });
  yield call(getCampaignAdCardPresets, updatedCampaign, true, true);
  yield call(getCustomAudiences, updatedCampaign.businessId, true);
  yield put(closeToaster());

  history.push(getCampaignEditRoute(updatedCampaign.id));
}
