import { call, put } from 'redux-saga/effects';

import api from '../../services/api';
import history from '../../services/history';

import { setBusiness } from '../../actions';
import { getRejectedRoute } from '../../routes';
import logger from '../../services/logger';

export default function* getBusiness(id) {
  try {
    const business = yield call(api.getBusiness, id);
    yield put(setBusiness(business));
    return business;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getBusiness' },
      params: { businessId: id },
    });
    history.push(getRejectedRoute());
    return undefined;
  }
}
