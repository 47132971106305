import { DATA_INITIAL_IMAGES, DATA_INITIAL_IMAGES_CURSOR, GET_IMAGES } from '../constants';

export default {
  defaultState: {
    initialCursor: null,
    initialImages: [],
    loading: false,
  },
  reducers: {
    [GET_IMAGES]: (state) => ({ ...state, loading: true, initialImages: [] }),
    [DATA_INITIAL_IMAGES]: (state, { payload }) => ({
      ...state, loading: false, initialImages: payload,
    }),
    [DATA_INITIAL_IMAGES_CURSOR]: (state, { payload }) => ({ ...state, initialCursor: payload }),
  },
};
