import _ from 'lodash';
import {
  all,
  call,
  fork,
  put,
  select,
  take,
} from 'redux-saga/effects';
import {
  removeTargetingPropsFromAds,
} from '@targetable/targetable-web-framework/lib/react/campaignWizard/services/campaign/campaign';

import i18n from '../../services/i18n';
import api from '../../services/api';
import { selectRouterLocationPath, selectStores, selectUser } from '../../selectors';
import {
  showToaster,
  setConfirmationDialog,
  setLoadingAsync,
  showConfirmationDialog,
} from '../../actions';
import logger from '../../services/logger';
import saveCampaign from '../saveCampaign/saveCampaign';
import { getToasterOptions } from '../helpers';
import {
  RESULT_CONFIRMATION_DIALOG,
} from '../../constants';
import validateCampaignUrlPresets from '../validateCampaignUrlPresets/validateCampaignUrlPresets';
import ensureAtLeastOneEnabledStore from '../ensureAtLeastOneEnabledStore/ensureAtLeastOneEnabledStore';

export default function* submitCampaign(
  campaign,
  onError,
  validatedCampaign,
  fromSuggestedCampaign = false,
) {
  const publishMessage = fromSuggestedCampaign
  ? 'Publishing suggested campaign...'
  : 'Publishing campaign...';
  yield put(showToaster(getToasterOptions(publishMessage,
    'publish', null, 7000)));

  const user = yield select(selectUser);
  const id = campaign?.userId || _.get(user, 'id');
  const clonedCampaign = _.cloneDeep(campaign);

  const stores = yield select(selectStores);

  // Validate the current status to prevent duplicate submissions
  if (campaign.suggestedCampaignId && fromSuggestedCampaign) {
    const latestCampaign = yield call(api.getSuggestedCampaign, campaign.suggestedCampaignId);
    if (latestCampaign?.status === 'approved' || latestCampaign?.status === 'rejected') {
      return { submitted: false, error: 'already_published' };
    }
  }

  // Checks if at least one of the associated stores is enabled
  const atLeastOneEnabledStore = yield call(ensureAtLeastOneEnabledStore, {
    campaign,
    onDone: onError,
  });
  if (!atLeastOneEnabledStore) {
    return { submitted: false, error: 'disabled_stores' };
  }

  // Only perform following validations if is not a Suggested Campaign,
  // due to those are already validated in the handleFeedbackDialog saga
  if (!validatedCampaign) {
    const presetsValid = yield call(
      validateCampaignUrlPresets,
      campaign.businessId,
      campaign,
    );

    const campaignCTA = _.get(campaign, 'ads.0.ad.callToAction');
    if (!presetsValid.valid && campaignCTA !== 'CALL_NOW') {
      if (presetsValid.error === 'invalid_preset') {
        yield put(setLoadingAsync(false));
        const pathname = yield select(selectRouterLocationPath);
        const inFormView = pathname.includes('campaigns/suggested');

        // When backDrop is clicked, it should returns the 'cancel' string
        // in the RESULT_CONFIRMATION_DIALOG
        yield put(setConfirmationDialog({
          resultOnClose: 'cancel',
        }));

        yield put(showConfirmationDialog({
          text: 'url_preset_publish_missing_error',
          confirmText: 'edit',
          cancelText: 'continue',
          onClick: _.identity,
        }));

        // true is edit, false is continue
        const { payload: confirm } = yield take(RESULT_CONFIRMATION_DIALOG);

        // Cancel the publishing
        if (confirm === 'cancel') return { submitted: false, error: 'canceled' };

        yield put(setLoadingAsync(true));

        if (confirm) {
          if (inFormView) {
            return { submitted: false, error: 'invalid_preset' };
          }
          // todo determine which route
          // history.push(get(campaign.businessId, campaign?.id));
          return { submitted: false, error: 'invalid_preset' };
        }
      } else {
        return { submitted: false, error: presetsValid.error };
      }
    }
  }
  // clean up campaign targeting
  if (clonedCampaign.goal === 'hiring') {
    clonedCampaign.ads = removeTargetingPropsFromAds(clonedCampaign.ads, ['minAge', 'maxAge', 'gender']);
  }

  // ensure goal hiring/housing to 15
  if (campaign.goal === 'hiring' || campaign.goal === 'housing') {
    campaign.ads.forEach((ad) => {
      if (ad.targeting.radius < 15) {
        _.set(ad, 'targeting.radius', 15);
      }
    });
  }

  let savedCampaign = yield call(saveCampaign, {
    ...campaign,
    userId: id,
  });

  if (!savedCampaign) {
    // eslint-disable-next-line consistent-return
    return { submitted: false, error: 'save_error' };
  }

  // get the latest campaign data if the campaign was updated
  if (campaign.id) {
    savedCampaign = yield call(api.getCampaign, campaign.id, stores);
  }

  try {
    yield call(api.submitCampaign, savedCampaign.id);
    yield fork(api.updateBusinessHubspotRecord, _.get(campaign, 'businessId'), { businessTargetableCampaignPublished: true });
    yield all(_.map(_.get(savedCampaign, 'ads'), (ad) => fork(api.updateStoreHubspotRecord, _.get(ad, 'storeId'), { storeTargetableAdPublished: true })));

    if (!fromSuggestedCampaign) {
      yield put(showToaster(getToasterOptions('Campaign has been published!.', 'success', null, 5000)));
    }

    // eslint-disable-next-line consistent-return
    return { submittedCampaign: savedCampaign, submitted: true, error: false };
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'submitCampaign' },
      params: { userId: id },
    });
    if (!fromSuggestedCampaign) {
      yield put(showToaster(getToasterOptions(i18n.t('error_publishing_campaign', { reason: e?.message }), 'error')));
    }
    // eslint-disable-next-line consistent-return
    return { submitted: false, error: 'error' };
  }
}
