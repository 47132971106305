import {
  put, select, all, call,
} from 'redux-saga/effects';

import { setStoreFacebookInfoLoading, showAccountIssuesModal } from '../../actions';
import { selectFacebookTokenExpired } from '../../selectors';
import logger from '../../services/logger';
import getStoresFacebookPermissions from '../getStoresFacebookPermissions/getStoresFacebookPermissions';
import getFacebookStoresPaymentMethods from '../getFacebookStoresPaymentMethods/getFacebookStoresPaymentMethods';

export default function* getStoresFacebookInfo({ payload: businessId }) {
  yield put(setStoreFacebookInfoLoading(true));

  try {
    const tokenExpired = yield select(selectFacebookTokenExpired);

    if (tokenExpired === false) {
      yield all([
        call(getStoresFacebookPermissions),
        call(getFacebookStoresPaymentMethods),
      ]);
    }

    yield put(showAccountIssuesModal(false));
    yield put(setStoreFacebookInfoLoading(false));
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getStoresFacebookInfo' },
      params: { businessId },
    });
    yield put(setStoreFacebookInfoLoading(false));
  }
}
