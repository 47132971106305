import _ from 'lodash';
import { put, select, fork } from 'redux-saga/effects';
import moment from 'moment';
import {
  SUGGEST_CAMPAIGN_STATUS_PUBLISHED,
  INSIGHT_STATUS_PUBLISHED,
} from '@targetable/targetable-web-framework/lib/react/constants';
import dateService from '@targetable/targetable-web-framework/lib/services/date';

import api from '../../services/api';
import { setSmartFeedItems, setSmartFeedLoading } from '../../actions';
import { selectStores, selectUser, selectBusiness } from '../../selectors';
import logger from '../../services/logger';
import { getNotExpiredInsighstOrSuggestedCampaigns } from '../helpers';
import getAllActivityLogsByBusiness from '../getAllActivityLogsByBusiness/getAllActivityLogsByBusiness';

export default function* getSmartFeed() {
  yield put(setSmartFeedLoading(true));
  yield put(setSmartFeedItems(null));

  const user = yield select(selectUser);
  const stores = yield select(selectStores);
  const business = yield select(selectBusiness);

  try {
    let [suggestedCampaigns, insights] = yield Promise.all([
      api.getSuggestedCampaigns(user.businessId, stores, SUGGEST_CAMPAIGN_STATUS_PUBLISHED),
      api.getInsights(user.businessId, INSIGHT_STATUS_PUBLISHED),
    ]);

    const now = moment.utc();

    if (!_.get(business, ['featureFlags', 'overrideAutoAgingInsights'], false)) insights = getNotExpiredInsighstOrSuggestedCampaigns(insights);
    if (!_.get(business, ['featureFlags', 'overrideAutoAgingSuggestedCampaigns'], false)) suggestedCampaigns = getNotExpiredInsighstOrSuggestedCampaigns(suggestedCampaigns);

    const smartFeedItems = suggestedCampaigns.concat(
      insights,
    );

    const filteredItems = _.filter(smartFeedItems, (item) => {
      if (item.type && _.includes(['google', 'tiktok'], _.get(item, 'type'))) return true;
      const year = _.get(item, 'displayDate.year');
      const month = _.get(item, 'displayDate.month');
      const day = _.get(item, 'displayDate.day');
      const hour = _.get(item, 'displayTime.hour');
      const minute = _.get(item, 'displayTime.minute');

      if (_.isNil(year) || _.isNil(month) || _.isNil(day) || _.isNil(hour) || _.isNil(minute)) {
        return false;
      }

      const dateTime = dateService.getUTCDateTimeFromObject(item.displayDate, item.displayTime);

      return now.diff(dateTime) >= 0;
    });

    const sortedSmartFeed = _.sortBy(filteredItems, (item) => {
      const dateStr = `${_.get(item, 'displayDate.year')}/${_.get(item, 'displayDate.month')}/${_.get(item, 'displayDate.day')}`;
      const time = `${_.get(item, 'displayTime.hour')}:${_.get(item, 'displayTime.minute')}`;
      return `${dateStr} ${time}`;
    }).reverse();

    yield put(setSmartFeedItems(sortedSmartFeed));
    yield put(setSmartFeedLoading(false));

    if (business) {
      yield fork(getAllActivityLogsByBusiness, business?.id);
    }

    return sortedSmartFeed;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getSmartFeed' },
      params: { user, stores },
    });
    yield put(setSmartFeedLoading(false));
    return undefined;
  }
}
