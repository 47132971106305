import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import useToggle from 'react-use/lib/useToggle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import AsyncButton from '@targetable/targetable-web-framework/lib/react/components/AsyncButton/AsyncButton';
import ComponentLoading from '@targetable/targetable-web-framework/lib/react/components/ComponentLoading/ComponentLoading';

import { selectPublishMarketingDialogInformation } from '../../selectors';
import { resultPublishMarketingDialog } from '../../actions';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(2, 0),
  },
  customTextarea: {
    width: '100%',
    marginTop: theme.spacing(2),
    padding: 10,
    border: '1px solid #D8D9DF',
    borderRadius: 5,
  },
  confirmBtn: {
    margin: '0 15px 10px',
  },
  closeIcon: {
    position: 'absolute',
    right: 18,
    cursor: 'pointer',
  },
  userTokenWrapper: {
    marginTop: 15,
  },
  email: {
    color: '#8c8f9f',
  },
}));

const PublishMarketingDialog = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [confirmDisabled, toggleConfirmDisabled] = useToggle(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const information = useSelector(selectPublishMarketingDialogInformation);

  const {
    isOpen,
    confirmText,
    confirmDataCy,
    text,
    title,
    textParams,
    titleParams,
    resultOnClose,
    users,
  } = information;
  const dispatch = useDispatch();

  const onClick = useCallback(
    (result) => dispatch(resultPublishMarketingDialog(result)),
    [dispatch],
  );

  useEffect(() => {
    if (!isOpen && confirmDisabled) {
      toggleConfirmDisabled(false);
    }
  }, [confirmDisabled, isOpen, toggleConfirmDisabled]);

  const handleConfirm = useCallback(() => {
    toggleConfirmDisabled(true);
    onClick({
      userId: selectedUser.id,
    });
  }, [toggleConfirmDisabled, onClick, selectedUser]);

  const handleBackdropClick = () => {
    if (resultOnClose) {
      return onClick(resultOnClose);
    }
    return onClick(false);
  };

  const showConfirmationFooter = () => (
    <>
      <AsyncButton
        onClick={handleConfirm}
        disabled={confirmDisabled || _.isEmpty(selectedUser)}
        color="secondary"
        variant="contained"
        data-cy={confirmDataCy}
        className={classes.confirmBtn}
      >
        {t(confirmText)}
      </AsyncButton>
    </>
  );

  const handleChange = (e, option) => {
    setSelectedUser(option);
  };

  const getName = (givenName, familyName) => `${givenName} ${familyName}` || null;

  return (
    <Dialog
      onClose={handleBackdropClick}
      maxWidth="sm"
      open={isOpen}
    >
      <DialogContent>
        <CloseIcon className={classes.closeIcon} onClick={() => onClick(false)} />
        {title && <Typography variant="h4" className={classes.title}>{t(title, titleParams)}</Typography>}
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: t(text, {
              ...(textParams || {}),
              interpolation: { escapeValue: false },
            }),
          }}
        />
        <Grid xs={12} item className={classes.userTokenWrapper}>
          <Autocomplete
            value={selectedUser}
            options={_.uniqBy(users,
              (user) => [user?.email, user?.auth0Id, user?.isConnectedToFacebook].join())}
            loading={false}
            loadingText={<ComponentLoading waitKey="loading" loading>{null}</ComponentLoading>}
            onChange={handleChange}
            getOptionLabel={(option) => getName(option.givenName, option.familyName)}
            getOptionSelected={(option) => option?.id === selectedUser?.id}
            getOptionDisabled={
              (option) => !option.onboarded
                || !option.isConnectedToFacebook
            }
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                InputLabelProps={{ shrink: true }}
                label="User"
                variant="outlined"
                autoFocus
                fullWidth
                required
              />
            )}
            renderOption={(option) => (
              <Grid container spacing={0.5} justifyContent="left" data-cy="publishMarketingDialog_user_item">
                <Grid item xs={8} md={8} lg={8}>
                  <div>{getName(option.givenName, option.familyName)}</div>
                  <Typography className={classes.email} component="div" variant="caption">{option.email}</Typography>
                  <Typography className={classes.email} component="div" variant="caption">{option.isConnectedToFacebook ? '(Facebook Token integrated)' : '(No Facebook token integrated)'}</Typography>
                </Grid>
              </Grid>
            )}
            filterOptions={(options, { inputValue }) => _.filter(
              options,
              (option) => (getName(option.givenName, option.familyName))
                .toLowerCase().includes(inputValue.toLowerCase())
                || option?.email?.toLowerCase().includes(inputValue.toLowerCase()),
            )}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        {showConfirmationFooter()}
      </DialogActions>
    </Dialog>
  );
};

export default PublishMarketingDialog;
