import _ from 'lodash';
import moment from 'moment';
import { call, select } from 'redux-saga/effects';
import {
  removeTargetingPropsFromAds,
} from '@targetable/targetable-web-framework/lib/react/campaignWizard/services/campaign/campaign';
import { CAMPAIGN_DRAFT, CAMPAIGN_SUBMITTED } from '@targetable/targetable-web-framework/lib/react/constants';

import api from '../../services/api';
import logger from '../../services/logger';
import {
  CAMPAIGN_SUBMITTED_WITH_ISSUES,
  CAMPAIGN_SUBMITTED_FAILED,
} from '../../constants';
import { selectUser, selectStores } from '../../selectors';

export default function* saveCampaign(campaign) {
  const clonedCampaign = _.cloneDeep(campaign);
  const user = yield select(selectUser);
  const stores = yield select(selectStores);
  const userId = _.get(user, 'id');
  let ads;

  // clean up campaign targeting
  if (clonedCampaign.goal === 'hiring') {
    clonedCampaign.ads = removeTargetingPropsFromAds(clonedCampaign.ads, ['minAge', 'maxAge', 'gender']);
  }

  if (!campaign?.type) {
    // Ensure only activated stores
    ads = _.filter(campaign?.ads, (ad) => {
      const { storeId } = ad;
      const store = _.find(stores, { id: storeId });
      return !store?.disabled;
    });
  }

  try {
    let updatedCampaign;
    if (!campaign.id) {
      const savedCampaign = yield call(
        api.createCampaign,
        { ...campaign, created: moment.utc().valueOf(), ...(ads && { ads }) },
      );
      updatedCampaign = { ...savedCampaign, ...campaign };
    } else {
      /**
       * Keep the integrations object when updating the campaing
       * because this is an edition of the campaign after try publishing
       */
      const keepCurrentIntegrations = [
        CAMPAIGN_SUBMITTED_FAILED,
        CAMPAIGN_SUBMITTED_WITH_ISSUES,
      ].includes(campaign.status);
      updatedCampaign = {
        ...campaign,
        ...(ads && { ads }),
        ...(keepCurrentIntegrations && { keepCurrentIntegrations: true }),
        ...(campaign.status === CAMPAIGN_SUBMITTED_FAILED && {
          status: CAMPAIGN_DRAFT,
          userId, // Update in case the user is  the one with issues
        }),
        ...(campaign.status === CAMPAIGN_SUBMITTED_WITH_ISSUES && {
          status: CAMPAIGN_SUBMITTED,
          userId, // Update in case the user is  the one with issues
        }),
      };
      yield call(api.updateCampaign, campaign.id, updatedCampaign);
      updatedCampaign = campaign;
    }

    return updatedCampaign;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'saveCampaign' },
    });
    return false;
  }
}
