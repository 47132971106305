import _ from 'lodash';
import { call, put, delay } from 'redux-saga/effects';
import sanitizeString from '@targetable/targetable-web-framework/lib/utils/sanitizeString';

import api from '../../services/api';
import {
  setInitialImages,
  setInitialImagesCursor,
  showToaster,
} from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';

export default function* getImages({ payload }) {
  const {
    businessId,
    term,
    onError,
  } = payload;
  yield delay(3000);

  try {
    const { images, cursor } = yield call(api.getImages, businessId, sanitizeString(term));

    yield put(setInitialImagesCursor(cursor));
    yield put(setInitialImages(images));
  } catch (e) {
    yield put(setInitialImagesCursor(null));
    yield put(setInitialImages([]));
    yield put(showToaster(getToasterOptions('error_whoops')));

    if (_.isFunction(onError)) {
      onError(true);
    }

    logger.error({
      error: e,
      context: { saga: 'getImages' },
      params: { payload },
    });
  }
}
