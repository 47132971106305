import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import { getFullName } from '@targetable/targetable-web-framework/lib/utils/user';
import api from '../../services/api';
import { showToaster } from '../../actions';
import i18n from '../../services/i18n';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import { selectUser } from '../../selectors';

export default function* reinviteUser(action) {
  const { id, businessId } = yield select(selectUser);
  const user = action.payload;
  const name = getFullName(user);
  const email = _.get(user, 'email');
  try {
    yield call(api.reinviteUser, businessId, user.id);
    // yield call(api.createUserLogs, id, {
    //   timestamp: new Date().valueOf(),
    //   userId: id,
    //   viewedUrl: window.location.href,
    //   invitedUser: user.id,
    // });
    yield put(showToaster(getToasterOptions(i18n.t('user_reinvited_successfully', { name, email }), 'info', null, 5000)));
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'reinviteUser' },
      params: {
        userId: id, businessId, user, name, email,
      },
    });
    yield put(showToaster(getToasterOptions(i18n.t('error_reinvite_user', { name, email }))));
  }
}
