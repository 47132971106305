import React from 'react';
import { useSelector } from 'react-redux';
import { Backdrop, CircularProgress } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

import { selectShowBackdrop } from '../../selectors';

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 1400,
  },
  progressColor: {
    color: '#fff',
  },
}));

const BackdropWrapper = () => {
  const classes = useStyles();
  const showBackdrop = useSelector(selectShowBackdrop) || false;

  return (
    <Backdrop
      className={classes.root}
      open={showBackdrop}
      addEndListener={() => {}}
    >
      <CircularProgress className={classes.progressColor} />
    </Backdrop>
  );
};

export default BackdropWrapper;
