import _ from 'lodash';
import moment from 'moment';
import {
  call, put, select, take,
} from 'redux-saga/effects';
import {
  INSIGHT_STATUS_APPROVED,
  INSIGHT_STATUS_REJECTED,
  SUGGEST_CAMPAIGN_STATUS_APPROVED,
  SUGGEST_CAMPAIGN_STATUS_REJECTED,
} from '@targetable/targetable-web-framework/lib/react/constants';
import convertSuggestedToDraftSchedule from '@targetable/targetable-web-framework/lib/react/campaignWizard/services/convertSuggestedToDraftSchedule/convertSuggestedToDraftSchedule';
import { InsightTypes } from '@targetable/targetable-types/dist/types/insight';
import { SlackTemplateType, ActivityLogType } from '@targetable/targetable-types/dist/types';

import {
  CAMPAIGN_DRAFT,
  DRAFT_TAB,
  LIVE_TAB,
  SMARTFEED_ACTION_APPROVED,
  SMARTFEED_ACTION_APPROVED_DRAFT,
  SMARTFEED_ACTION_REJECTED,
  SMARTFEED_TYPE_INSIGHT,
  SMARTFEED_TYPE_SUGGESTED_CAMPAIGN,
  SMARTFEED_TYPE_SUGGESTED_CAMPAIGN_CARD,
  BUSINESS_INSIGHTS_TAB,
  SUBSCRIPTION_CANCELLATION,
  CANCEL_SUBSCRIPTION,
  REMOVE_ITEMS,
  RESULT_CONFIRMATION_DIALOG,
} from '../../constants';

import {
  closeFeedbackDialog, showToaster, showConfirmationDialog, setLoadingAsync,
} from '../../actions';
import api from '../../services/api';
import i18n from '../../services/i18n';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import getSmartFeed from '../getSmartFeed/getSmartFeed';
import saveCampaign from '../saveCampaign/saveCampaign';
import {
  getBusinessProfileRoute, getHomeRoute, getCampaignsRoute,
} from '../../routes';
import history from '../../services/history';
import { selectUser, selectBusiness } from '../../selectors';
import submitCampaign from '../submitCampaign/submitCampaign';
import deleteStripeSubscriptionItem from '../deleteStripeSubscriptionItem/deleteStripeSubscriptionItem';
import cancelStripeSubscription from '../cancelStripeSubscription/cancelStripeSubscription';
import createActivityLog from '../createActivityLog/createActivityLog';

export default function* submitFeedback({ payload }) {
  const {
    action,
    campaign,
    data,
    onError,
    type,
    validatedCampaign,
    onCompleted,
    onCancellingError,
    subscriptionItems,
  } = payload;

  const user = yield select(selectUser);

  const business = yield select(selectBusiness);
  const businessId = _.get(business, 'id');

  const clonedData = _.cloneDeep(data);
  clonedData.feedback.timestamp = moment.utc().valueOf();
  clonedData.feedback.userId = user.id;

  try {
    if (type === SMARTFEED_TYPE_INSIGHT) {
      let insightToastMessage = '';
      if (action === SMARTFEED_ACTION_APPROVED || SMARTFEED_ACTION_APPROVED_DRAFT) {
        clonedData.status = INSIGHT_STATUS_APPROVED;
        insightToastMessage = 'insight_approval';

        if (clonedData.type === InsightTypes.CampaignUpdate
          && clonedData?.campaignUpdate?.updateType === 'budget') {
          insightToastMessage = 'insight_budget_approval';
        }
        if (clonedData.type === InsightTypes.CampaignUpdate
          && clonedData?.campaignUpdate?.updateType === 'headlines_descriptions') {
          insightToastMessage = 'insight_headlines_descriptions_approval';
        }
        if (clonedData.type === InsightTypes.CampaignUpdate
          && clonedData?.campaignUpdate?.updateType === 'locations') {
          insightToastMessage = 'insight_locations_approval';
        }
      }

      if (action === SMARTFEED_ACTION_REJECTED) {
        clonedData.status = INSIGHT_STATUS_REJECTED;
        insightToastMessage = 'insight_rejection';
      }
      yield put(showToaster(getToasterOptions(insightToastMessage, 'info', null, 20000)));

      clonedData.responded = moment.utc().valueOf();
      yield call(api.updateInsight, clonedData.id, clonedData);
      yield put(closeFeedbackDialog());
      yield call(getSmartFeed);

      if (action === SMARTFEED_ACTION_APPROVED) {
        yield put(showToaster(getToasterOptions(
          'insight_saved_to_business_profile',
          'info',
          {
            title: 'go_to_business_profile',
            href: getBusinessProfileRoute(businessId, BUSINESS_INSIGHTS_TAB),
          },
          5000,
        )));
      }

      if (typeof onCompleted === 'function') {
        onCompleted();
      }
    }
    if (type === SMARTFEED_TYPE_SUGGESTED_CAMPAIGN
      || type === SMARTFEED_TYPE_SUGGESTED_CAMPAIGN_CARD) {
      // handle save draft from card and detail flow
      if (action === SMARTFEED_ACTION_APPROVED_DRAFT) {
        let savedCampaign;
        if (campaign) {
          // handle saving from the campaign form
          savedCampaign = yield call(saveCampaign, {
            businessId: campaign.businessId,
            status: CAMPAIGN_DRAFT,
            suggestedCampaignId: data.id,
            publishedAsPaused: !!campaign.publishedAsPaused,
            name: campaign.name,
            goal: campaign.goal,
            objective: campaign.objective,
            overrideAutoAging: campaign.overrideAutoAging,
            ...(campaign.userId ? { userId: campaign.userId } : {}),
            ads: campaign.ads,
            ...(campaign.requestInfo ? { requestInfo: campaign.requestInfo } : {}),
            ...(campaign.pixelEventType ? { pixelEventType: campaign.pixelEventType } : {}),
          });
        } else {
          // handle saving directly from the card
          savedCampaign = yield call(saveCampaign, {
            businessId: campaign.businessId,
            status: CAMPAIGN_DRAFT,
            suggestedCampaignId: data.id,
            name: data.name,
            goal: data.goal,
            objective: data.objective,
            overrideAutoAging: data.overrideAutoAging,
            ...(data.pixelEventType ? { pixelEventType: data.pixelEventType } : {}),
            ads: data.ads.map((adItem) => ({
              ...adItem,
              schedule: convertSuggestedToDraftSchedule(adItem.schedule),
            })),
            ...(campaign.requestInfo ? { requestInfo: campaign.requestInfo } : {}),
            ...(campaign.userId ? { userId: campaign.userId } : {}),
          });
        }

        if (!savedCampaign) {
          yield put(showToaster(getToasterOptions('saving_campaign_draft_from_suggested_error', 'error')));
          onError();
          return;
        }

        clonedData.status = SUGGEST_CAMPAIGN_STATUS_APPROVED;
        clonedData.responded = moment.utc().valueOf();
        yield call(api.updateSuggestedCampaign, clonedData.id, clonedData);
        yield put(closeFeedbackDialog());
        history.push(getHomeRoute(businessId));
        yield call(getSmartFeed);

        yield put(showToaster(getToasterOptions(
          i18n.t('suggested_campaign_draft_saved', { name: clonedData.name }),
          'info',
          {
            title: 'go_to_drafts',
            href: getCampaignsRoute(DRAFT_TAB),
          },
          5000,
        )));
      }

      // handle publish from detail flow
      if (action === SMARTFEED_ACTION_APPROVED) {
        let submittedCampaign;
        if (campaign) {
          // handle publishing from the campaign form
          // the schedule is already converted
          submittedCampaign = yield call(submitCampaign, {
            businessId: campaign.businessId,
            status: CAMPAIGN_DRAFT,
            suggestedCampaignId: data.id,
            publishedAsPaused: !!campaign.publishedAsPaused,
            name: campaign.name,
            goal: campaign.goal,
            objective: campaign.objective,
            overrideAutoAging: campaign.overrideAutoAging,
            ...(campaign.pixelEventType ? { pixelEventType: campaign.pixelEventType } : {}),
            ads: campaign.ads,
            ...(campaign.requestInfo ? { requestInfo: campaign.requestInfo } : {}),
            ...(campaign.userId ? { userId: campaign.userId } : {}),
          }, onError, validatedCampaign, true);
        } else {
          // handle publishing directly from the card
          submittedCampaign = yield call(submitCampaign, {
            businessId: campaign.businessId,
            status: CAMPAIGN_DRAFT,
            suggestedCampaignId: data.id,
            name: data.name,
            goal: data.goal,
            objective: data.objective,
            overrideAutoAging: data.overrideAutoAging,
            ...(data.pixelEventType ? { pixelEventType: data.pixelEventType } : {}),
            ads: data.ads.map((adItem) => ({
              ...adItem,
              schedule: convertSuggestedToDraftSchedule(adItem.schedule),
            })),
            ...(campaign.requestInfo ? { requestInfo: campaign.requestInfo } : {}),
            ...(campaign.userId ? { userId: campaign.userId } : {}),
          }, onError, validatedCampaign, true);
        }

        if (submittedCampaign.error) {
          if (submittedCampaign.error === 'already_published') {
            yield put(showToaster(getToasterOptions(i18n.t('campaign_already_published_toaster'), 'error')));
            onError(submittedCampaign.error);
          } else {
            yield put(showToaster(getToasterOptions('submit_campaign_from_suggested_error', 'error')));
            onError(submittedCampaign.error);
          }

          return;
        }

        if (submittedCampaign.submitted) {
          clonedData.status = SUGGEST_CAMPAIGN_STATUS_APPROVED;
          clonedData.responded = moment.utc().valueOf();
          yield call(api.updateSuggestedCampaign, clonedData.id, clonedData);
          yield put(closeFeedbackDialog());
          history.push(getHomeRoute(businessId));
          yield call(getSmartFeed);
          yield put(showToaster(getToasterOptions(
            i18n.t('suggested_campaign_submitted', { name: clonedData.name }),
            'info',
            {
              title: 'go_to_campaigns',
              href: getCampaignsRoute(LIVE_TAB),
            },
            5000,
          )));
        }
      }

      // handle dismiss from the card flow
      if (action === SMARTFEED_ACTION_REJECTED) {
        clonedData.status = SUGGEST_CAMPAIGN_STATUS_REJECTED;
        clonedData.responded = moment.utc().valueOf();
        yield call(api.updateSuggestedCampaign, clonedData.id, clonedData);
        yield put(closeFeedbackDialog());
        history.push(getHomeRoute(businessId));
        yield call(getSmartFeed);
      }
    }
    if (type === SUBSCRIPTION_CANCELLATION) {
      yield put(setLoadingAsync(false));
      if (action === CANCEL_SUBSCRIPTION) {
        yield put(showConfirmationDialog({
          title: 'cancel_subscription',
          text: 'cancel_subscription_confirmation_for_user',
          confirmText: 'Yes',
          confirmDataCy: 'cta-cancel_subscription_yes',
          cancelText: 'No',
          cancelDataCy: 'cta-cancel_subscription_no',
          onClick: _.identity,
        }));
        const { payload: confirm } = yield take(RESULT_CONFIRMATION_DIALOG);
        if (confirm) {
          yield call(cancelStripeSubscription, {
            onCompleted,
            onError: onCancellingError,
          });
        }
      }
      if (action === REMOVE_ITEMS) {
        yield put(showConfirmationDialog({
          title: 'remove_products',
          text: 'remove_products_confirmation',
          textParams: { length: subscriptionItems?.length },
          confirmText: 'Yes',
          confirmDataCy: 'cta-remove_subscriptionItem_yes',
          cancelText: 'No',
          cancelDataCy: 'cta-remove_subscriptionItem_no',
          onClick: _.identity,
        }));

        const { payload: confirm } = yield take(RESULT_CONFIRMATION_DIALOG);

        if (confirm) {
          yield call(deleteStripeSubscriptionItem, {
            onCompleted,
            onError: onCancellingError,
            subscriptionItems,
          });
        }
      }

      yield put(closeFeedbackDialog());
      yield put(setLoadingAsync(true));
      // send to slack
      yield call(createActivityLog, {
        businessId: campaign?.businessId || businessId,
        userId: user?.id,
        type: ActivityLogType.CancelSubscription,
        ui: {
          title: 'Customer Cancellation',
          description: 'Customer has cancelled the Subscription in-app',
        },
        slack: {
          templateType: SlackTemplateType.CancelStripeSubscriptionFeedback,
        },
        meta: {
          questionnaire: data,
          subscriptionItems,
        },
      });
    }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'submitFeedback' },
      params: { payload },
    });
    yield put(showToaster(getToasterOptions('submit_feedback_error', 'error')));
    onError();
  }
}
