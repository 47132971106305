import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import useWindowSize from 'react-use/lib/useWindowSize';

import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';

import AsyncButton from '@targetable/targetable-web-framework/lib/react/components/AsyncButton/AsyncButton';
import { setInstagramAccount, showInstagramSelectModal } from '../../actions';
import { selectInstagramAccounts, selectShowInstagramSelect } from '../../selectors';
import i18n from '../../services/i18n';

const useStyles = makeStyles((theme) => ({
  accountPaper: {
    padding: theme.spacing(1, 1, 1, 2),
    '&:hover': {
      boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    },
  },
  btnContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'left',
    },
  },
  cancelBtn: {
    marginRight: theme.spacing(2),
  },
  closeBtnContainer: {
    position: 'relative',
    height: theme.spacing(3.5),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#757575',
    padding: 0,
  },
  container: {
    '&:focus': {
      outline: 'none',
    },
  },
  content: {
    padding: theme.spacing(2, 3, 2, 3),
  },
  paper: {
    margin: `${theme.spacing(2)}px auto 0`,
  },
  scrollWindow: {
    marginTop: theme.spacing(1),
    overflow: 'auto',
  },
}));

const InstagramSelectModal = ({
  instagramAccounts,
  setInstagramAccountAction,
  open,
  showInstagramSelectModalAction,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { height, width } = useWindowSize();

  const [selectedAccountId, setSelectedAccountId] = useState(null);

  useEffect(() => {
    setSelectedAccountId(null);
  }, [open]);

  const handleConnect = () => {
    const account = _.find(instagramAccounts, { id: selectedAccountId });
    setInstagramAccountAction(account);
  };

  const handleChange = (instagramAccountId) => () => {
    setSelectedAccountId(instagramAccountId);
  };

  const handleClose = () => {
    showInstagramSelectModalAction(false);
  };

  return (
    <Modal disableAutoFocus open={open} onClose={handleClose}>
      <Grid container justifyContent="center" className={classes.container}>
        <Grid item xs={11} sm={10} md={8} lg={6}>
          <Paper className={classes.paper} square>
            <div className={classes.closeBtnContainer}>
              <IconButton
                aria-label="Close"
                className={classes.closeButton}
                onClick={handleClose}
                disableRipple
              >
                <CloseIcon />
              </IconButton>
            </div>
            <Grid
              className={classes.content}
              container
              direction="column"
              style={{ height: width < 600 ? height - 64 : height - 250 }}
            >
              <Typography variant="h2" gutterBottom>
                {t('instagram_select_title')}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {t('instagram_select_subtitle')}
              </Typography>
              <Grid item xs>
                <Grid
                  className={classes.scrollWindow}
                  container
                  alignContent="flex-start"
                  spacing={2}
                >
                  {instagramAccounts && instagramAccounts.map(({ id, username }) => (
                    <Grid key={id} item xs={12} md={6}>
                      <Paper
                        className={classes.accountPaper}
                        onClick={handleChange(id)}
                        elevation={selectedAccountId === id ? 6 : 1}
                      >
                        <Grid container justifyContent="space-between" alignItems="center">
                          <Grid item xs>
                            <Typography variant="body1" noWrap>
                              {`@${username}`}
                            </Typography>
                          </Grid>
                          <Grid item={false}>
                            <Radio
                              checked={selectedAccountId === id}
                              onChange={handleChange(id)}
                              value={id}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between" spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <AsyncButton
                    variant="outlined"
                    onClick={handleClose}
                    fullWidth
                  >
                    {i18n.t('cancel')}
                  </AsyncButton>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <AsyncButton
                    variant="contained"
                    color="secondary"
                    onClick={handleConnect}
                    disabled={!selectedAccountId}
                    fullWidth
                  >
                    {i18n.t('connect')}
                  </AsyncButton>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Modal>
  );
};

InstagramSelectModal.defaultProps = {
  instagramAccounts: [],
};

InstagramSelectModal.propTypes = {
  instagramAccounts: PropTypes.arrayOf(PropTypes.shape({})),
  open: PropTypes.bool.isRequired,
  setInstagramAccountAction: PropTypes.func.isRequired,
  showInstagramSelectModalAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  open: selectShowInstagramSelect(state),
  instagramAccounts: selectInstagramAccounts(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setInstagramAccountAction: setInstagramAccount,
  showInstagramSelectModalAction: showInstagramSelectModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InstagramSelectModal);
