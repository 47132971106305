import { take, put } from 'redux-saga/effects';

import { setConfirmationDialog, setLoadingAsync, showFacebookPermissionsModal } from '../../actions';
import { RESULT_CONFIRMATION_DIALOG } from '../../constants';

export default function* showFacebookConfirmationDialog(payload) {
  yield put(setLoadingAsync(false));
  yield put(setConfirmationDialog(payload));

  const { payload: result } = yield take(RESULT_CONFIRMATION_DIALOG);

  yield put(setLoadingAsync(false));
  if (!result) {
    yield put(setConfirmationDialog({
      isOpen: false,
    }));
  } else {
    yield put(showFacebookPermissionsModal(true));
    yield put(setConfirmationDialog({
      isOpen: false,
    }));
  }
}
