import _ from 'lodash';
import {
  call, put, select, all,
} from 'redux-saga/effects';

import {
  SUGGEST_CAMPAIGN_STATUS_PUBLISHED,
} from '@targetable/targetable-web-framework/lib/react/constants';
import api from '../../services/api';
import { selectBusiness, selectStores } from '../../selectors';
import { setBusinessSuggestedCampaigns, setBusinessSuggestedCampaignsLoading } from '../../actions';
import logger from '../../services/logger';
import {
  CAMPAIGN_STATUS_LIVE,
  CAMPAIGN_STATUS_SUBMITTED,
  CAMPAIGN_STATUS_PAUSED,
} from '../../constants';

export default function* getBusinessSuggestedCampaigns({ payload }) {
  const business = yield select(selectBusiness);
  const stores = yield select(selectStores);
  const expired = _.get(payload, 'expired', false);
  yield put(setBusinessSuggestedCampaignsLoading(true));

  let suggestedCampaigns = [];
  try {
    const calls = [];
    const statuses = [
      CAMPAIGN_STATUS_LIVE,
      CAMPAIGN_STATUS_SUBMITTED,
      CAMPAIGN_STATUS_PAUSED,
      SUGGEST_CAMPAIGN_STATUS_PUBLISHED,
    ];
    _.forEach(statuses, (status) => {
      calls.push(call(
        api.getSuggestedCampaigns,
        business.id,
        stores,
        status,
        false,
        expired,
      ));
    });

    const result = yield all(calls);
    suggestedCampaigns = suggestedCampaigns.concat(result?.flat());
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getBusinessSuggestedCampaigns' },
      params: {
        businessId: business.id,
        stores,
        expired,
      },
    });
  }

  yield put(setBusinessSuggestedCampaigns(suggestedCampaigns));
  yield put(setBusinessSuggestedCampaignsLoading(false));

  return suggestedCampaigns;
}
