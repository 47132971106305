import _ from 'lodash';
import { call, put, select } from 'redux-saga/effects';

import api from '../../services/api';
import logger from '../../services/logger';
import { selectInstagramAccount, selectBusiness, selectUser } from '../../selectors';
import { setInstagramAccount, showFacebookConnectAdAccountModal } from '../../actions';

import getStoresByBusiness from '../getStoresByBusiness/getStoresByBusiness';

export default function* connectInstagramAccountToAdAccount({ payload }) {
  const onDone = _.get(payload, 'onDone');
  const store = _.get(payload, 'store');

  const user = yield select(selectUser);
  const business = yield select(selectBusiness);

  const storeIntegrations = _.get(store, 'integrations');
  const facebookIntegration = _.find(storeIntegrations, (int) => int.type === 'facebook' || int.connection === 'facebook');
  const facebookIdentity = _.find(_.get(user, 'identities'), ['provider', 'facebook']) || {};

  const accessToken = _.get(facebookIdentity, 'access_token') || _.get(user, ['integrations', '0', 'accessToken']);
  const adAccountId = _.get(facebookIntegration, 'adAccountId');
  const fbBusinessId = _.get(facebookIntegration, 'businessId');
  const storeId = _.get(store, 'id');

  try {
    const instagramAccount = yield select(selectInstagramAccount);

    const bmInstagramAccountId = _.get(instagramAccount, 'id');
    const bmInstagramAccountUsername = _.get(instagramAccount, 'username');

    const res = yield call(
      api.updateAdAccount,
      accessToken,
      adAccountId,
      fbBusinessId,
      storeId,
      bmInstagramAccountId,
    );

    if (res) {
      // update the store with IG data
      facebookIntegration.instagramAccountId = bmInstagramAccountId;
      facebookIntegration.instagramUsername = bmInstagramAccountUsername;
      yield call(api.updateStore, storeId, store);

      yield call(api.updateStoreHubspotRecord, store.id, { igConnected: true });

      yield call(getStoresByBusiness, _.get(business, 'id'));

      // close the modal and clean up redux
      yield put(showFacebookConnectAdAccountModal({ show: false, store: null }));
      yield put(setInstagramAccount(null));
    } else {
      // todo future story to handle error
      // close the modal and clean up redux
      yield put(showFacebookConnectAdAccountModal({ show: false, store: null }));
      yield put(setInstagramAccount(null));
    }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'connectInstagramAccountToAdAccount' },
      params: {
        user, business, storeId, adAccountId, fbBusinessId,
      },
    });
  }

  if (onDone) {
    onDone();
  }
}
