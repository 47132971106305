import { DATA_STORES_CUSTOM_AUDIENCES } from '../constants';

const defaultState = {
  stores: null,
};

export default {
  defaultState,
  reducers: {
    [DATA_STORES_CUSTOM_AUDIENCES]: (state, { payload: stores }) => ({ ...state, stores }),
  },
};
