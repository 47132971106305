import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import StepIcon from '@material-ui/core/StepIcon';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import { StepContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import Step from '@material-ui/core/Step';
import AsyncButton from '@targetable/targetable-web-framework/lib/react/components/AsyncButton/AsyncButton';
import VideoPlayer from '@targetable/targetable-web-framework/lib/react/components/VideoPlayer/VideoPlayer';

import InfoStepImage from '../InfoStepImage/InfoStepImage';
import i18n from '../../../../services/i18n';
import infoStepShape from '../../../../shapes/infoStep';

const useStyles = makeStyles((theme) => ({
  a: {
    display: 'block',
    textDecoration: 'none',
  },
  container: {
    '&:focus': {
      outline: 'none',
    },
  },
  paper: {
    margin: `${theme.spacing(2)}px auto 0`,
  },
  title: {
    margin: theme.spacing(3, 3, 1, 3),
  },
  closeButtonContainer: {
    position: 'relative',
    height: theme.spacing(3.5),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#757575',
    padding: 0,
  },
  scrollWindow: {
    height: 'calc(100vh - 180px)',
    overflow: 'auto',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100vh - 160px)',
    },
  },
  stepBtnContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'left',
    },
  },
  stepBackBtn: {
    marginRight: theme.spacing(2),
  },
  stepIconText: {
    fontSize: '0.75rem',
    fontWeight: 600,
  },
  stepIconActive: {
    color: `${theme.palette.secondary.main} !important`,
  },
  stepLine: {
    paddingBottom: theme.spacing(1),
  },
  videoPlayerContainer: {
    marginTop: theme.spacing(2),
    position: 'relative',
    width: '100%',
  },
}));

const InfoStepperModal = ({
  dataCy,
  onClose,
  open,
  steps,
  title,
}) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (!open) {
      setActiveStep(0);
    }
  }, [open]);

  const handleBack = () => setActiveStep(activeStep - 1);

  const handleNext = (step) => () => {
    const nextOverride = _.get(step, 'nextOverride');

    if (nextOverride) {
      nextOverride(activeStep, setActiveStep);
    } else if (activeStep < steps.length) {
      setActiveStep(activeStep + 1);
    }
  };

  return (
    <Modal disableAutoFocus onClose={onClose} open={open} data-cy={dataCy}>
      <Grid container justifyContent="center" className={classes.container}>
        <Grid item xs={11} sm={10} md={8} lg={6}>
          <Paper className={classes.paper} square>
            <div className={classes.closeButtonContainer}>
              <IconButton
                aria-label="Close"
                className={classes.closeButton}
                onClick={onClose}
                disableRipple
                data-cy="button_close"
              >
                <CloseIcon />
              </IconButton>
            </div>
            {title && (
              <Typography className={classes.title} variant="h2">
                {title}
              </Typography>
            )}
            <div className={classes.scrollWindow}>
              <Stepper activeStep={activeStep} orientation="vertical">
                {_.map(steps, (step, index) => (
                  <Step key={_.get(step, 'label')}>
                    <StepLabel
                      StepIconComponent={(props) => (
                        <StepIcon
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...props}
                          classes={{
                            text: classes.stepIconText,
                            active: classes.stepIconActive,
                          }}
                        />
                      )}
                    >
                      {_.get(step, 'label')}
                    </StepLabel>
                    <StepContent>
                      {_.get(step, 'line1') && (
                        <Typography
                          variant="body2"
                          className={classes.stepLine}
                          dangerouslySetInnerHTML={{
                            __html: _.get(step, 'line1'),
                          }}
                        />
                      )}
                      {_.get(step, 'line2') && (
                        <Typography
                          variant="body2"
                          className={classes.stepLine}
                          dangerouslySetInnerHTML={{
                            __html: _.get(step, 'line2'),
                          }}
                        />
                      )}
                      {_.get(step, 'line3') && (
                        <Typography
                          variant="body2"
                          className={classes.stepLine}
                          dangerouslySetInnerHTML={{
                            __html: _.get(step, 'line3'),
                          }}
                        />
                      )}
                      {_.get(step, 'link') && (
                        <Link to={_.get(step, 'link')} component={RouterLink}>
                          {_.get(step, 'linkText', 'Follow this link')}
                        </Link>
                      )}
                      {_.get(step, 'externalLink') && (
                        <Link href={_.get(step, 'externalLink')} target="_blank" rel="noreferrer">
                          {_.get(step, 'linkText', 'Follow this link')}
                        </Link>
                      )}
                      {_.get(step, 'externalLinks', []).map((link) => (
                        <Box key={_.get(link, 'externalLink')} my={1}>
                          <Link href={_.get(link, 'externalLink')} target="_blank" rel="noreferrer">
                            {_.get(link, 'linkText', 'Follow this link')}
                          </Link>
                        </Box>
                      ))}
                      {_.get(step, 'imageDefinition') && <InfoStepImage imageDefinition={_.get(step, 'imageDefinition')} />}
                      {_.get(step, 'video') && (
                        <div className={classes.videoPlayerContainer}>
                          <VideoPlayer options={step.video} dataCy={`component_VideoPlayer_${index}`} />
                        </div>
                      )}
                      <div className={classes.stepBtnContainer}>
                        {_.get(step, 'backEnabled') && (
                          <AsyncButton
                            className={classes.stepBackBtn}
                            variant="contained"
                            color="secondary"
                            onClick={handleBack}
                            data-cy={`button_back_${index}`}
                          >
                            {i18n.t('back')}
                          </AsyncButton>
                        )}
                        {_.get(step, 'nextEnabled') && (
                          <AsyncButton
                            variant="contained"
                            color="secondary"
                            onClick={handleNext(step)}
                            data-cy={`button_next_${index}`}
                          >
                            {i18n.t('next')}
                          </AsyncButton>
                        )}
                      </div>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Modal>
  );
};

InfoStepperModal.defaultProps = {
  dataCy: '',
  open: false,
  title: null,
};

InfoStepperModal.propTypes = {
  dataCy: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  steps: PropTypes.arrayOf(
    PropTypes.shape(infoStepShape),
  ).isRequired,
  title: PropTypes.string,
};

export default InfoStepperModal;
