export default (theme) => ({
  alignLeft: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  alignRight: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
  leftButton: {
    margin: theme.spacing(2, 1, 2, 2),
    width: `calc(100% - ${theme.spacing(3)}px)`,
    [theme.breakpoints.up('md')]: {
      width: 180,
      marginLeft: 0,
    },
  },
  rightButton: {
    margin: theme.spacing(2, 2, 2, 1),
    width: `calc(100% - ${theme.spacing(3)}px)`,
    [theme.breakpoints.up('md')]: {
      width: 180,
      marginRight: 0,
    },
  },
});
