import { URL_PRESET_CUSTOM_ID } from '@targetable/targetable-web-framework/lib/react/constants';
import { put, select } from 'redux-saga/effects';
import _ from 'lodash';
import { setAdCardPresets } from '../../actions';
import { selectUrlPresets, selectAdCardPresets } from '../../selectors';

export default function* getCampaignAdCardPresets(campaign, useCurrent, reset) {
  if (reset) {
    yield put(setAdCardPresets({}));
  }

  const presets = yield select(selectUrlPresets);
  const currentAdCardPresets = yield select(selectAdCardPresets);

  const adCardPresets = {};
  campaign?.ads[0]?.ad?.adCards.forEach((adCard) => {
    if (_.includes(['image', 'video'], adCard.type)) {
      let preset = _.find(presets, { id: adCard.urlPresetId });

      // default back to custom preset if the preset is not found
      // this could happen if the preset was removed
      if (!preset) {
        preset = _.find(presets, { id: URL_PRESET_CUSTOM_ID });
      }

      // if a current preset is available,
      // merge it due to it contains the latest changes made to the Ad
      if (currentAdCardPresets && currentAdCardPresets[adCard.id] && useCurrent) {
        preset = currentAdCardPresets[adCard.id];
      }

      adCardPresets[adCard.id] = {
        ...preset,
        stores: preset.stores.map((presetStore) => {
          if (adCard.urlPresetId === URL_PRESET_CUSTOM_ID) {
            return { ...presetStore, url: adCard.link };
          }

          // use the current url preset store url
          if (useCurrent) {
            return {
              ...presetStore,
              ...(presetStore.url === '' ? { url: adCard.link } : undefined),
            };
          }

          // use the ad link ignoring the latest url preset store url
          return { ...presetStore, url: adCard.link };
        }),
      };
    }
  });
  yield put(setAdCardPresets(adCardPresets));
}
