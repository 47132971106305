import { call, put, all } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment';
import date from '@targetable/targetable-web-framework/lib/services/date';

import api from '../../services/api';

import {
  setCampaignMetrics,
  setCampaignMetricsLoading,
  setCampaignMetricsLoadingError,
  setCampaignTrends,
} from '../../actions';
import logger from '../../services/logger';

export default function* getCampaignMetrics(campaign) {
  try {
    yield put(setCampaignMetrics(null));
    yield put(setCampaignTrends(null));
    yield put(setCampaignMetricsLoading(true));
    yield put(setCampaignMetricsLoadingError(false));

    const now = moment().utc();
    const schedule = _.get(campaign, 'ads.0.schedule');
    const isEvergreen = !_.has(schedule, 'endDate');
    const startDate = moment(date.getDateFromSchedule(schedule, 'startDate').valueOf()).utc();
    const endDate = moment(date.getDateFromSchedule(schedule, 'endDate')?.valueOf())?.utc();

    let to = moment();
    if (campaign?.status === 'live') to = moment();
    if (campaign?.status !== 'live' && !isEvergreen) to = moment(date.getDateFromSchedule(schedule, 'endDate').valueOf());
    if (campaign?.status !== 'live' && !isEvergreen && (endDate > now)) to = moment();
    if (campaign?.status !== 'live' && isEvergreen) to = moment();

    let from30 = moment(to).subtract(30, 'days');
    let from14 = moment(to).subtract(14, 'days');
    let from7 = moment(to).subtract(7, 'days');

    // Meta Ads Manager does not include today's data when selecting the 7, 14, 30 days range
    // So we need to subtract one day to today's date to match the Ads Manager Metrics
    if (to.isAfter(startDate, 'day')) {
      to = moment(to).subtract(1, 'day').format('YYYY-MM-DD');
    } else {
      to = to.format('YYYY-MM-DD');
    }

    from30 = (startDate > from30) ? startDate.format('YYYY-MM-DD') : from30.format('YYYY-MM-DD');
    from14 = (startDate > from14) ? startDate.format('YYYY-MM-DD') : from14.format('YYYY-MM-DD');
    from7 = (startDate > from7) ? startDate.format('YYYY-MM-DD') : from7.format('YYYY-MM-DD');

    const [report, trends30, trends14, trends7] = yield all([
      call(api.getFacebookCampaignMetrics, campaign?.id, 'combined'),
      startDate > now ? Promise.resolve([]) : call(
        api.getFacebookCampaignMetrics, campaign?.id, 'combined', 1, from30, to,
      ),
      startDate > now ? Promise.resolve([]) : call(
        api.getFacebookCampaignMetrics, campaign?.id, 'combined', 1, from14, to,
      ),
      startDate > now ? Promise.resolve([]) : call(
        api.getFacebookCampaignMetrics, campaign?.id, 'combined', 1, from7, to,
      ),
    ]);

    yield put(setCampaignTrends({
      trends30,
      trends14,
      trends7,
    }));
    yield put(setCampaignMetrics(report));
    yield put(setCampaignMetricsLoading(false));

    return report;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getCampaignMetrics' },
      params: { campaign },
    });

    yield put(setCampaignMetricsLoading(false));
    yield put(setCampaignMetricsLoadingError(true));

    return undefined;
  }
}
